import React from "react";
import { ROUTER } from "../../constants/env";
import { useLogin } from "../../hooks/useLogin";
import "./style.css";
import { useNavigate } from "react-router-dom";
export default function Error404() {
    const navigate = useNavigate();
    const { signout, noAccess, auth } = useLogin();
    const routeToRedirect = React.useMemo(() => {
        let allRoles: String[] = ["", "", "", "", ""];
        auth.user?.manage?.map((manageSite: any) => {
            const { roles } = manageSite;
            roles?.map((role: string) => {
                if (
                    role === "Planning" &&
                    !allRoles[0] &&
                    auth.user?.license.planningOn
                ) {
                    allRoles[0] = role;
                }
                if (role === "Pointeuse" && !allRoles[1]) {
                    allRoles[1] = role;
                }
                if (role === "Employés" && !allRoles[2]) {
                    allRoles[2] = role;
                }
                if (role === "Rapports" && !allRoles[3]) {
                    allRoles[3] = role;
                }
            });
        });
        allRoles = allRoles?.filter((role) => role);
        if (allRoles && allRoles?.length > 0) {
            switch (allRoles[0]) {
                case "Planning":
                    return "/planning";
                case "Pointeuse":
                    return "/pointeuse";

                case "Employés":
                    return "/employees";

                case "Rapports":
                    return "/report/worked_hours";

                default:
                    return "/";
            }
        }
    }, []);
    const logout = () => {
        signout();
        navigate("/");
    };
    return (
        <div
            className={
                "flex flex-1 justify-center items-center flex-col h-full gap-2"
            }
            style={{
                height: "100vh",
            }}
        >
            <div className={"items-center justify-center text-center"}>
                <h1 className={"error-404-title "}>Erreur 404</h1>
                <p className={"text-2xl font-regular"}>
                    {noAccess
                        ? "vous n'avez accès à aucune page"
                        : "Ooops, page introuvable!"}
                </p>
            </div>
            <img
                src={"/assets/imgs/404.png"}
                className={"w-1/3 w-max-[240px]"}
            />
            {noAccess ? (
                <button
                    className={
                        "error-404-button rounded-xl pt-5 pb-5 pl-10 pr-10"
                    }
                    onClick={logout}
                >
                    Déconnexion
                </button>
            ) : (
                <button
                    className={
                        "error-404-button rounded-xl pt-5 pb-5 pl-10 pr-10"
                    }
                    onClick={() => navigate(routeToRedirect ?? "/")}
                >
                    Retour à la page d'accueil
                </button>
            )}
        </div>
    );
}
