import React, { useEffect } from "react";
import Button from "../../atoms/ZButton";
import { ReactComponent as CheckIcon } from "../../atoms/Checkbox/checkedIcon.svg";
import { ReactComponent as CloseIcon } from "../../../utils/img/icons/close.svg";
import { ReactComponent as LeftArrow } from "../../../utils/img/lucideReact-icons/arrow-left-to-line.svg";
import { ReactComponent as Trash } from "../../../utils/img/lucideReact-icons/trash.svg";
import { ReactComponent as Edit } from "../../../utils/img/lucideReact-icons/edit.svg";

import TextData from "../../atoms/TextData";
import ProfilePicture from "../../molecules/ProfilePicture";
import moment from "moment";
import { ROUTER } from "../../../constants/env";
import TimingComponent from "../../molecules/TimingComponent";
import { ReactComponent as MessageSquareText } from "../../../utils/img/lucideReact-icons/message-square-text.svg";
import ZTextarea from "../../atoms/ZTextarea";
import { generatePicUrl } from "../../../_helpers/PictureHelpers";
import { calculateDays, congeTypes } from "../../../_helpers/Functions";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import HolidayDeletionAlert from "../HolidayDeletionAlert";
import SelectInput from "../../molecules/SelectInput";

interface VacationRequestCardInterface {
    display: boolean;
    onclose?: () => void;
    employee: any;
    onReject: (congeId: any, cause: any, userId: any) => void;
    onValid: (congeId: any, cause: any, userId: any) => void;
    onDelete: (congeId: any) => void;
    onReset: (congeId: any, status: any) => void;
    openUpdatePopup?: () => void;
    loading: any;
    atraiter?: boolean;
}
const UserInfo = ({ employee }: { employee: any }) => {
    const pictureProps = {
        imgPath: ROUTER.STATIC_IMG(employee?.user?.picture),
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        style: { borderRadius: "50%" },
    };
    return (
        <div
            style={{
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
                margin: "0 20px",
                padding: "5px",
            }}
        >
            <TextData
                variant="label"
                width="auto"
                size="22px"
                fontWeight="700"
                color="#2A346D"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        width: "fit-content",
                        height: "fit-content",
                    }}
                >
                    <ProfilePicture
                        profileImage={
                            employee?.user?.picture &&
                            generatePicUrl(employee?.user?.picture)
                        }
                        firstName={employee?.user?.fname || " "}
                        lastName={employee?.user?.lname || " "}
                        pictureProps={pictureProps}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div>
                        {employee?.user?.fname} {employee?.user?.lname}
                    </div>
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "400",
                        }}
                    >
                        Ajouté le{" "}
                        {moment(employee?.created_at, "YYYY-MM-DD")
                            .locale("fr")
                            .format("DD/MM/YYYY")}
                    </div>
                </div>
            </TextData>
        </div>
    );
};

const HolidayLabel = ({ employee }: { employee: any }) => {
    const congeType = congeTypes?.find(
        (type) => type?.value === employee?.type?.toLowerCase()?.trim(),
    );
    return (
        <div className="w-full text-lg font-medium rounded-lg flex items-center gap-2 p-3 text-default bg-n-0">
            <MessageSquareText color="#4c5690" width={20} />
            {congeType ? congeType?.name : "Pas de type"}
        </div>
    );
};
const VacationRequestCard = ({
    display,
    onclose,
    employee,
    onValid,
    onReject,
    onDelete,
    onReset,
    loading,
    atraiter = false,
    openUpdatePopup,
}: VacationRequestCardInterface) => {
    const [dialogAlert, setDialogAlert] = React.useState(false);
    const [cause, setCause] = React.useState(employee ? employee?.cause : "");
    const [days, setDays] = React.useState<any>();
    const [alertContent, setAlertContent] = React.useState({
        headerTitle: "",
        content: "",
        textButton1: "",
        handleOnConfirm: () => { },
    });
    useEffect(() => {
        if (employee) {
            setCause(employee?.cause);
            const calculatedDays = calculateDays(
                employee?.fromDay,
                employee?.fromHour,
                employee?.toDay,
                employee?.toHour,
            );
            setDays(calculatedDays);
        }
    }, [employee]);

    const handleAccept = () => {
        onValid(employee?._id, cause, employee?.user?._id);
        setCause("");
    };
    const handleReject = () => {
        onReject(employee?._id, cause, employee?.user?._id);
        setCause("");
    };
    const handleDelete = () => {
        setAlertContent({
            headerTitle: "Suppression",
            content:
                "Êtes-vous sûr de vouloir supprimer cette demande de congé ?",
            textButton1: "Supprimer",
            handleOnConfirm: () => {
                onDelete(employee?._id);
                setDialogAlert(false);
            },
        });
        setDialogAlert(true);
    };

    const handleUpdate = () => {
        setAlertContent({
            headerTitle: "Modification",
            content:
                "Êtes-vous sûr de vouloir ramener cette demande de congé à son état initial ?",
            textButton1: "Confirmer",
            handleOnConfirm: handleReset,
        });
        setDialogAlert(true);
    };

    const handleReset = () => {
        onReset(employee?._id, 0);
        setDialogAlert(false);
    };

    const actionButtons = [
        {
            label: (
                <div className="w-full">
                    <Button
                        size="block"
                        loading={loading}
                        variant="default"
                        title={"Modifier"}
                        leftComponent={
                            <div className="">
                                <Edit color="#4C5690" height={20} width={20} />
                            </div>
                        }
                        onClick={openUpdatePopup}
                    />
                </div>
            ),
            value: "Modifier",
        },
        {
            label: (
                <div className="w-full">
                    <Button
                        containerClasses="text-red-500 bg-n-0 hover:bg-n-0-hover"
                        size="block"
                        loading={loading}
                        title={"Supprimer"}
                        leftComponent={
                            <div className="">
                                <Trash color="red" height={20} width={20} />
                            </div>
                        }
                        onClick={handleDelete}
                    />
                </div>
            ),
            value: "supprimer",
        },
    ];

    return (
        <div>
            {employee && (
                <>
                    <Modal modalEnabled={display} onClose={onclose}>
                        <PopUpCard
                            containerWidth={"540px"}
                            display={display}
                            header={
                                atraiter
                                    ? "Modifier le demande"
                                    : "Demande de congé"
                            }
                            onClose={onclose}
                            isCloseIcon={true}
                            activateFooter={false}
                        >
                            <div className="flex flex-col  space-y-3 ">
                                <div className="emp-selection-container px-6 pt-6 pb-12 flex flex-col  space-y-3  w-full">
                                    <UserInfo employee={employee} />

                                    <div className="flex justify-center w-full">
                                        <TimingComponent
                                            startTime={moment(
                                                employee?.fromDay,
                                            )?.format("DD/MM/YY")}
                                            endTime={moment(
                                                employee?.toDay,
                                            )?.format("DD/MM/YY")}
                                            hasPause={days}
                                            dateType={
                                                days > 1 ? " jours" : " jour"
                                            }
                                        />
                                    </div>

                                    <HolidayLabel employee={employee} />

                                    <div className="text-default font-semibold  max-w-[500px] max-h-[100px] overflow-auto">
                                        Commentaire du collaborateur :{" "}
                                        <span className="text-default font-medium  ">
                                            {employee?.message?.length > 0
                                                ? employee?.message
                                                : "Pas de commentaire"}
                                        </span>
                                    </div>
                                    {atraiter ? (
                                        <div className="text-default font-semibold max-w-[500px] max-h-[100px]  overflow-auto">
                                            Réponse du manager :{" "}
                                            <span className="text-default font-medium  ">
                                                {employee?.cause?.length > 0
                                                    ? employee?.cause
                                                    : "Pas de commentaire"}
                                            </span>
                                        </div>
                                    ) : (
                                        <ZTextarea
                                            field={{
                                                name: "cause",
                                                value: cause,
                                            }}
                                            rows={1}
                                            maxlength={100}
                                            required={false}
                                            label={"Notes (100 caractères)"}
                                            onUpdate={(newValue) =>
                                                setCause(newValue.toString())
                                            }
                                        />
                                    )}
                                </div>
                                <div className="flex justify-end space-x-2 border border-t-[1px] rounded-b-[20px] p-4">
                                    {atraiter ? (
                                        <Button
                                            loading={loading}
                                            variant="primary"
                                            title={"Modifier"}
                                            onClick={handleUpdate}
                                        />
                                    ) : (
                                        <div className="flex space-x-2 w-full">
                                            <div className=" w-2/5">
                                                <SelectInput
                                                    placeholder="Autres actions"
                                                    controlCssStyles={{
                                                        height: "48px",
                                                        width: "139px",
                                                    }}
                                                    options={actionButtons}
                                                    onChange={() => { }}
                                                    menuPlacement="top"
                                                    value={"g"}
                                                />
                                            </div>
                                            <div className="flex space-x-4 w-3/5">
                                                <Button
                                                    size="block"
                                                    loading={loading}
                                                    containerClasses="bg-red-500 text-white hover:bg-red-500/75 max-w-[133px]"
                                                    variant="danger"
                                                    title={"Refuser"}
                                                    leftComponent={
                                                        <div className="rotate-180">
                                                            <CloseIcon
                                                                height={20}
                                                                width={20}
                                                                color="#fff"
                                                            />
                                                        </div>
                                                    }
                                                    onClick={handleReject}
                                                />
                                                <Button
                                                    size="block"
                                                    loading={loading}
                                                    containerClasses=" max-w-[133px]"
                                                    variant="primary"
                                                    title={"Approuver"}
                                                    leftComponent={
                                                        <div className="">
                                                            <CheckIcon
                                                                height={20}
                                                                width={20}
                                                                color="#fff"
                                                            />
                                                        </div>
                                                    }
                                                    onClick={handleAccept}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </PopUpCard>
                    </Modal>
                    {dialogAlert && (
                        <HolidayDeletionAlert
                            display={dialogAlert}
                            headerTitle={alertContent.headerTitle}
                            content={alertContent.content}
                            textButton1={alertContent.textButton1}
                            handleOnConfirm={alertContent.handleOnConfirm}
                            onClose={() => setDialogAlert(false)}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default VacationRequestCard;

// Ps: FOR NEXT UPDATE
// cancel="ou"
// cancelRightComponent={
//     <Button
//         loading={loading}
//         containerClasses="bg-red-500 text-white hover:bg-red-500/75"
//         variant="danger"
//         title={"Refuser"}
//         leftComponent={
//             <div className="rotate-180">
//                 <Close />
//             </div>
//         }
//         onClick={ handleReject}
//     />
// }
// cancelLeftComponent={
//     <div
//         className="rounded-lg flex items-center justify-center gap-1 bg-red-500 text-white hover:bg-red-500/75 text-md px-3 py-2"
//         onClick={handleDelete}
//     >
//         <Trash2 className="" />
//     </div>
// }
// />
