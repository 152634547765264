import React from "react";
import Conges from "../../components/templates/Conges";
import { useBranch } from "../../hooks/useBranch";
import CongesTable from "./CongesTable";
import { useHoliday } from "../../hooks/useHolidays";
import VacationRequest from "./VacationRequest";
import CongesTraiteTable from "./CongesTraiteTable";
import CreateConge from "./CreateConge";
import { useEmployees } from "../../hooks/useEmployees";
import UpdateConge from "./UpdateConge";
import { transformArrayToObject } from "../../_helpers/Functions";

export default function CongesPage() {
    const { selectedBranchSettings } = useBranch();
    const { getEmployees, employees, filteredFromAllSites } = useEmployees();
    const { processedHolidays, validHolidays, loading, error, getHolidays } =
        useHoliday();
    const [atraiter, setAtraiter] = React.useState(false);
    const [openVacationRequest, setOpenVacationRequest] = React.useState(false);
    const [updateRequest, setUpdateRequest] = React.useState(false);
    const [openCreateConges, setOpenCreateConges] = React.useState(false);
    const [employee, setEmployee] = React.useState<any>(null);
    const currentUserInfo = sessionStorage.getItem("user");
    let parsedBranch: any;
    if (currentUserInfo) {
        parsedBranch = JSON.parse(currentUserInfo);
    }
    React.useEffect(() => {
        if (selectedBranchSettings) {
            getHolidays(
                null,
                "0",
                null,
                null,
                selectedBranchSettings?.license
                    ? selectedBranchSettings.license
                    : parsedBranch?.license?._id
                        ? parsedBranch.license?._id
                        : null,
                selectedBranchSettings?._id
                    ? [selectedBranchSettings?._id]
                    : null,
            );
            getHolidays(
                null,
                "1,2",
                null,
                null,
                selectedBranchSettings?.license
                    ? selectedBranchSettings.license
                    : parsedBranch?.license?._id
                        ? parsedBranch.license?._id
                        : null,
                selectedBranchSettings?._id
                    ? [selectedBranchSettings?._id]
                    : null,
            );
        }
    }, [selectedBranchSettings?._id]);

    React.useEffect(() => {
        getEmployees({ archived: false });
    }, [selectedBranchSettings?._id]);

    const closePopUps = () => {
        setOpenVacationRequest(false);
    };
    const openPopUps = () => {
        setOpenVacationRequest(true);
    };
    const closeCreatePopUps = () => {
        setOpenCreateConges(false);
    };
    const openUpdatePopup = () => {
        setUpdateRequest(true);
    };
    const closeUpdatePopup = () => {
        setUpdateRequest(false);
    };
    const handleOpenVacationRequest = () => {
        setOpenCreateConges(true);
    };
    const updateEmployeeValue = (value: any) => {
        setEmployee(value);
    };
    const openTraitement = () => {
        setAtraiter(true);
    };
    const closeTraitement = () => {
        setAtraiter(false);
    };

    const transformedValidHolidays = transformArrayToObject(validHolidays);
    const transformedProcessedHolidays =
        transformArrayToObject(processedHolidays);
    let transformedValidHolidaysArray: any[] = [];
    let transformedProcessedHolidaysArray: any[] = [];

    employees.map((employee) => {
        const emp = transformedValidHolidays[employee?._id];
        if (emp) {
            emp.map((ele: any) => transformedValidHolidaysArray.push(ele));
        }
    });
    employees.map((employee) => {
        const emp = transformedProcessedHolidays[employee?._id];
        if (emp) {
            emp.map((ele: any) => transformedProcessedHolidaysArray.push(ele));
        }
    });
    return (
        <>
            <VacationRequest
                display={openVacationRequest}
                onClose={closePopUps}
                employee={employee}
                licenseId={
                    selectedBranchSettings?.license
                        ? selectedBranchSettings?.license
                        : parsedBranch.license._id
                }
                siteId={selectedBranchSettings?._id}
                atraiter={atraiter}
                openUpdatePopup={openUpdatePopup}
            />
            <CreateConge
                onOpen={openCreateConges}
                onClose={closeCreatePopUps}
                employees={employees}
                licenseId={
                    selectedBranchSettings?.license
                        ? selectedBranchSettings?.license
                        : parsedBranch.license._id
                }
                siteId={selectedBranchSettings?._id}
            />
            <UpdateConge
                display={updateRequest}
                onClose={closeUpdatePopup}
                closeVacationRequest={closePopUps}
                conge={employee}
                licenseId={
                    selectedBranchSettings?.license
                        ? selectedBranchSettings?.license
                        : parsedBranch.license._id
                }
                siteId={selectedBranchSettings?._id}
            />
            <Conges
                selectedBranch={selectedBranchSettings?._id}
                openAddConge={handleOpenVacationRequest}
                processedCount={processedHolidays?.length}
                ValidCount={validHolidays?.length}
                FirstTabCompoenent={
                    <CongesTable
                        holidayLoading={loading}
                        userHolidays={transformedValidHolidaysArray}
                        openVacationRequestDialog={openPopUps}
                        updateEmployeeValue={updateEmployeeValue}
                        openAddConge={handleOpenVacationRequest}
                        closeTraitement={closeTraitement}
                    />
                }
                SecondTabCompoenent={
                    <CongesTraiteTable
                        holidayLoading={loading}
                        userHolidays={transformedProcessedHolidaysArray}
                        openAddConge={handleOpenVacationRequest}
                        updateEmployeeValue={updateEmployeeValue}
                        openVacationRequestDialog={openPopUps}
                        openTraitement={openTraitement}
                    />
                }
            />
        </>
    );
}
