import React from "react";
import "./style.scss";

import { usePlanning } from "../../../hooks/usePlanning";
import ShiftSummary from "./ShiftSummary";
function Shift(props: any) {
    const {
        data,
        setIsShiftEditing,
        setShiftToEdit,
        setIsShift,
        setIsClicked,
        published,
        department,
        extern,
    } = props;
    const [isHover, setisHover] = React.useState(false);
    const [dragged, setIsDragged] = React.useState(false);
    const frontDivRef = React.useRef<HTMLDivElement>(null);
    const backDivRef = React.useRef<HTMLDivElement>(null);
    const divContainer = React.useRef<HTMLDivElement>(null);
    const { setDraggedShiftType, absenceColors } = usePlanning();

    React.useLayoutEffect(() => {
        // console.log("frontDivRef.current", frontDivRef.current);
        // console.log("backDivRef.current", backDivRef.current);
        if (frontDivRef.current && backDivRef.current) {
            // console.log("frontDivRef.current", frontDivRef.current);
            // console.log("backDivRef.current", backDivRef.current);
            // console.log("use");
            const frontDivHeight =
                frontDivRef.current.getBoundingClientRect().height ?? 0;
            const backDivHeight =
                backDivRef.current.getBoundingClientRect().height ?? 0;
            const frontDivWidth =
                frontDivRef.current.getBoundingClientRect().width ?? 0;
            const backDivWidth =
                backDivRef.current.getBoundingClientRect().width ?? 0;

            const maxHeight = Math.max(frontDivHeight, backDivHeight);
            const maxWidth = Math.max(frontDivWidth, backDivWidth);
            frontDivRef.current.style.cssText += `width: 100%`;
            backDivRef.current.style.cssText += `height: ${maxHeight}px; width: 100%;`;
            if (divContainer.current)
                divContainer.current.style.cssText = `height: ${maxHeight}px; width:100%`;
            // frontDivRef.current.style.cssText += `height: ${maxHeight}px; width: ${maxWidth}px;`;
            // backDivRef.current.style.cssText += `height: ${maxHeight}px; width: ${maxWidth}px;`;
            // if (divContainer.current)
            //     divContainer.current.style.cssText = `height: ${maxHeight}px; width: ${maxWidth}px;`;
        }
    }, [isHover]);

    const toggleHover = (value: boolean) => {
        setisHover(value);
    };
    const absenceColor = React.useMemo(() => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const siteId = JSON.parse(selectedBranch)?._id;
            for (let i = 0; i < absenceColors?.length; i++) {
                const { absence, site, color } = absenceColors[i];
                if (absence === data.typeAbsence && site === siteId) {
                    return color;
                }
            }
        }
        return "#000000";
    }, [data, absenceColors]);
    // React.useEffect(() => {
    //     if (
    //         data.type === 0 &&
    //         data.typeAbsence &&
    //         data.typeAbsence !== "" &&
    //         absenceColors?.length > 0
    //     ) {
    //         for (let i = 0; i < absenceColors?.length; i++) {
    //             // #f6b93b
    //             if (absenceColors[i]?.absence === data.typeAbsence) {
    //                 console.log(
    //                     " *-*-*-*-*  ",
    //                     absenceColors[i]?.absence,
    //                     data.typeAbsence,
    //                     absenceColors[i]?.color
    //                 );
    //                 setAbsenceColor(absenceColors[i].color);
    //                 break;
    //             }
    //         }
    //     }
    // }, [data.type, data.typeAbsence]);
    const handleEditShift = () => {
        console?.log(" shift = ", data);
        if (!published) {
            setIsShiftEditing(true);
            setIsClicked(true);
            if (data?.type === 1) {
                setIsShift(true);
            } else {
                setIsShift(false);
            }
            setShiftToEdit(data);
        }
    };
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        // Set the shift data to be transferred during drag
        setDraggedShiftType("normal");
        setIsDragged(true);
        event.dataTransfer.setData(
            "text/plain",
            JSON.stringify({ type: "normal", shift: data }),
        );
    };

    if (!published) {
        return (
            <div
                // ref={divContainer}
                // onMouseEnter={() => toggleHover(true)}
                // onMouseLeave={() => toggleHover(false)}
                style={{
                    width: "100%",
                    // height: "100%",
                    cursor:
                        !extern && dragged
                            ? "grabbing"
                            : !extern
                                ? "grab"
                                : "default",
                    marginBottom: "3px",
                    opacity: extern ? "0.5" : "1",
                }}
                onClick={!extern ? handleEditShift : undefined}
                draggable
                onDragStart={!extern ? handleDragStart : undefined}
            >
                {/* published={published} */}
                {/* reference={backDivRef} */}
                {/* toggleHover={toggleHover}
                        isHover={isHover} */}
                <ShiftSummary
                    // reference={frontDivRef}
                    // toggleHover={toggleHover}
                    // isHover={isHover}
                    data={data}
                    absenceColor={absenceColor}
                    published={published}
                    extern={extern}
                />
            </div>
        );
    } else {
        return (
            <div
                // ref={divContainer}
                // onMouseEnter={() => toggleHover(true)}
                // onMouseLeave={() => toggleHover(false)}
                style={{
                    width: "100%",
                    // height: "100%",
                    marginBottom: "3px",
                    position: "relative",
                    // cursor: "grab",
                    opacity: extern ? "0.5" : "1",
                }}
                onClick={() => {
                    console?.log(" shift = ", data);
                }}
            >
                {/* published={published} */}
                {/* reference={backDivRef} */}
                {/* toggleHover={toggleHover}
                        isHover={isHover} */}
                <ShiftSummary
                    // reference={frontDivRef}
                    // toggleHover={toggleHover}
                    // isHover={isHover}
                    data={data}
                    absenceColor={absenceColor}
                    published={published}
                    extern={extern}
                />
            </div>
        );
    }
}
export default React.memo(Shift);
