import Swal from "sweetalert2";
import { API } from "../constants/env";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import {
    CorrectVacationBalanceService,
    getAllUserVacationBalanceHistoryService,
    getAllVacationBalanceHistoryService,
    updateAutoVacationBalanceScoreService,
} from "../redux/features/leaveBalance/service";
import { setLeaveBalanceHistoryUserID } from "../redux/features/leaveBalance/leaveBalanceSlice";

export const useLeaveBalance = () => {
    const disptach = useAppDispatch();

    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;

    const leaveBalance = useAppSelector((state: any) => state.leaveBalance);

    const allHistoryBalances = useAppSelector(
        (state: any) => state.leaveBalance,
    ).list;

    const UserLeaveBalanceHistory = useAppSelector(
        (state: any) => state.leaveBalance,
    ).selectedUserLeaveBalanceHistory;

    const getAllLeaveBalance = () => {
        disptach(
            getAllVacationBalanceHistoryService({
                siteID: selectedBranchSettings?._id,
            }),
        );
    };

    const forceManualLeaveBalance = async (
        user: any,
        leaveCount: any,
        leaveType = "-",
    ) => {
        if (leaveCount !== 0 && selectedBranchSettings) {
            await disptach(
                CorrectVacationBalanceService({
                    user: user,
                    siteID: selectedBranchSettings?._id,
                    leaveCount: leaveCount,
                    leaveType: leaveType,
                }),
            );
        }
    };

    const computeAutoVacationBalanceScore = () => {
        if (selectedBranchSettings) {
            disptach(
                updateAutoVacationBalanceScoreService({
                    siteID: selectedBranchSettings?._id,
                }),
            );
        }
    };

    const setSelectedUserLeaveBalanceHistory = (userID: any) => {
        disptach(setLeaveBalanceHistoryUserID({ userID }));
    };
    const getUserLeaveBalanceHistory = () => {
        if (UserLeaveBalanceHistory.userID != null) {
            disptach(
                getAllUserVacationBalanceHistoryService({
                    userID: UserLeaveBalanceHistory.userID,
                }),
            );
        }
    };

    return {
        leaveBalance,
        allHistoryBalances,
        UserLeaveBalanceHistory,

        getUserLeaveBalanceHistory,
        setSelectedUserLeaveBalanceHistory,
        getAllLeaveBalance,
        forceManualLeaveBalance,
        computeAutoVacationBalanceScore,
    };
};
