import * as React from "react";
import moment from "moment";
import ReportDisplayOption from "../../../molecules/ReportDisplayOption";
import SelectInput from "../../../atoms/SelectInput";
import PayrollPerformanceCard from "../../../molecules/PayrollPerformanceCard";
import TextData from "../../../atoms/TextData";
import Button from "../../../atoms/Button";
import Table from "../../Table";

import "./style.scss";
import HeaderTable from "./HeaderTable";
import ContentTable from "./ContentTable";
import CommingSoon from "../../../../pages/Settings/Elements/CommingSoon";
import { useAnalytics } from "../../../../hooks/useAnalytics";
import { convertMinutesToHours } from "../../../../_helpers/Functions";
import Popina from "../../../../pages/Settings/icons/popina.svg";
import { ReactComponent as EyeOpenedSVG } from "./eye-opened.svg";
import { ReactComponent as EyeClosedSVG } from "./eye-closed.svg";
import { formatToTwoDecimals } from "../../../../_helpers/NumbersHelpers";

function ProductivityTable({
    isBudget,
    loading,
    showSales,
}: {
    isBudget: boolean;
    loading: boolean;
    showSales: boolean;
}) {
    return {
        fields: {
            fieldRow: HeaderTable(isBudget),
            onClickRow: () => { },
            style: {
                border: "1px solid #EDEFF5",
                borderTop: "none",
                width: "100%",
                backgroundColor: "#EDEFF5",
            },
            className: [],
        },
        data: ContentTable(isBudget, showSales),
    };
}

function WeekRatio(props: any) {
    const { isBudget, setIsBudget } = props;
    return (
        <div className="week-ratio-container">
            <TextData
                width="auto"
                color="#2A346D"
                size="14px"
                fontWeight="bold"
            >
                Ratios de la semaine
            </TextData>
            <div className="clickable-button">
                <Button
                    textColor={isBudget ? "#fff" : "#2D2AAD"}
                    backgroundColor={isBudget ? "#2D2AAD" : "#fff"}
                    width="70px"
                    padding="6px 16px"
                    style={{
                        borderRadius: "4px 0 0 4px",
                        fontSize: "12px",
                    }}
                    onClick={() => setIsBudget(true)}
                >
                    Budget
                </Button>
                <Button
                    textColor={isBudget ? "#2D2AAD" : "#fff"}
                    backgroundColor={isBudget ? "#fff" : "#2D2AAD"}
                    width="70px"
                    padding="6px 16px"
                    style={{
                        borderRadius: "0 4px 4px 0",
                        fontSize: "12px",
                    }}
                    onClick={() => setIsBudget(false)}
                >
                    Réel
                </Button>
            </div>
        </div>
    );
}
function InfoCard(props: any) {
    const {
        isBudget,
        setIsBudget,
        reportData,
        setShowSales,
        showSales,
        configs,
    } = props;
    const data = React.useMemo(() => {
        if (!reportData)
            return [
                { label: "Ventes HT", value: [`0Є`] },
                // { label: "Masse salariale", value: ["3573.6Є"] },
                {
                    label: isBudget
                        ? "Heures planifiées"
                        : "Heures travaillées",
                    value: [`0 h`],
                },
                { label: "Productivité", value: [`0Є/h`] },
                // { label: "Ratio masse salariale", value: ["0.0%"] },
                // { label: "Heures Supp.", value: ["0h", "0.00Є"] },
            ];
        if (isBudget) {
            return [
                {
                    label: (
                        <div className="flex justify-between align-center">
                            Ventes HT{" "}
                            <span className="cursor-pointer">
                                {!showSales ? (
                                    <EyeOpenedSVG
                                        onClick={() => setShowSales(true)}
                                    />
                                ) : (
                                    <EyeClosedSVG
                                        onClick={() => setShowSales(false)}
                                    />
                                )}
                            </span>
                        </div>
                    ),
                    value: [
                        `${showSales ? formatToTwoDecimals(reportData?.estimatedReport?.sales ?? 0) : "***"}Є`,
                    ],
                },
                // { label: "Masse salariale", value: ["3573.6Є"] },
                {
                    label: "Heures planifiées",
                    value: [
                        `${formatToTwoDecimals(reportData?.estimatedReport?.workingHours ?? 0)}`,
                    ],
                },
                {
                    label: "Productivité",
                    value: [
                        `${formatToTwoDecimals(reportData?.estimatedReport?.productivity ?? 0)}Є/h`,
                    ],
                },
                // { label: "Ratio masse salariale", value: ["0.0%"] },
                // { label: "Heures Supp.", value: ["0h", "0.00Є"] },
            ];
        } else {
            return [
                {
                    label: (
                        <div className="flex justify-between align-center">
                            Ventes HT{" "}
                            <span className="cursor-pointer">
                                {!showSales ? (
                                    <EyeOpenedSVG
                                        onClick={() => setShowSales(true)}
                                    />
                                ) : (
                                    <EyeClosedSVG
                                        onClick={() => setShowSales(false)}
                                    />
                                )}
                            </span>
                        </div>
                    ),
                    value: [
                        `${showSales ? formatToTwoDecimals(reportData?.actualReport?.sales ?? 0) : "***"}Є`,
                    ],
                },
                // { label: "Masse salariale", value: ["3573.6Є"] },
                {
                    label: "Heures travaillées",
                    value: [
                        `${formatToTwoDecimals(reportData?.actualReport?.workingHours ?? 0)}`,
                    ],
                },
                {
                    label: "Productivité",
                    value: [
                        `${formatToTwoDecimals(reportData?.actualReport?.productivity ?? 0)}Є/h`,
                    ],
                },
                // { label: "Ratio masse salariale", value: ["0.0%"] },
                // { label: "Heures Supp.", value: ["0h", "0.00Є"] },
            ];
        }
    }, [isBudget, reportData, showSales]);
    const zeltyImg =
        "https://consumersiteimages.trustpilot.net/business-units/5d8c78625d09830001a175ba-198x149-1x.jpg";
    return (
        <div className="productivity-data-cards">
            <WeekRatio isBudget={isBudget} setIsBudget={setIsBudget} />
            {data.map((item, index) => (
                <div className="info-card-container">
                    <TextData width="100%" color="#D1D1E8" size="14px">
                        {item.label}
                    </TextData>
                    {item.value.map((value, i) => (
                        <TextData
                            fontWeight="bold"
                            width="auto"
                            color="#fff"
                            size="20px"
                        >
                            {value}
                        </TextData>
                    ))}
                </div>
            ))}
            {!isBudget && configs?.salesApi?.provider === "Pragma" ? (
                <div
                    style={{
                        marginLeft: "auto",
                    }}
                >
                    <img src={Popina} />
                    <TextData
                        fontWeight="bold"
                        width="auto"
                        color="#fff"
                        size="15px"
                        style={{
                            backgroundColor: "#00a42e",
                            padding: "5px 15px",
                            borderRadius: "5px",
                        }}
                    >
                        Activé
                    </TextData>
                </div>
            ) : (
                !isBudget &&
                configs?.salesApi?.provider === "Zelty" && (
                    <div
                        style={{
                            marginLeft: "auto",
                            maxHeight: "30px",
                        }}
                    >
                        <img src={zeltyImg} className="h-full" />
                        <TextData
                            fontWeight="bold"
                            width="auto"
                            color="#fff"
                            size="15px"
                            style={{
                                backgroundColor: "#00a42e",
                                padding: "5px 15px",
                                borderRadius: "5px",
                            }}
                        >
                            Activé
                        </TextData>
                    </div>
                )
            )}
        </div>
    );
}

function ProductivityContent(props: ProductivityContentProps) {
    const { activateCommingSoon } = props;
    const { data, configs, loading } = useAnalytics();
    const [isBudget, setIsBudget] = React.useState(true);
    const [showSales, setShowSales] = React.useState(true);

    return (
        <div className="productivity-container">
            {/* {dateRange.startDate}
            <br />
            {dateRange.endDate}
            <br /> */}

            {activateCommingSoon && <CommingSoon page={"productivity"} />}
            {/* <div className="payroll-stats-container">
                <PayrollPerformanceCard />
            </div> */}
            <div className="productivity-data-container">
                <InfoCard
                    isBudget={isBudget}
                    setIsBudget={setIsBudget}
                    showSales={showSales}
                    setShowSales={setShowSales}
                    reportData={data}
                    configs={configs}
                />
                <div
                    style={{
                        height: "calc(100vh - 330px)",
                        overflowY: "auto",
                    }}
                >
                    <Table
                        {...ProductivityTable({ isBudget, loading, showSales })}
                    />
                </div>
            </div>
        </div>
    );
}

function ProductivitySidebar() {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            <ReportDisplayOption />
            <SelectInput
                title=""
                options={[{ label: "Année", value: null }]}
                style={{
                    width: "auto",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "9px",
                    fontSize: "12px",
                    padding: "0 10px",
                }}
            ></SelectInput>
            <SelectInput
                title=""
                options={[{ label: "Mois", value: null }]}
                style={{
                    width: "auto",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "9px",
                    fontSize: "12px",
                    padding: "0 10px",
                }}
            ></SelectInput>
            <SelectInput
                title=""
                options={[{ label: "Selectionner un employé", value: null }]}
                style={{
                    width: "210px",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "9px",
                    fontSize: "12px",
                    padding: "0 10px",
                }}
            ></SelectInput>
        </div>
    );
}
function ProductivityTableHeader() {
    return (
        <div
            style={{
                display: "flex",
                width: "auto",
                justifyContent: "space-between",
                alignItems: "center",
                height: "80px",
                margin: "0 1.25rem",
            }}
        >
            <TextData
                color="#11106E"
                size="34"
                fontWeight="bold"
                className={["flex items-center gap-1"]}
            >
                Productivité{" "}
                <span
                    className="text-[12px] flex items-center"
                    style={{
                        gap: "2px",
                        padding: "2px 5px",
                        borderRadius: "5px",
                        backgroundColor: "#d3e4e9",
                        color: "#2a8bab",
                    }}
                >
                    Bêta
                </span>
            </TextData>
        </div>
    );
}

const ProductivityTableRightMenu = ProductivitySidebar;
interface ProductivityContentProps {
    activateCommingSoon?: boolean;
}
ProductivityContent.defaultProps = {
    activateCommingSoon: false,
};
export {
    ProductivityContent,
    ProductivityTableRightMenu,
    ProductivityTableHeader,
};
