import * as React from "react";
import Ticket from "../../../../components/atoms/Ticket";
import NewTicket from "../../../../components/atoms/NewTicket";
import Modal from "../../../../components/atoms/Modal";
import NewDepartmentCard from "../../../../components/organism/NewDepartmentCard";
function MultiPlanningTickets(props: MultiPlanningTicketsProps) {
    const {
        newTicketButtonText,
        tickets,
        handleTicketEdit,
        handleTicketDelete,
        //create new planning
        isCreatePopup,
        setIsCreatePopup,
        createPopupCard,
        //update planning
        isUpdatePopup,
        setIsUpdatePopup,
        UpdatePopupCard,
        //delete planning
        isDeletePopup,
        setIsDeletePopup,
        DeletePopupCard,
    } = props;

    const [listOfPlannings, setListOfPlannings] = React.useState(tickets ?? []);
    React.useEffect(() => {
        setListOfPlannings(tickets ?? []);
    }, [tickets]);

    const handleUpdate = (id: any) => {
        handleTicketEdit?.(id);
    };
    const handleDelete = (id: any) => {
        handleTicketDelete?.(id);
    };
    return (
        <div>
            <div className='multi-planning-tickets'>
                {listOfPlannings?.length > 0 &&
                    listOfPlannings.map((elem, index) => {
                        return (
                            <Ticket
                                id={elem.id}
                                key={index}
                                text={elem.name}
                                handleEdit={handleUpdate}
                                handleClose={handleDelete}
                            />
                        );
                    })}
            </div>

            <NewTicket
                handleAddNewTicket={() => setIsCreatePopup(!isCreatePopup)}
                width='fit-content'
                text={newTicketButtonText}
            />
            <Modal
                modalEnabled={isCreatePopup}
                onClose={() => setIsCreatePopup(false)}
            >
                {createPopupCard}
            </Modal>
            <Modal
                modalEnabled={isUpdatePopup}
                onClose={() => setIsUpdatePopup(false)}
            >
                {UpdatePopupCard}
            </Modal>
            <Modal
                modalEnabled={isDeletePopup}
                onClose={() => setIsDeletePopup(false)}
            >
                {DeletePopupCard}
            </Modal>
        </div>
    );
}
interface MultiPlanningTicketsProps {
    newTicketButtonText?: string;
    tickets?: {
        id: string;
        name: string;
    }[];
    handleTicketEdit?: (id: string) => void;
    handleTicketDelete?: (id: string) => void;
    //create new planning
    isCreatePopup?: boolean;
    setIsCreatePopup: (state: boolean) => void;
    createPopupCard: React.ReactNode;
    //update planning
    isUpdatePopup?: boolean;
    setIsUpdatePopup: (state: boolean) => void;
    UpdatePopupCard: React.ReactNode;
    //delete planning
    isDeletePopup?: boolean;
    setIsDeletePopup: (state: boolean) => void;
    DeletePopupCard: React.ReactNode;

    // isPopupOpen?: boolean;
    // changePopupState: (state: boolean) => void;
    // PopupCard: React.ReactNode;
}
MultiPlanningTickets.defaultProps = {
    newTicketButtonText: "Nouveau Département",
    tickets: ["Pizza", "Cuisine", "Salle"],
    handleTicketEdit: (id: string) => { },
    handleTicketDelete: (id: string) => { },
    //create new planning
    isCreatePopup: false,
    setIsCreatePopup: (state: boolean) => { },
    createPopupCard: <NewDepartmentCard />,
    //update planning
    isUpdatePopup: false,
    setIsUpdatePopup: (state: boolean) => { },
    UpdatePopupCard: <NewDepartmentCard />,
    //delete planning
    isDeletePopup: false,
    setIsDeletePopup: (state: boolean) => { },
    DeletePopupCard: <NewDepartmentCard />,

    // isPopupOpen: false,
    // changePopupState: (state: boolean) => {
    // },
    // PopupCard: <NewDepartmentCard />,
};
export default MultiPlanningTickets;
