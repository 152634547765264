import * as React from "react";
import Button from "../../../atoms/Button";
import TextData from "../../../atoms/TextData";
import { useReport } from "../../../../hooks/useReport";
import { useUser } from "../../../../hooks/useUsers";
import Table from "../../Table";
import {
    capitalize,
    formatTime,
    formatDate,
} from "../../../../_helpers/Functions";
import SelectInput from "../../../atoms/SelectInput";
import "./style.css";
import { ReactComponent as NoPlanningSVG } from "./no-plan.svg";
import { ReactComponent as CheckSVG } from "./check.svg";
import { ReactComponent as AttentionVG } from "./attention.svg";
import { useEmployees } from "../../../../hooks/useEmployees";
import { useBranch } from "../../../../hooks/useBranch";
import DatepickerInput from "../../../atoms/DatepickerInput";
import moment from "moment";
import { format } from "path";

function DelayTableRightMenu() {
    const { report, setDelayDateRange, setSelectedEmployee, setDelayData } =
        useReport();
    const {
        getEmployees,
        getPrimesTypes,
        onUpdateFilterBy,
        filterBy,
        employees,
    } = useEmployees();
    const { selectedBranchSettings } = useBranch();
    React.useEffect(() => {
        if (selectedBranchSettings && selectedBranchSettings?._id) {
            getPrimesTypes({ siteId: selectedBranchSettings?._id });
        }
        onUpdateFilterBy({
            ...filterBy,
            name: "",
            niveau: [],
            role: [],
            site: selectedBranchSettings?._id
                ? selectedBranchSettings?._id.toString()
                : undefined,
        });
    }, [selectedBranchSettings]);
    React.useEffect(() => {
        getEmployees({ archived: false });
        setDelayDateRange({
            startDate: formatDate(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            ),
            endDate: formatDate(new Date()),
        });
        return () => {
            setDelayDateRange({ startDate: null, endDate: null });
            setSelectedEmployee(null);
            setDelayData([]);
        };
    }, []);
    React.useEffect(() => {
        if (employees?.length > 0) {
            setSelectedEmployee({
                id: employees[0]._id,
                fname: employees[0].fname,
                lname: employees[0].lname,
                picture: employees[0].picture,
            });
        }
    }, [employees]);
    const handleStartDateChange = (value: any) => {
        const startDate = new Date(value);
        setDelayDateRange({ startDate: formatDate(startDate) });
    };
    const handleEndDateChange = (value: any) => {
        const endDate = new Date(value);
        setDelayDateRange({ endDate: formatDate(endDate) });
    };
    const formatEmployeeDisplay = (user: any) =>
        `${user.fname} ${user.lname}`.trim();
    const listUsers = employees.map((employee: any) => {
        const emp = formatEmployeeDisplay(employee);
        return { label: emp, value: emp };
    });
    const employeesList = [...listUsers];
    const handleSelectEmployee = (selectedOption: any) => {
        const selectedUser = employees.find((user: any) => {
            return formatEmployeeDisplay(user) === selectedOption.value;
        });
        if (selectedUser === undefined) {
            setSelectedEmployee(null);
            return;
        }
        setSelectedEmployee({
            id: selectedUser._id,
            fname: selectedUser.fname,
            lname: selectedUser.lname,
            picture: selectedUser.picture,
        });
    };
    const selectedEmployeeValue = report?.selectedEmployee
        ? {
            label:
                report.selectedEmployee.fname +
                " " +
                report.selectedEmployee.lname,
            value:
                report.selectedEmployee.fname +
                " " +
                report.selectedEmployee.lname,
        }
        : { label: "", value: "" };
    const startDateField = {
        name: "startDate",
        value: report.delayDateRange.startDate,
    };
    const endDateField = {
        name: "endDate",
        value: report.delayDateRange.endDate,
    };
    return (
        <div className="delay-right-menu">
            <DatepickerInput
                required={false}
                field={startDateField}
                onUpdate={handleStartDateChange}
                side="left"
            />
            <DatepickerInput
                required={false}
                field={endDateField}
                onUpdate={handleEndDateChange}
                side="left"
            />
            {/* <SelectInput
                title=''
                options={employeesList}
                selectedValue={selectedEmployeeValue}
                onSelectOption={handleSelectEmployee}
                style={{
                    width: "160px",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "9px",
                    fontSize: "12px",
                    padding: "0 10px",
                }}
            ></SelectInput> */}
        </div>
    );
}

function DelayTableHeader() {
    const { exportDelayReport } = useReport();
    return (
        <div
            style={{
                display: "flex",
                width: "auto",
                justifyContent: "space-between",
                alignItems: "center",
                height: "80px",
                margin: "0 1.25rem",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                }}
            >
                <TextData color="#11106E" size="34" fontWeight="bold">
                    Rapports
                </TextData>
                {/* <button className="fullscreen-btn" onClick={toggleFullScreen}>
                    <div className="fullscreen-icon">
                        {isFullScreen ? (
                            <ExitFullScreenSVG
                                height={18}
                                width={18}
                                color="white"
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        ) : (
                            <FullScreenSVG
                                height={18}
                                width={18}
                                color="white"
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        )}
                    </div>
                    <div className="fullscreen-title">
                    <div>
                        {!isFullScreen
                            ? "Mode plein écran"
                            : "Quitter le mode plein écran"}
                    </div>
                </div>
                </button> */}
            </div>

            <Button
                textColor="white"
                backgroundColor="#2A8BAB"
                style={{
                    fontSize: "16px",
                    gap: "0.25rem",
                    marginTop: "1rem",
                    // alignSelf: "flex-end",
                    marginBottom: "0.5rem",
                }}
                width="auto"
                padding="0.75rem 20px"
                height="auto"
                onClick={exportDelayReport}
            >
                Télécharger le rapport
            </Button>
        </div>
    );
}

function DelayTable() {
    const { getEployeesDelay, report } = useReport();
    React.useEffect(() => {
        if (
            report?.delayDateRange.startDate &&
            report?.delayDateRange.endDate &&
            report?.selectedEmployee
        ) {
            getEployeesDelay();
        }
    }, [report?.delayDateRange, report?.selectedEmployee]);

    const loadDelay = (retard: any, shifts: any, plans: any) => {
        if (
            shifts?.length === 0 ||
            plans?.length === 0 ||
            (plans?.length > 0 && plans[0]?.message)
        )
            return <></>;
        if (retard > 0) return <div>{formatTime(retard)}</div>;
        let stopCondition = false;
        return (
            <>
                {shifts.map((shift: any) => {
                    if (stopCondition) return null;
                    if (shift.message) {
                        stopCondition = true;
                        return null;
                    }
                    if (
                        shift.fromReel === "" ||
                        shift.fromReel === "-" ||
                        !shift.fromReel
                    ) {
                        stopCondition = true;
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <AttentionVG
                                    width={25}
                                    height={25}
                                    color="#fb3640"
                                />
                                Pas de pointage à l'arrivée
                            </div>
                        );
                    }
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                marginTop: "5px",
                                marginBottom: "5px",
                            }}
                        >
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#31af91",
                                    padding: "5px",
                                    borderRadius: "50%",
                                }}
                            >
                                <CheckSVG
                                    width={15}
                                    height={15}
                                    color="white"
                                />
                            </span>
                            À l'heure
                        </div>
                    );
                })}
            </>
        );
    };
    const loadShifts = (shifts: any) => {
        let stopCondition = false;
        if (shifts?.length === 0) return <></>;
        return (
            <>
                {shifts.map((shift: any) => {
                    if (stopCondition) return null;
                    if (shift.message) {
                        stopCondition = true;
                        return <div>{shift.message}</div>;
                    }
                    return (
                        <div>
                            {shift.fromReel} {" - "}
                            {shift.toReel}
                        </div>
                    );
                })}
            </>
        );
    };
    const loadPlans = (plans: any) => {
        if (plans?.length > 0) {
            return (
                <>
                    {plans.map((plan: any) => {
                        return (
                            <div>
                                {plan.message && plan.message}
                                {!plan.message && plan.from + " - " + plan.to}
                            </div>
                        );
                    })}
                </>
            );
        }
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <span
                    style={{
                        backgroundColor: "#fef2e1",
                        color: "white",
                        padding: "5px",
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        fontSize: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <NoPlanningSVG width={20} height={20} color="#fac87c" />
                </span>
                Pas de planning assigné
            </div>
        );
    };
    const loadDelayData = () => {
        let res: any = [];
        if (report && report?.delayData && report?.delayData.data?.length > 0) {
            res = report.delayData.data.map((delayItem: any, index: number) => {
                const { day, shifts, plans, retardReel } = delayItem;
                return {
                    dataRow: [
                        {
                            name: "date",
                            value: capitalize(String(day)),
                            style: {
                                padding: "10px 0",
                                paddingLeft: "15px",
                                borderLeft: "1px solid #EDEFF5",
                                borderRight: "1px solid #EDEFF5",
                                borderBottom: "1px solid #EDEFF5",
                                backgroundColor: `${index % 2 === 0 ? "#f5f6f9" : "white"
                                    }`,
                                color: "#101844",
                            },
                        },
                        {
                            name: "plans",
                            value: loadPlans(plans),
                            style: {
                                padding: "10px 0",
                                textAlign: "center" as "center",
                                borderRight: "1px solid #EDEFF5",
                                borderBottom: "1px solid #EDEFF5",
                                backgroundColor: `${index % 2 === 0 ? "#f5f6f9" : "white"
                                    }`,
                                color: "#4C5690",
                            },
                        },
                        {
                            name: "shifts",
                            value: loadShifts(shifts),
                            style: {
                                padding: "10px 0",

                                textAlign: "center" as "center",
                                borderRight: "1px solid #EDEFF5",
                                borderBottom: "1px solid #EDEFF5",
                                backgroundColor: `${index % 2 === 0 ? "#f5f6f9" : "white"
                                    }`,
                                color: "#4C5690",
                            },
                        },
                        {
                            name: "retard",
                            value: loadDelay(retardReel, shifts, plans),
                            style: {
                                padding: "10px 0",

                                textAlign: "center" as "center",
                                borderRight: "1px solid #EDEFF5",
                                borderBottom: "1px solid #EDEFF5",
                                backgroundColor: `${index % 2 === 0 ? "#f5f6f9" : "white"
                                    }`,
                                color: "#4C5690",
                            },
                        },
                    ],
                };
            });
            res.push({
                dataRow: [
                    {
                        name: "totalsDay",
                        value: `Total jours ${report.delayData.totals.jours}`,
                        style: {
                            padding: "10px 0",
                            paddingLeft: "15px",
                            width: "19%",
                            textAlign: "left",
                            borderLeft: "1px solid #EDEFF5",
                            borderRight: "1px solid #EDEFF5",
                            borderBottom: "1px solid #EDEFF5",
                            backgroundColor: "#A6D3E2",
                            color: "#101844",
                            position: "sticky",
                            bottom: "0",
                            fontWeight: "bold",
                            borderBottomLeftRadius: "8px",
                        },
                    },
                    {
                        name: "empty",
                        value: "",
                        style: {
                            padding: "10px 0",
                            width: "27%",
                            textAlign: "center" as "center",
                            borderLeft: "1px solid #EDEFF5",
                            borderRight: "1px solid #EDEFF5",
                            borderBottom: "1px solid #EDEFF5",
                            backgroundColor: "#A6D3E2",
                            color: "#101844",
                            position: "sticky",
                            bottom: "0",
                            fontWeight: "bold",
                        },
                    },
                    {
                        name: "workedhoursAndDays",
                        value: `${report.delayData.totals.heures.toFixed(
                            2,
                        )} heures / ${report.delayData.totals.workedDays
                            } jours`,
                        style: {
                            padding: "10px 0",
                            width: "27%",
                            borderLeft: "1px solid #EDEFF5",
                            borderRight: "1px solid #EDEFF5",
                            borderBottom: "1px solid #EDEFF5",
                            backgroundColor: "#A6D3E2",
                            color: "#101844",
                            position: "sticky",
                            bottom: "0",
                            textAlign: "center",
                            fontWeight: "bold",
                        },
                    },
                    {
                        name: "totalDelay",
                        value: formatTime(report.delayData.totals.retardReel),
                        style: {
                            padding: "10px 0",
                            width: "27%",
                            textAlign: "center" as "center",
                            borderLeft: "1px solid #EDEFF5",
                            borderRight: "1px solid #EDEFF5",
                            borderBottom: "1px solid #EDEFF5",
                            backgroundColor: "#A6D3E2",
                            color: "#101844",
                            position: "sticky",
                            bottom: "0",
                            fontWeight: "bold",
                            borderBottomRightRadius: "8px",
                        },
                    },
                ],
            });
        }
        return res;
    };
    const DelayTableProps = {
        fields: {
            fieldRow: [
                {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                        >
                            Date
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        paddingLeft: "15px",
                        borderTop: "none",
                        width: "19%",
                        // backgroundColor: "#CFE0E6",
                        backgroundColor: "#A6D3E2",
                    },
                    titleContainerStyle: {
                        // height: "70px",
                        display: "flex",
                        alignItems: "center",
                    },
                },
                {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                        >
                            Planning
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        padding: "10px 0",
                        width: "27%",
                        textAlign: "center" as "center",
                        borderRight: "1px solid #edeff5",
                        borderLeft: "1px solid #edeff5",
                        borderBottom: "1px solid #edeff5",
                        backgroundColor: "#A6D3E2",
                    },
                },
                {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                        >
                            Shift réel
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        padding: "10px 0",
                        width: "27%",
                        textAlign: "center" as "center",
                        backgroundColor: "#A6D3E2",
                        borderRight: "1px solid #edeff5",
                        borderLeft: "1px solid #edeff5",
                    },
                },
                {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                        >
                            Retard
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        padding: "10px 0",
                        width: "27%",
                        textAlign: "center" as "center",
                        backgroundColor: "#A6D3E2",
                        borderRight: "1px solid #edeff5",
                        borderLeft: "1px solid #edeff5",
                    },
                },
            ],
            onClickRow: () => { },
            style: {
                border: "1px solid #EDEFF5",
                borderTop: "none",
                position: "sticky" as "sticky",
                top: 0,
                backgroundColor: "white",
            },
            className: [],
        },

        data: loadDelayData(),
    };
    return (
        <div className="delay-table-container">
            {report?.delayData?.data?.length > 0 ? (
                <Table {...DelayTableProps} />
            ) : (
                <div className="Delay-table-alternative">
                    <TextData
                        variant="heading"
                        fontWeight="bold"
                        size="24px"
                        color="#57569C"
                    >
                        Aucun détail de retard trouvé
                    </TextData>
                    <TextData
                        size="16px"
                        width="500px"
                        color="#57569C"
                        style={{ textAlign: "center" }}
                    >
                        Veuillez sélectionner un employé et une plage de dates
                        pour afficher les détails des retards.
                    </TextData>
                </div>
            )}
        </div>
    );
}

export { DelayTableRightMenu, DelayTable, DelayTableHeader };
