import React from "react";
import DashboardTemplate from "../Dashboard";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import ReportTabNavigation from "../../molecules/TabNavigationV2/ReportTabNavigation";
import Table from "../../organism/Table";
import SelectInput from "../../atoms/SelectInput";
import ReportDisplayOption from "../../molecules/ReportDisplayOption";
import CommingSoon from "../../../pages/Settings/Elements/CommingSoon";

import {
    WorkedHoursTable,
    WorkedHoursTableRightMenu,
    WorkedHoursTableHeader,
    ActivityTable,
    ActivityTableRightMenu,
    ActivityTableHeader,
    PayrollTable,
    PayrollTableRightMenu,
    PayrollTableHeader,
    ProductivityContent,
    ProductivityTableRightMenu,
    ProductivityTableHeader,
    DelayTableRightMenu,
    DelayTable,
    DelayTableHeader,
    VacationTableRightMenu,
    VacationTable,
    VacationTableHeader,
    VacationBarioloTable,
    VacationBarioloTableHeader,
    VacationBarioloTableRightMenu,
} from "../../organism/ReportManagement";
import CalendarInput from "../../molecules/CalendarInput";
import { ROUTER } from "../../../constants/env";
import { useBranch } from "../../../hooks/useBranch";
import { useReport } from "../../../hooks/useReport";
import { useEmployees } from "../../../hooks/useEmployees";
import { useTimeTracker } from "../../../hooks/useTimeTracker";
import { useUser } from "../../../hooks/useUsers";
import { useLogin } from "../../../hooks/useLogin";

function ReportTemplate() {
    const {
        report,
        getReportFromLocalStorage,
        getShowOnlyCurrentMonthHours,
        getMonthlyWorkHours,
    } = useReport();
    const {
        selectedBranchSettings,
        getBranchSettings,
        Settings,
        branchSettings,
        getSiteSettings,
    } = useBranch();
    const { auth } = useLogin();
    const {
        getEmployees,
        employees,
        onUpdateFilterBy,
        filterBy,
        getPrimesTypes,
    } = useEmployees();
    const { getTimeTracker } = useTimeTracker();
    const { getUsersList } = useUser();
    React.useEffect(() => {
        getReportFromLocalStorage();
        getShowOnlyCurrentMonthHours();
    }, []);
    React.useEffect(() => {
        if (selectedBranchSettings?._id) {
            getBranchSettings();
            getSiteSettings();
            getTimeTracker();
        }
    }, [selectedBranchSettings?._id]);
    React.useEffect(() => {
        if (!employees?.length) {
            getEmployees({ archived: false });
        }
        if (selectedBranchSettings && selectedBranchSettings?._id) {
            getPrimesTypes({ siteId: selectedBranchSettings?._id });
        }
        onUpdateFilterBy({
            ...filterBy,
            name: "",
            niveau: [],
            role: [],
            site: selectedBranchSettings?._id
                ? selectedBranchSettings?._id.toString()
                : undefined,
        });
    }, [selectedBranchSettings]);
    React.useEffect(() => {
        getUsersList();
    }, []);

    const [isBariolo, setIsBariolo] = React.useState(false);
    React.useEffect(() => {
        if (
            branchSettings?._id !== null &&
            (auth?.user?.email == "sbelhadj@bariolojuices.com" ||
                auth?.user?.email == "sbenkhaled@bariolojuices.com" ||
                auth?.user?.email == "contact.esperoo@gmail.com")
        ) {
            setIsBariolo(true);
        } else {
            setIsBariolo(false);
        }
    }, [branchSettings?._id, auth?.user]);

    let tabs: any = branchSettings.paie
        ? [
            {
                title: "Heures travaillées",
                headerComponent: <WorkedHoursTableHeader />,
                component: <WorkedHoursTable />,
                rightTabs: <WorkedHoursTableRightMenu />,
                link: ROUTER.REPORT.WORKED_HOURS,
                active: false,
                onClick: () => { },
            },
            {
                title: "Retard",
                headerComponent: <DelayTableHeader />,
                component: <DelayTable />,
                rightTabs: <DelayTableRightMenu />,
                link: ROUTER.REPORT.DELAY,
                active: false,
                onClick: () => { },
            },
            // {
            //     title: "Congé",
            //     headerComponent: <VacationTableHeader />,
            //     component: <VacationTable />,
            //     rightTabs: <VacationTableRightMenu />,
            //     link: ROUTER.REPORT.VACATION,
            //     active: false,
            //     onClick: () => {},
            // },
            ...[
                isBariolo
                    ? {
                        title: "Congé",
                        headerComponent: <VacationBarioloTableHeader />,
                        component: <VacationBarioloTable />,
                        rightTabs: <VacationBarioloTableRightMenu />,
                        link: ROUTER.REPORT.VACATION,
                        active: isBariolo,
                        onClick: () => { },
                    }
                    : [],
            ],
            //   {
            //       title: "Congé Bariolo",
            //       headerComponent: <VacationBarioloTableHeader />,
            //       component: <VacationBarioloTable />,
            //       rightTabs: <VacationBarioloTableRightMenu />,
            //       link: ROUTER.REPORT.VACATION,
            //       active: false,
            //       onClick: () => {},
            //   },
            {
                title: "Rapport paie",
                headerComponent: <PayrollTableHeader />,
                component: <PayrollTable />,
                rightTabs: <PayrollTableRightMenu />,
                link: ROUTER.REPORT.PAYROLL,
                active: false,
                onClick: () => { },
            },
            //   {
            //       title: "Productivité",
            //       headerComponent: <ProductivityTableHeader />,
            //       component: <ProductivityContent />,
            //       rightTabs: <ProductivityTableRightMenu />,
            //       link: ROUTER.REPORT.PRODUCTIVITY,
            //       active: false,
            //       onClick: () => {},
            //   },
            // {
            //     title: "Activité",
            //     headerComponent: <ActivityTableHeader />,
            //     component: <ActivityTable />,
            //     rightTabs: <ActivityTableRightMenu />,
            //     link: ROUTER.REPORT.ACTIVITY,
            //     active: false,
            //     onClick: () => {},
            // },
        ]
        : [
            {
                title: "Heures travaillées",
                headerComponent: <WorkedHoursTableHeader />,
                component: <WorkedHoursTable />,
                rightTabs: <WorkedHoursTableRightMenu />,
                link: ROUTER.REPORT.WORKED_HOURS,
                active: false,
                onClick: () => { },
            },
            {
                title: "Retard",
                headerComponent: <DelayTableHeader />,
                component: <DelayTable />,
                rightTabs: <DelayTableRightMenu />,
                link: ROUTER.REPORT.DELAY,
                active: false,
                onClick: () => { },
            },
            // {
            //     title: "Congé",
            //     headerComponent: <VacationTableHeader />,
            //     component: <VacationTable />,
            //     rightTabs: <VacationTableRightMenu />,
            //     link: ROUTER.REPORT.VACATION,
            //     active: false,
            //     onClick: () => {},
            // },
            ...[
                isBariolo
                    ? {
                        title: "Congé",
                        headerComponent: <VacationBarioloTableHeader />,
                        component: <VacationBarioloTable />,
                        rightTabs: <VacationBarioloTableRightMenu />,
                        link: ROUTER.REPORT.VACATION,
                        active: isBariolo,
                        onClick: () => { },
                    }
                    : [],
            ],
            // {
            //     title: "Productivité",
            //     headerComponent: <ProductivityTableHeader />,
            //     component: <ProductivityContent />,
            //     rightTabs: <ProductivityTableRightMenu />,
            //     link: ROUTER.REPORT.PRODUCTIVITY,
            //     active: false,
            //     onClick: () => {},
            // },
            // {
            //     title: "Activité",
            //     headerComponent: <ActivityTableHeader />,
            //     component: <ActivityTable />,
            //     rightTabs: <ActivityTableRightMenu />,
            //     link: ROUTER.REPORT.ACTIVITY,
            //     active: false,
            //     onClick: () => {},
            // },
        ];
    tabs = tabs.filter((tab: any) => {
        if (
            tab &&
            (typeof tab === "object" ? Object.keys(tab)?.length > 0 : true)
        ) {
            return true;
        } else {
            return false;
        }
    });
    return <ReportTabNavigation tabs={tabs} />;
}

export default ReportTemplate;
