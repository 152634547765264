import React from "react";
import ZInput from "../../atoms/ZInput";
import RolePickerInput from "../../molecules/RolePickerInput";
import theme from "../../../tailwind-theme";
import EstablishementPickerPopup from "../../molecules/EstablishementPickerPopup";
import { useIntl, FormattedMessage } from "react-intl";
import { useLogin } from "../../../hooks/useLogin";

export default function CreateEmployeeRolesAndAccess({
    employee,
    onChange,
    sites,
    onBlur,
    departements,
    multiplePlanning,
}: CreateEmployeeRolesAndAccessInterface) {
    const { user } = useLogin();

    const roles =
        user?.role === "Manager"
            ? [
                {
                    title: <FormattedMessage id="MENU.EMPLOYEE" />,
                    desc: <FormattedMessage id="ROLE.EMPLOYEE.DESC" />,
                    value: "Employee",
                    bgColor: theme?.colors?.["employee-op-05"],
                    bgMidColor: theme.colors["employee-op-2"],
                    bgActiveColor: theme.colors["employee"],
                },
            ]
            : [
                {
                    title: <FormattedMessage id="MENU.EMPLOYEE" />,
                    desc: <FormattedMessage id="ROLE.EMPLOYEE.DESC" />,
                    value: "Employee",
                    bgColor: theme?.colors?.["employee-op-05"],
                    bgMidColor: theme.colors["employee-op-2"],
                    bgActiveColor: theme.colors["employee"],
                },
                {
                    title: <FormattedMessage id="MENU.MANAGER" />,
                    desc: <FormattedMessage id="ROLE.MANAGER.DESC" />,
                    value: "Manager",
                    bgColor: theme.colors["manager-op-05"],
                    bgMidColor: theme.colors["manager-op-2"],
                    bgActiveColor: theme.colors["manager"],
                },
                {
                    title: <FormattedMessage id="MENU.ADMINISTRATOR" />,
                    desc: <FormattedMessage id="ROLE.ADMINISTRATOR.DESC" />,
                    value: "Administrator",
                    bgColor: theme.colors["admin-op-05"],
                    bgMidColor: theme.colors["admin-op-2"],
                    bgActiveColor: theme.colors["admin"],
                },
            ];
    const intl = useIntl();
    return (
        <div
            className="w-full px-5 flex flex-col gap-5"
            style={{ marginLeft: "200px" }}
        >
            <div
                className="flex flex-wrap gap-3 mb-4"
                style={{ alignItems: "baseline" }}
            >
                <div style={{ width: "30%" }}>
                    <ZInput
                        label={intl.formatMessage({
                            id: "EMPLOYEE.ADD.LABEL.PIN",
                        })}
                        placeholder={intl.formatMessage({
                            id: "EMPLOYEE.ADD.PLACEHOLDER.PIN",
                        })}
                        field={employee.pin}
                        required={true}
                        onBlur={onBlur}
                        onUpdate={(value) => {
                            onChange("pin", value);
                        }}
                        maxLength={4}
                        isNumber={true}
                    />
                </div>
                <div style={{ width: "30%" }}>
                    <ZInput
                        label="Heure de travail hebdo (h)"
                        placeholder="Exemple: 35"
                        type="number"
                        field={employee.tempsDeTravailHebdomadaire}
                        required={false}
                        onBlur={onBlur}
                        onUpdate={(value) => {
                            let theValue = Number(value);
                            const decimalRegex = /^\d+(\.\d{0,2})?$/;
                            if (
                                !(
                                    !decimalRegex.test(String(value)) ||
                                    isNaN(theValue) ||
                                    theValue <= 0 ||
                                    theValue > 99.99 ||
                                    String(value)?.length > 5
                                ) ||
                                String(value) === ""
                            ) {
                                onChange("tempsDeTravailHebdomadaire", value);
                            }
                        }}
                        isNumber={true}
                        maxLength={5}
                    />
                </div>
                <div style={{ width: "30%" }}>
                    <ZInput
                        label="Heure de travail mensuel(h)"
                        placeholder="Exemple: 140"
                        type="number"
                        field={employee.tempsDeTravailMensuel}
                        required={false}
                        onBlur={onBlur}
                        onUpdate={(value) => {
                            const decimalRegex = /^\d+(\.\d{0,2})?$/;
                            if (
                                !(
                                    !decimalRegex.test(String(value)) ||
                                    Number(value) <= 0 ||
                                    Number(value) > 999.99 ||
                                    String(value)?.length > 6
                                ) ||
                                String(value) === ""
                            ) {
                                onChange("tempsDeTravailMensuel", value);
                            }
                        }}
                        isNumber={true}
                        maxLength={6}
                    />
                </div>
            </div>
            <RolePickerInput
                title={intl.formatMessage({
                    id: "EMPLOYEE.ADD.LABEL.PICK_ROLE",
                })}
                value={employee.role.value}
                roles={roles}
                onPress={(value) => {
                    onChange("role", value);
                }}
            />
            <EstablishementPickerPopup
                pickerType="create"
                title={intl.formatMessage({
                    id: "EMPLOYEE.ADD.LABEL.ESTABLISHEMENT",
                })}
                establishements={sites}
                onChange={onChange}
                role={employee.role.value}
                manage={employee.manage.value}
                belongsTo={employee.belongsTo.value}
                planning={employee.planning.value}
                selectedDepartment={employee.departements.value}
                // departements={departements.map((dep) => ({
                //     label: dep.name,
                //     value: dep._id,
                // }))}
                departements={departements}
                multiplePlanning={multiplePlanning}
                error={employee.belongsTo.error || employee.departements.error}
                hasError={
                    employee.belongsTo.hasError ||
                    employee.departements.hasError
                }
            />
        </div>
    );
}

interface CreateEmployeeRolesAndAccessInterface {
    employee: any;
    onChange: (field: string, value: any) => void;
    sites: any[];
    onBlur: (field: string) => void;
    departements: any[];
    multiplePlanning: boolean;
}
