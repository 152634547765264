import React from "react";

import "./style.scss";

// import Input from "../../atoms/Input";
import SelectInput from "../../atoms/SelectInput";
// import { ReactComponent as ToggleSVG } from "./toggle.svg";
import { ReactComponent as NotificationSVG } from "./notification.svg";
import { ReactComponent as MoreSVG } from "./more.svg";
// import searchSVG from "./search.svg";
// import hiddenserach from "./hiddenserach.svg";
import Picture from "../../atoms/Picture";
import TopbarDropdown from "../TopbarDropdown";
import NotificationCard from "../NotificationCard";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
// import * as Icons from "../../../Icons";
import { User, Settings, CreditCard, Logout, Search } from "../../../Icons";
import { useEmployees } from "../../../hooks/useEmployees";
import ZInput from "../../atoms/ZInput";
// import { useUser } from "../../../hooks/useUsers";
import { useLogin } from "../../../hooks/useLogin";
import { useReport } from "../../../hooks/useReport";
import Select from "react-select";
// import TextData from "../../atoms/TextData";
import { useNotifications } from "../../../hooks/useNotifications";
import { useLocation } from "react-router-dom";
export default function TopBar(props: TopBarProps) {
    const {
        searchPlaceholder,
        // handleHamburger,
        selectElements,
        selectedValue,
        // handleNotification,
        avatarImg,
        firstName,
        lastName,
        email,
        handleItemSelection,
        signout,
    } = props;

    const { user } = useLogin();
    const intl = useIntl();
    const navigate = useNavigate();
    const [items, setItems] = React.useState<any>(() => {
        return selectElements?.map((selectElement: any) => ({
            value: selectElement.value,
            label: selectElement.label,
        }));
    });
    const [defaultItem, setDefaultItem] = React.useState<any>(selectedValue);
    React.useEffect(() => {
        setItems(selectElements);
        if (selectElements?.length === 2) {
            // =2 because we consider both the only site and the default value "tous les etablissements"
            handleItemSelection?.(selectElements[1]);
        }
    }, [selectElements]);
    React.useEffect(() => {
        setDefaultItem(selectedValue);
    }, [selectedValue]);

    const location = useLocation();
    const [showNormalSearch, setIndelay] = React.useState(false);

    React.useEffect(() => {
        if (location.pathname === "/report/delay") {
            setIndelay(false);
        } else {
            setIndelay(true);
            // onUpdateFilterBy({
            //     ...filterBy,
            //     name: "hello",
            // });
        }
    }, [location]);
    // const handleHamburgerIcon = () => {
    //     handleHamburger && handleHamburger();
    // };
    // const handleNotificationDisplay = () => {
    //     handleNotification && handleNotification();
    // };
    // const { filterBy, onUpdateFilterBy } = useEmployees();

    const [showDropdonwMenu, setShowDropdownMenu] = React.useState(false);
    const [showNotifications, setShowNotifications] = React.useState(false);
    const hideNotifications = () => {
        setShowNotifications(false);
    };
    const toggleNotificationsDisplay = () => {
        setShowNotifications((prev) => !prev);
    };

    const { notifications, getNotifications, setNotificationsSeen } =
        useNotifications();

    const hideDropdownMenu = () => {
        setShowDropdownMenu(false);
    };
    const toggleDropdownMenu = () => {
        setShowDropdownMenu((prev) => !prev);
    };

    const navigateToProfile = () => {
        if (user) {
            navigate(`/employees/${user._id}/edit`);
        }
        hideDropdownMenu();
    };
    const navigateToSettings = () => {
        hideDropdownMenu();
        navigate("/settings/branch");
    };
    // const navigateToSub = () => {
    //     hideDropdownMenu();
    // };
    const logout = () => {
        signout();
        hideDropdownMenu();
        navigate("/");
    };

    const dropDownMenus = [
        {
            title: intl.formatMessage({ id: "MENU.PROFILE" }),
            action: navigateToProfile,
            Icon: <User />,
        },
        {
            title: intl.formatMessage({ id: "MENU.SETTINGS" }),
            action: navigateToSettings,
            Icon: <Settings />,
        },
        // {
        //     title: intl.formatMessage({ id: "MENU.SUBSCRIBE" }),
        //     action: navigateToSub,
        //     Icon: <CreditCard />,
        // },
        {
            title: intl.formatMessage({ id: "MENU.LOGOUT" }),
            action: logout,
            Icon: <Logout />,
        },
    ];
    const notificationHead = {
        title: "Notifications",
        Icon: <Settings color="white" />,
    };
    const divRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        document.addEventListener("click", (event) => {
            if (
                divRef &&
                divRef.current &&
                !divRef.current.contains(event.target as Node)
            ) {
                hideDropdownMenu();
            }
        });
        return () => {
            document.removeEventListener("click", () => {});
        };
    }, []);
    //toggle notifications
    const notifRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        document.addEventListener("click", (event) => {
            if (
                notifRef &&
                notifRef.current &&
                !notifRef.current.contains(event.target as Node)
            ) {
                hideNotifications();
            }
        });
        return () => {
            document.removeEventListener("click", () => {});
        };
    }, []);
    const { onUpdateFilterBy, filterBy, employees } = useEmployees();
    const { report, setSelectedEmployee } = useReport();
    const formatEmployeeDisplay = (user: any) =>
        `${user.fname} ${user.lname}`.trim();
    const handleSelectEmployee = (selectedOption: any) => {
        const selectedUser = employees.find(
            (user: any) => formatEmployeeDisplay(user) === selectedOption.value,
        );

        if (selectedUser === undefined) {
            setSelectedEmployee(null);
            return;
        }
        setSelectedEmployee({
            id: selectedUser._id,
            fname: selectedUser.fname,
            lname: selectedUser.lname,
            picture: selectedUser.picture,
        });
    };
    // const listUsers = employees.map((employee: any) => {
    //     const emp = formatEmployeeDisplay(employee);
    //     return { label: emp, value: emp };
    // });
    // const employeesList = [...listUsers];
    const employeesList = employees.map((employee: any) => {
        const emp = formatEmployeeDisplay(employee);
        return { label: emp, value: emp };
    });
    const selectedEmployeeValue = report?.selectedEmployee
        ? {
              label:
                  report.selectedEmployee.fname +
                  " " +
                  report.selectedEmployee.lname,
              value:
                  report.selectedEmployee.fname +
                  " " +
                  report.selectedEmployee.lname,
          }
        : { label: "Chercher un employé...", value: "Chercher un employé..." };
    // Define custom styles for react-select
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "none",
            boxShadow: "none",
            outline: "none",
            height: "100%",
            width: "100%",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        dropdownIndicator: () => ({
            display: "none",
        }),
    };

    return (
        <div className="topbar-container z-9">
            <div className="flex items-center gap-4">
                {/* <img
                    className='basis-[160px] max-w-[160px] hidden md:block'
                    src={"/assets/imgs/Esperoo_fullcolor.png"}
                    alt='logo'
                /> */}
                {/* <img
                    className='basis-[50px] max-w-[50px] hidden sm:block md:hidden'
                    src={"/assets/imgs/logo.png"}
                    alt='logo'
                /> */}
                {/* <div> */}
                {/* <ToggleSVG
                        onClick={handleHamburgerIcon}
                        // className='mcursor-pointer'
                        style={{
                            cursor: "pointer",
                        }}
                    /> */}
                {/* </div> */}

                <div className="md:block">
                    <div className="search-section">
                        {/* Use the custom styles in the Select component */}

                        {/* <SelectInput
                            title=''
                            options={employeesList}
                            selectedValue={selectedEmployeeValue}
                            onSelectOption={handleSelectEmployee}
                            style={{
                                width: "300px",
                                height: "30px",
                                marginRight: "10px",
                                borderRadius: "9px",
                                fontSize: "12px",
                                padding: "0 10px",
                            }}
                        ></SelectInput> */}
                        {showNormalSearch ? (
                            <ZInput
                                inputClasses="!border-none"
                                leftInputAction={
                                    <div>
                                        <Search />
                                    </div>
                                }
                                required={false}
                                field={{
                                    name: "search",
                                    value: filterBy.name,
                                }}
                                placeholder={searchPlaceholder}
                                onUpdate={(value: any) => {
                                    onUpdateFilterBy({
                                        ...filterBy,
                                        name: value,
                                    });
                                }}
                            />
                        ) : (
                            <div className="relative h-[48px] w-[190.8px] flex items-center">
                                <div className="flex items-center justify-center">
                                    <Search />
                                </div>
                                <Select
                                    className="h-full w-full px-2 py-1 text-md font-light text-n-600 peer"
                                    options={employeesList}
                                    value={selectedEmployeeValue}
                                    placeholder={"Chercher un employé..."}
                                    styles={customStyles}
                                    onChange={(val) =>
                                        handleSelectEmployee(val)
                                    }
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className="select-section"
                style={{
                    zIndex: "998",
                }}
            >
                {items?.length > 2 && (
                    <SelectInput
                        id="branchSelection"
                        isSearchable={false}
                        options={items}
                        selectedValue={defaultItem}
                        title=""
                        style={{
                            backgroundColor: "#EDEFF5",
                            borderWidth: 0,
                            borderRadius: 8,
                            borderRight: "16px solid transparent",
                            // padding: "7px 25px",
                            width: "auto",
                            minWidth: "220px",
                            color: "#2A346D",
                            zIndex: "998",
                        }}
                        styleOption={{
                            paddingRight: 15,
                            color: "#4C5690",
                        }}
                        onSelectOption={handleItemSelection}
                    />
                )}
            </div>
            <div className="flex items-center gap-[15px] md:gap-[25px]">
                {/* <div className='md:hidden'>
                    <Search />
                </div> */}
                <div className="notifications-wrapper" ref={notifRef}>
                    <div className="notifications-icon">
                        {notifications.unread > 0 && (
                            <span>
                                {notifications.unread > 99
                                    ? "+99"
                                    : notifications.unread}
                            </span>
                        )}
                        <NotificationSVG onClick={toggleNotificationsDisplay} />
                    </div>
                    <NotificationCard
                        head={notificationHead}
                        show={showNotifications}
                        onHide={hideNotifications}
                        notifications={notifications}
                        getNotifications={getNotifications}
                        setNotificationsSeen={setNotificationsSeen}
                    />
                </div>

                <div ref={divRef} className="flex items-center gap-[25px]">
                    <div
                        className="profile-section"
                        onClick={toggleDropdownMenu}
                    >
                        <div className="image-container">
                            {avatarImg ? (
                                <Picture
                                    imgPath={avatarImg}
                                    className={["!w-full", "!h-auto"]}
                                />
                            ) : (
                                <div className="flex h-full items-center justify-center bg-n-0 font-bold">
                                    {firstName[0]}
                                    {lastName[0]}
                                </div>
                            )}
                        </div>

                        <div>
                            <span className="text-n-700 text-md font-bold block">
                                {firstName} {lastName}
                            </span>
                            <span className="text-n-400 text-sm font-medium block">
                                {email}
                            </span>
                        </div>
                    </div>
                    <div>
                        <MoreSVG
                            onClick={toggleDropdownMenu}
                            className="cursor-pointer"
                        />
                    </div>
                    <div>
                        <TopbarDropdown
                            items={dropDownMenus}
                            show={showDropdonwMenu}
                            onHide={hideDropdownMenu}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

type DropdownItemProps = {
    title: string | React.ReactNode;
    action: () => void;
    icon: string;
    activeIcon: string;
};

interface TopBarProps {
    searchPlaceholder?: string;
    handleHamburger?: () => void;
    selectElements?: string[];
    selectedValue?: string;
    handleNotification?: () => void;
    avatarImg?: string;
    firstName: string;
    lastName: string;
    email: string;
    handleItemSelection?: (option: any) => void;
    signout: () => void;
}
/*
TopBar.defaultProps = {
  searchPlaceholder: "Recherche...",
  handleHamburger: () => {
  },
  selectElements: [
    "Tous les établissements",
    "Marseille",
    "Paris",
    "Ile de france",
  ],
  selectedValue: "Tous les établissements",
  handleNotification: () => {
  },
  avatarImg: avatar,
  fullName: "andy warhol",
  email: "andywarhol@gmail.com",
  handleSeeMore: () => {
  },
  handleItemSelection: (option: any) => {
  },
};
*/
