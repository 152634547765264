import * as React from "react";

import "./style.scss";
import Button from "../../atoms/Button";

export default function LevelProgressBar(props: any /*LevelProgressBarProps*/) {
    const {
        maxProgress,
        minProgress,
        // currentProgress,
        containerStyle,
        setLevels,
        levels,
        oneLevel,
    } = props;

    const handleAddToLevels = (newProgress: any) => {
        setLevels((prev: any) => {
            let newLevels: any = [];
            let found = false;
            for (let i = 0; i < prev?.length; i++) {
                if (prev[i] === String(newProgress)) {
                    found = true;
                    continue;
                }
                newLevels.push(String(prev[i]));
            }
            if (!found) {
                newLevels.push(String(newProgress));
            }
            // setProgress(newLevels);
            return newLevels;
        });
        // if (newProgress === progress) {
        //     setProgress(null);
        //     setLevels && setLevels(null);
        // } else {
        //     setProgress(newProgress);
        //     setLevels && setLevels(String(newProgress));
        // }
    };
    const handleChangeLevel = (newProgress: any) => {
        setLevels(String(newProgress));
    };
    const progressElements = [];
    for (let index = minProgress; index <= maxProgress; index++) {
        progressElements.push(
            <Button
                key={index}
                className={["element-container"]}
                width="100%"
                height="37px"
                backgroundColor={
                    levels.includes(index) || levels.includes(String(index))
                        ? "#2A8BAB"
                        : "#EDEFF5"
                }
                textColor={
                    levels.includes(index) || levels.includes(String(index))
                        ? "#EDEFF5"
                        : "#2A346D"
                }
                onClick={() => {
                    if (oneLevel) {
                        handleChangeLevel(index);
                    } else {
                        handleAddToLevels(index);
                    }
                }}
                style={{
                    fontSize: "small",
                    fontWeight: "bold",
                }}
            >
                {index === 1
                    ? "Débutant"
                    : index === 2
                        ? "Intermédiaire"
                        : "Confirmé"}
            </Button>,
        );
    }

    return (
        <div className="level-progress-bar-container" style={containerStyle}>
            {progressElements}
        </div>
    );
}

interface LevelProgressBarProps {
    maxProgress: number;
    minProgress: number;
    currentProgress: number;
    containerStyle: React.CSSProperties;
}

LevelProgressBar.defaultProps = {
    maxProgress: 3,
    minProgress: 1,
    currentProgress: null,
    containerStyle: {
        width: "auto",
    },
    oneLevel: false,
};
