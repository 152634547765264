import React from "react";
import ReportTemplate from "../../components/templates/Report";
import { useBranch } from "../../hooks/useBranch";
export default function Report(props: ReportProps) {
    const { selectedBranchSettings, getSiteSettings } = useBranch();
    React.useEffect(() => {
        getSiteSettings();
    }, [selectedBranchSettings]);
    // if (!selectedBranchSettings?._id) {
    //     return (
    //         <div
    //             style={{
    //                 marginTop: "20px",
    //                 padding: "20px",
    //                 backgroundColor: "rgb(173 235 255)",
    //                 borderRadius: "8px",
    //                 color: "#4c5690",
    //             }}
    //         >
    //             Veuillez sélectionner un établissement
    //         </div>
    //     );
    // }
    return <ReportTemplate />;
}

interface ReportProps { }
