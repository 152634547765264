import React, { useRef } from "react";
import SideBarItem from "../../atoms/SidebarItem";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icons from "../../../Icons";
import Theme from "../../../tailwind-theme";
import "./style.css";
import { ReactComponent as ExpandSVG } from "./expand.svg";
import { ReactComponent as CollapseSVG } from "./collapse.svg";
import { ReactComponent as DashboardSVG } from "./dashboard.svg";
import { ReactComponent as PlanningSVG } from "./planning.svg";
import { ReactComponent as PointageSVG } from "./pointage.svg";
import { ReactComponent as EmployeesSVG } from "./employees.svg";
import { ReactComponent as RHSVG } from "./rh.svg";
import { ReactComponent as CongeSVG } from "./conge.svg";
import { ReactComponent as ProductivitySVG } from "./productivity.svg";
import { ReactComponent as SignSVG } from "./sign.svg";
import { ReactComponent as ReportSVG } from "./report.svg";
import { ReactComponent as SettingsSVG } from "./settings.svg";
import { ReactComponent as MessagesSVG } from "./messages.svg";
import { ReactComponent as PaperSVG } from "./paper.svg";

import { ROUTER } from "../../../constants/env";
import { useBranch } from "../../../hooks/useBranch";
import { useLogin } from "../../../hooks/useLogin";
import { VERSION } from "../../../constants/env";
import { ScrollText } from "lucide-react";

interface Path {
    name: string;
    url?: string;
    Icon: React.ReactNode;
    subRoutes?: {
        name: string;
        url: string;
        Icon: React.ReactNode;
        hide?: boolean;
    }[];
}

const ZendeskComponent = () => {
    const initCallback = () => {
        // console.log("Script loaded and ready!");
    };
    const zendeskSettings = {
        // color: { theme: "#ff0000" },
        contactForm: {
            suppress: false,
        },
        contactOptions: {
            enabled: true,
            contactButton: { "*": "Contactez nous" },
        },
        position: {
            horizontal: "left",
            vertical: "bottom",
        },
        zIndex: -1,
    };

    const zendeskRef = React.useRef<any>(null);
    const handleZendeskClick = () => {
        console.log("clicked", zendeskRef.current);
        if (zendeskRef) {
            zendeskRef.current?.click();
        }
    };

    return (
        <>
            <div
                style={{
                    backgroundColor: "white",
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "5px",
                }}
                onClick={handleZendeskClick}
            >
                <MessagesSVG height={25} width={25} color="#2a8bab" />
            </div>
            <div className="help">
                <div>
                    <div
                        style={{
                            fontSize: "12px",
                        }}
                    >
                        Besoin d'aide ?
                    </div>
                    <div
                        style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                        }}
                    >
                        Contactez-nous
                    </div>
                </div>
            </div>
        </>
    );
};

export default function Sidebar({
    expanded,
    hidden = true,
    // routes = menuRoutes,
    logo = "/assets/imgs/logo-full.png",
    minimisedLogo = "/assets/imgs/logo.png",
    handleExpand,
    toggleExpand,
    allowedAccessPages,
}: SidebarInterface) {
    const location = useLocation();
    const auth = useLogin();
    const navigate = useNavigate();
    const menuClicked = (url: string) => {
        navigate(`/${url}`);
    };
    const { selectedBranchSettings, branchSettings, Settings } = useBranch();

    const checkPlanningOn = React.useMemo(() => {
        if (auth?.user?.realLicense?.length) {
            for (let license of auth?.user?.realLicense) {
                if (license?._id === selectedBranchSettings?.license)
                    return license?.planningOn;
            }
        }
        return false;
    }, [auth?.user, selectedBranchSettings]);
    const isRoutesAllowed = (route: any) => {
        switch (route?.name) {
            case "Planning":
                return (
                    allowedAccessPages.includes(route.name) && checkPlanningOn
                );
            case "Pointage":
                return allowedAccessPages.includes("Pointeuse");
            case "Employés":
                return allowedAccessPages.includes(route.name);
            case "R.H":
                return allowedAccessPages.includes("Employés");
            case "Rapports":
                return allowedAccessPages.includes(route.name);
            default:
                return false;
        }
    };
    // const getNavClassname = () => {
    //     let baseClass =
    //         "flex fixed flex-col bg-white min-h-screen left-[-400px] duration-200 ease-linear pt-[20px] z-10";
    //     if (hidden) {
    //         //baseClass += " transform translate-x-[-400px]"
    //     } else {
    //         baseClass += " transform translate-x-[400px]";
    //     }
    //     if (expanded) {
    //         baseClass += " min-w-[140px] max-w-[140px]";
    //     } else {
    //         baseClass += " min-w-[70px] max-w-[70px]";
    //     }
    //     return baseClass;
    // };
    const [expandedItem, setExpandedItem] = React.useState(-1);

    const menuRoutes: Path[] = [
        // {
        //     name: "Dashboard",
        //     url: "dashboard",
        //     Icon: <DashboardSVG color='inherit' />,
        // },
        {
            name: "Planning",
            url: "planning",
            Icon: <PlanningSVG color="inherit" />,
        },
        {
            name: "Pointage",
            url: "pointeuse",
            Icon: <PointageSVG color="inherit" />,
        },
        {
            name: "Employés",
            url: "employees",
            Icon: <EmployeesSVG color="inherit" />,
        },
        {
            name: "R.H",
            Icon: <RHSVG color="inherit" />,
            subRoutes: [
                {
                    name: "Congés",
                    url: "conges",
                    Icon: <CongeSVG height={20} width={20} color="inherit" />,
                },
                {
                    name: "Productivité",
                    url: "productivity",
                    Icon: (
                        <ProductivitySVG
                            height={20}
                            width={20}
                            color="inherit"
                        />
                    ),
                },

                {
                    name: "Feuille d'émargement ",
                    url: "feuille",
                    Icon: <PaperSVG height={20} width={20} color="inherit" />,
                    hide: !branchSettings?.activateEmergencePage,
                },
            ],
        },
        {
            name: "Rapports",
            url: "report/worked_hours",
            Icon: <ReportSVG color="inherit" />,
        },
    ];
    return (
        <div /*className={getNavClassname()}*/
            className={`sidebar-container ${expanded ? "static" : ""}`}
            style={{
                backgroundColor: "white",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        gap: "10px",
                        // marginTop: "25px",
                        // marginBottom: "30px",
                        padding: "20px 0",
                        overflow: "hidden",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",

                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            fontSize: "30px",
                        }}
                    >
                        <div
                            className="logo-name"
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src="/static/media/logo.2b17c7ae00beb3aec19ff68866a0d9da.svg"
                                title="img"
                                alt=""
                                // className='Picture'
                                style={{
                                    zIndex: "1",
                                    cursor: "pointer",
                                    // width: "120%",
                                    height: "44px",
                                    transition: "none",
                                }}
                            />
                            {/* <div
                                style={{
                                    fontSize: "small",
                                    fontWeight: "bold",
                                    color: "#1a6f99",
                                    background:
                                        "linear-gradient(to right, #aacee8, transparent)",
                                    marginTop: "-8px",
                                    marginLeft: "-5px",
                                    position: "relative",
                                    padding: "1px",
                                    paddingLeft: "10px",
                                    paddingRight: "8px",
                                    borderRadius: "5px",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        height: "110%",
                                        width: "20px",
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        left: "-13px",
                                        top: "47%",
                                        transform: "translateY(-50%)",
                                    }}
                                ></div>
                                Beta
                            </div> */}
                        </div>
                        <img
                            src="/static/media/logo.97a8262ed67f2ae54933286c5d9fe6bd.svg"
                            title="img"
                            alt="img"
                            className="logo-picture"
                            // style='width: auto; height: auto;'
                            style={{
                                width: "auto",
                                height: "30px",
                            }}
                        />
                    </div>
                    <div
                        className="expand-icon"
                        style={{
                            alignSelf: "flex-end",
                            marginRight: "10px",
                        }}
                    >
                        {expanded ? (
                            <CollapseSVG
                                width={20}
                                height={20}
                                color="#2a346d"
                                onClick={() =>
                                    toggleExpand((prev: boolean) => !prev)
                                }
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        ) : (
                            <ExpandSVG
                                width={20}
                                height={20}
                                color="#2a346d"
                                onClick={() =>
                                    toggleExpand((prev: boolean) => !prev)
                                }
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        )}
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "calc(100% - 250px)",
                        overflowY: "auto",
                    }}
                >
                    {/* {routes */}
                    {menuRoutes
                        .map((route, index) => {
                            if (!isRoutesAllowed(route)) return null;
                            if (route.subRoutes) {
                                return (
                                    <>
                                        <SideBarItem
                                            key={index}
                                            title={route.name}
                                            Icon={route.Icon}
                                            showSubRoutes={
                                                expandedItem === index
                                            }
                                            active={
                                                !!route.url &&
                                                location.pathname.indexOf(
                                                    route.url,
                                                ) !== -1
                                            }
                                            onClick={() =>
                                                setExpandedItem(
                                                    (prev: number) =>
                                                        prev === index
                                                            ? -1
                                                            : index,
                                                )
                                            }
                                            expanded={expanded}
                                        />
                                        {expandedItem === index &&
                                            route?.subRoutes?.map((item, i) => {
                                                if (item?.hide) return null;
                                                return (
                                                    <SideBarItem
                                                        key={i}
                                                        title={item.name}
                                                        Icon={item.Icon}
                                                        active={
                                                            location.pathname.indexOf(
                                                                item.url,
                                                            ) !== -1
                                                        }
                                                        onClick={() =>
                                                            menuClicked(
                                                                item.url,
                                                            )
                                                        }
                                                        expanded={expanded}
                                                        subItem
                                                    />
                                                );
                                            })}
                                    </>
                                );
                            } else {
                                return (
                                    <SideBarItem
                                        key={index}
                                        title={route.name}
                                        Icon={route.Icon}
                                        active={
                                            !!route.url &&
                                            location.pathname.indexOf(
                                                route.url,
                                            ) !== -1
                                        }
                                        onClick={() =>
                                            route.url && menuClicked(route.url)
                                        }
                                        expanded={expanded}
                                    />
                                );
                            }
                        })
                        ?.filter((item: any) => item !== null)}
                    <div
                        style={{
                            backgroundColor: "rgb(76 86 144 / 48%)",
                            width: "70%",
                            height: "1px",
                            margin: "10px auto",
                            borderRadius: "5px",
                        }}
                    ></div>
                    <SideBarItem
                        title={"Paramètres"}
                        Icon={<SettingsSVG color="inherit" />}
                        active={location.pathname.indexOf("settings") !== -1}
                        onClick={() => {
                            menuClicked("settings/branch");
                        }}
                        expanded={expanded}
                    />
                </div>

                <div
                    style={{
                        justifySelf: "flex-end",
                        alignSelf: "center",
                        marginTop: "auto",
                        marginBottom: "20px",
                    }}
                >
                    <div
                        style={{
                            background:
                                "linear-gradient(95deg, #2a8bab 0%, #2d2aad 100%)",
                            backgroundImage:
                                "linear-gradient(95deg, #2a8bab, #2d2aad)",
                            // boxShadow: "rgba(0, 0, 0, 0.5) 0px 3px 8px",
                            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.5)",
                            zIndex: "9998",
                            color: "white",
                            borderRadius: "30px",
                            padding: "8px",
                            marginBottom: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                            // height: "60px",
                            cursor: "pointer",
                        }}
                        onClick={() => navigate(ROUTER.SUPPORT)}
                    >
                        <ZendeskComponent />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#2d37ad",
                            fontWeight: "bold",
                        }}
                    >
                        V<div className="version">ersion</div>
                        {VERSION}
                    </div>
                </div>
            </div>
        </div>
    );
}

interface RouteInterface {
    name: string;
    url?: string;
    Icon: React.ReactNode;
    subRoutes?: {
        name: string;
        url: string;
        Icon: React.ReactNode;
    }[];
}

interface SidebarInterface {
    expanded: boolean;
    hidden?: boolean;
    handleExpand: () => void;
    toggleExpand: any;
    logo?: string;
    minimisedLogo?: string;
    routes?: Array<RouteInterface>;
    allowedAccessPages?: any;
}