import React, { useEffect } from "react";
import ZToggleButton from "../../atoms/ZToggleButton";
import ZCheckBox from "../../atoms/ZCheckbox";
import ZSelect from "../../atoms/ZSelect";
import { FormattedMessage, useIntl } from "react-intl";
import SelectInput from "../../atoms/SelectInput";
// import SelectInput from "../SelectInput";

const managePages = [
    { value: "Planning", label: <FormattedMessage id={"MENU.PLANNING"} /> },
    { value: "Pointeuse", label: <FormattedMessage id={"MENU.POINTEUSE"} /> },
    { value: "Employés", label: <FormattedMessage id={"MENU.EMPLOYEES"} /> },
    { value: "Rapports", label: <FormattedMessage id={"MENU.RAPPORTS"} /> },
];

function EstablishementPickerPopup({
    pickerType,
    establishements,
    title,
    manage,
    role,
    belongsTo,
    planning,
    selectedDepartment,
    onChange,
    direction = "row",
    departements,
    multiplePlanning,
    error,
    hasError,
}: EstablishementPickerInterface) {
    const [branchsLabeled, setBranchsLabeled] = React.useState(() => {
        const sessionUser = sessionStorage.getItem("user");
        let sessionLicense: any[] = [];
        if (sessionUser) {
            sessionLicense = JSON.parse(sessionUser).realLicense;
        }
        if (sessionLicense?.length > 1) {
            return establishements.map((etab: any) => {
                const LicenseEnseigne = sessionLicense.find((license: any) => {
                    return license._id === etab.license;
                });
                let enseigneName = "";
                if (LicenseEnseigne) {
                    enseigneName = LicenseEnseigne.enseigne;
                }
                const etablissement = {
                    ...etab,
                    name: `${enseigneName} - ${etab.name}`,
                };
                return etablissement;
            });
        } else {
            return establishements;
        }
    });
    const getTogglLabel = (text: string, active: boolean) => (
        <label
            className={`font-bold text-md ${active ? "text-n-800" : "text-n-600"
                }`}
        >
            {text}
        </label>
    );
    const getCheckLabel = (
        text: string | React.ReactNode,
        active: boolean,
        onPress?: any,
    ) => (
        <label
            onClick={onPress}
            className={`font-semibold text-base ml-1 ${active ? "text-t-500" : "text-n-500"
                }`}
            style={{
                cursor: "pointer",
            }}
        >
            {text}
        </label>
    );

    const intl = useIntl();

    const checkManage = (establishementId: string, value: string) => {
        const newVals = [];
        let index = -1;
        manage.forEach((establishement, i) => {
            if (establishement.site === establishementId) {
                index = i;
            } else {
                newVals.push(establishement);
            }
        });
        if (index === -1) {
            newVals.push({
                site: establishementId,
                roles: [value],
            });
        } else {
            let roles = [...manage[index].roles];
            let roleIndex = roles.indexOf(value);
            if (roleIndex !== -1) {
                roles.splice(roleIndex, 1);
            } else {
                roles.push(value);
            }
            newVals.push({
                site: establishementId,
                roles: roles,
            });
        }
        onChange("manage", newVals);
    };

    const isEstablishementRolePicked = (
        establishementId: string,
        value: string,
    ) => {
        let checked = false;
        manage.forEach((establishement) => {
            if (establishement.site === establishementId) {
                checked = establishement.roles.includes(value);
            }
        });
        return checked;
    };
    React.useEffect(() => {
        if (pickerType === "create") {
            let etablishmentList = [];

            switch (role) {
                case "Administrator":
                    etablishmentList = establishements.map(
                        (etab: any) => etab._id,
                    );
                    onChange("planning", []);
                    break;
                case "Employee":
                case "Manager":
                    if (establishements && establishements?.length === 1) {
                        etablishmentList = [establishements[0]._id];
                    }
                    break;
                default:
                    break;
            }
            if (etablishmentList?.length > 0) {
                onChange("belongsTo", etablishmentList);
            }
        }
    }, [role]);
    React.useEffect(() => {
        if (
            pickerType === "create" &&
            (role === "Employee" || role === "Manager")
        ) {
            onChange("planning", belongsTo);
        }
    }, [belongsTo, role]);

    const ManageAccessSites = (props: any) => {
        let { establishements } = props;
        const [selectedDept, setSelectedDept] = React.useState<any>([]);
        establishements = establishements?.map((etab: any) => {
            const depts = departements.find(
                (dept: any) => dept.site === etab._id,
            );
            if (depts) {
                return {
                    ...etab,
                    departments: depts.depts,
                };
            } else {
                return {
                    ...etab,
                    departments: [],
                };
            }
        });
        const selectedDepartments = (establishement: any) => {
            const selectedSite = selectedDepartment?.find(
                (item: any) => item.site === establishement._id,
            );
            if (!selectedSite) {
                return [];
            }
            const existSiteDepts = departements?.find(
                (item: any) => item.site === establishement._id,
            );
            if (!existSiteDepts) {
                return [];
            }
            const existedSelectedDepts = selectedSite.depts;
            const existedDepts = existSiteDepts.depts;

            const values = existedDepts
                .map((existDept: any) => {
                    if (existedSelectedDepts.includes(existDept._id)) {
                        return {
                            label: existDept.name,
                            value: existDept._id,
                        };
                    }
                })
                .filter((item: any) => item);
            if (!values) {
                return [];
            }
            // console.log("values", values);
            return values;
        };
        if (role === "Administrator") {
            return establishements.map((establishement: any, key: number) => (
                <div
                    key={key}
                    style={{
                        borderWidth: "1px",
                        borderRadius: "0.5rem",
                        marginTop: "0.75rem",
                        marginBottom: "0.75rem",
                    }}
                    className={`${hasError
                        ? "border-red-500"
                        : belongsTo.includes(establishement._id)
                            ? "border-t-100"
                            : "border-n-0"
                        } `}
                >
                    <div
                        key={key}
                        style={{
                            display: "flex",
                            padding: "1rem",
                            gap: "1.25rem",
                            flexDirection: "column",
                        }}
                    // className={`${direction === "row" ? "" : "flex-col"}`}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                            className={`${direction === "row" ? "min-w-[100px]" : "w-full"
                                } `}
                        >
                            <ZToggleButton
                                leftComponent={getTogglLabel(
                                    establishement.name,
                                    belongsTo.includes(establishement._id),
                                )}
                                isChecked={belongsTo.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    let newVals = [...belongsTo];
                                    if (
                                        belongsTo.indexOf(
                                            establishement._id,
                                        ) !== -1
                                    ) {
                                        newVals.splice(
                                            belongsTo.indexOf(
                                                establishement._id,
                                            ),
                                            1,
                                        );
                                    } else {
                                        newVals.push(establishement._id);
                                    }
                                    onChange("belongsTo", newVals);
                                }}
                            />
                        </div>
                        <div
                            className="flex items-center"
                            style={{
                                gap: "2px",
                            }}
                        >
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            {getCheckLabel(
                                intl.formatMessage({
                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                }),
                                planning.includes(establishement._id),
                                () => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                },
                            )}
                        </div>
                        {multiplePlanning &&
                            establishement?.departments?.length > 0 && (
                                <div
                                    className={`${direction === "row"
                                        ? "ml-auto"
                                        : "hidden"
                                        }`}
                                    style={{
                                        width: "max-content",
                                    }}
                                >
                                    <SelectInput
                                        title="Départements"
                                        menuPlacement="top"
                                        options={establishement?.departments?.map(
                                            (dept: any) => ({
                                                label: dept.name,
                                                value: dept._id,
                                            }),
                                        )}
                                        required={true}
                                        isDisabled={
                                            !belongsTo.includes(
                                                establishement._id,
                                            )
                                        }
                                        isMultiple={true}
                                        selectedValue={selectedDepartments(
                                            establishement,
                                        )}
                                        onSelectOption={async (
                                            department: any,
                                        ) => {
                                            let updatedDept: any[] = [];
                                            if (selectedDepartment) {
                                                updatedDept = [
                                                    ...selectedDepartment?.filter(
                                                        (dept: any) =>
                                                            dept.site !==
                                                            establishement._id,
                                                    ),
                                                ];
                                            }
                                            updatedDept = [
                                                ...updatedDept,
                                                {
                                                    site: establishement._id,
                                                    depts: department.map(
                                                        (dept: any) =>
                                                            dept.value,
                                                    ),
                                                },
                                            ].filter(
                                                (dept: any) =>
                                                    dept.depts?.length > 0,
                                            );
                                            // await setSelectedDept(updatedDept);

                                            onChange(
                                                "departements",
                                                updatedDept,
                                            );
                                        }}
                                        placeholder={intl.formatMessage({
                                            id: "MENU.DEPARTEMENTS",
                                        })}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            ));
        } else if (role === "Manager") {
            return establishements.map((establishement: any, key: number) => (
                <div
                    key={key}
                    style={{
                        borderWidth: "1px",
                        borderRadius: "0.5rem",
                        marginTop: "0.75rem",
                        marginBottom: "0.75rem",
                    }}
                    className={`${hasError
                        ? "border-red-500"
                        : belongsTo.includes(establishement._id)
                            ? "border-t-100"
                            : "border-n-0"
                        } `}
                >
                    <div
                        key={key}
                        style={{
                            display: "flex",
                            padding: "1rem",
                            gap: "1.25rem",
                            flexDirection: "column",
                        }}
                    // className={`${direction === "row" ? "" : "flex-col"}`}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                            className={`${direction === "row" ? "min-w-[100px]" : "w-full"
                                } `}
                        >
                            <ZToggleButton
                                leftComponent={getTogglLabel(
                                    establishement.name,
                                    belongsTo.includes(establishement._id),
                                )}
                                isChecked={belongsTo.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    let newVals = [...belongsTo];
                                    if (
                                        belongsTo.indexOf(
                                            establishement._id,
                                        ) !== -1
                                    ) {
                                        newVals.splice(
                                            belongsTo.indexOf(
                                                establishement._id,
                                            ),
                                            1,
                                        );
                                    } else {
                                        newVals.push(establishement._id);
                                    }
                                    onChange("belongsTo", newVals);
                                }}
                            />
                        </div>
                        {multiplePlanning &&
                            establishement?.departments?.length > 0 && (
                                <div
                                    className={`${direction === "row"
                                        ? "ml-auto"
                                        : "hidden"
                                        }`}
                                >
                                    <SelectInput
                                        style={{
                                            padding: "0",
                                            height: "40px",
                                        }}
                                        isDisabled={
                                            !belongsTo.includes(
                                                establishement._id,
                                            )
                                        }
                                        required={true}
                                        menuPlacement="top"
                                        isMultiple={true}
                                        title="Départements"
                                        options={establishement?.departments?.map(
                                            (dept: any) => ({
                                                label: dept.name,
                                                value: dept._id,
                                            }),
                                        )}
                                        selectedValue={selectedDepartments(
                                            establishement,
                                        )}
                                        onSelectOption={async (
                                            department: any,
                                        ) => {
                                            let updatedDept: any[] = [];
                                            if (selectedDepartment) {
                                                updatedDept = [
                                                    ...selectedDepartment?.filter(
                                                        (dept: any) =>
                                                            dept.site !==
                                                            establishement._id,
                                                    ),
                                                ];
                                            }
                                            updatedDept = [
                                                ...updatedDept,
                                                {
                                                    site: establishement._id,
                                                    depts: department.map(
                                                        (dept: any) =>
                                                            dept.value,
                                                    ),
                                                },
                                            ].filter(
                                                (dept: any) =>
                                                    dept.depts?.length > 0,
                                            );
                                            // await setSelectedDept(updatedDept);
                                            console.log(
                                                "selected Value",
                                                updatedDept,
                                            );
                                            onChange(
                                                "departements",
                                                updatedDept,
                                            );
                                        }}
                                        // onChange={() => {}}
                                        placeholder={intl.formatMessage({
                                            id: "MENU.DEPARTEMENTS",
                                        })}
                                    />
                                </div>
                            )}
                    </div>

                    {/** this part is for access management for Manager Role */}

                    <div
                        className={`py-2 mx-4 border-t ${belongsTo.includes(establishement._id)
                            ? "border-t-100"
                            : "border-n-0"
                            }  flex   flex-wrap ${direction === "col"
                                ? "flex-col"
                                : "justify-between items-center"
                            }`}
                    >
                        <div className="flex items-center">
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            {getCheckLabel(
                                intl.formatMessage({
                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                }),
                                planning.includes(establishement._id),
                                () => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                },
                            )}
                        </div>
                        {managePages.map((page, key) => (
                            <div
                                key={key}
                                className={`flex items-center flex-wrap ${direction === "row" && "justify-between"
                                    }`}
                            >
                                <ZCheckBox
                                    isChecked={isEstablishementRolePicked(
                                        establishement._id,
                                        page.value,
                                    )}
                                    onPress={() => {
                                        if (
                                            belongsTo.includes(
                                                establishement._id,
                                            )
                                        ) {
                                            checkManage(
                                                establishement._id,
                                                page.value,
                                            );
                                        }
                                    }}
                                />
                                {getCheckLabel(page.label, false, () => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        checkManage(
                                            establishement._id,
                                            page.value,
                                        );
                                    }
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            ));
        } else if (role === "Employee") {
            return establishements.map((establishement: any, key: number) => (
                <div
                    key={key}
                    style={{
                        borderWidth: "1px",
                        borderRadius: "0.5rem",
                        marginTop: "0.75rem",
                        marginBottom: "0.75rem",
                    }}
                    className={`${hasError
                        ? "border-red-500"
                        : belongsTo.includes(establishement._id)
                            ? "border-t-100"
                            : "border-n-0"
                        } `}
                >
                    <div
                        key={key}
                        style={{
                            display: "flex",
                            padding: "1rem",
                            gap: "1.25rem",
                            flexDirection: "column",
                        }}
                    // className={`${direction === "row" ? "" : "flex-col"}`}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                            className={`${direction === "row" ? "min-w-[100px]" : "w-full"
                                } `}
                        >
                            <ZToggleButton
                                leftComponent={getTogglLabel(
                                    establishement.name,
                                    belongsTo.includes(establishement._id),
                                )}
                                isChecked={belongsTo.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    let newVals = [...belongsTo];
                                    if (
                                        belongsTo.indexOf(
                                            establishement._id,
                                        ) !== -1
                                    ) {
                                        newVals.splice(
                                            belongsTo.indexOf(
                                                establishement._id,
                                            ),
                                            1,
                                        );
                                    } else {
                                        newVals.push(establishement._id);
                                    }
                                    onChange("belongsTo", newVals);
                                }}
                            />
                        </div>

                        <div className="flex items-center">
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            {getCheckLabel(
                                intl.formatMessage({
                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                }),
                                planning.includes(establishement._id),
                                () => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                },
                            )}
                        </div>
                        {multiplePlanning &&
                            establishement?.departments?.length > 0 && (
                                <div
                                    className={`${direction === "row"
                                        ? "ml-auto"
                                        : "hidden"
                                        }`}
                                >
                                    <SelectInput
                                        title="Départements"
                                        menuPlacement="top"
                                        options={establishement?.departments?.map(
                                            (dept: any) => ({
                                                label: dept.name,
                                                value: dept._id,
                                            }),
                                        )}
                                        required={true}
                                        isDisabled={
                                            !belongsTo.includes(
                                                establishement._id,
                                            )
                                        }
                                        isMultiple={true}
                                        selectedValue={selectedDepartments(
                                            establishement,
                                        )}
                                        onSelectOption={async (
                                            department: any,
                                        ) => {
                                            let updatedDept: any[] = [];
                                            if (selectedDepartment) {
                                                updatedDept = [
                                                    ...selectedDepartment?.filter(
                                                        (dept: any) =>
                                                            dept.site !==
                                                            establishement._id,
                                                    ),
                                                ];
                                            }
                                            updatedDept = [
                                                ...updatedDept,
                                                {
                                                    site: establishement._id,
                                                    depts: department.map(
                                                        (dept: any) =>
                                                            dept.value,
                                                    ),
                                                },
                                            ].filter(
                                                (dept: any) =>
                                                    dept.depts?.length > 0,
                                            );
                                            // await setSelectedDept(updatedDept);

                                            onChange(
                                                "departements",
                                                updatedDept,
                                            );
                                        }}
                                        placeholder={intl.formatMessage({
                                            id: "MENU.DEPARTEMENTS",
                                        })}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            ));
        }

        return establishements.map((establishement: any, key: number) => (
            <div
                key={key}
                style={{
                    borderWidth: "1px",
                    borderRadius: "0.5rem",
                    marginTop: "0.75rem",
                    marginBottom: "0.75rem",
                }}
                className={`${belongsTo.includes(establishement._id)
                    ? "border-t-100"
                    : hasError
                        ? "border-red-500"
                        : "border-n-0"
                    } `}
            >
                <div
                    key={key}
                    style={{
                        display: "flex",
                        padding: "1rem",
                        gap: "1.25rem",
                        flexDirection: "column",
                    }}
                // className={`${direction === "row" ? "" : "flex-col"}`}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                        }}
                        className={`${direction === "row" ? "min-w-[100px]" : "w-full"
                            } `}
                    >
                        <ZToggleButton
                            leftComponent={getTogglLabel(
                                establishement.name,
                                belongsTo.includes(establishement._id),
                            )}
                            isChecked={belongsTo.includes(establishement._id)}
                            onPress={() => {
                                let newVals = [...belongsTo];
                                if (
                                    belongsTo.indexOf(establishement._id) !== -1
                                ) {
                                    newVals.splice(
                                        belongsTo.indexOf(establishement._id),
                                        1,
                                    );
                                } else {
                                    newVals.push(establishement._id);
                                }
                                onChange("belongsTo", newVals);
                            }}
                        />
                    </div>
                    {role !== "Manager" && (
                        <div className="flex items-center">
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            {getCheckLabel(
                                intl.formatMessage({
                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                }),
                                planning.includes(establishement._id),
                                () => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                },
                            )}
                        </div>
                    )}
                    {role !== "Administrator" &&
                        multiplePlanning &&
                        departements?.length > 0 && (
                            <div
                                className={`${direction === "row" ? "ml-auto" : "hidden"
                                    }`}
                            >
                                <SelectInput
                                    title=""
                                    menuPlacement="top"
                                    options={departements}
                                    onSelectOption={() => { }}
                                    placeholder={intl.formatMessage({
                                        id: "MENU.DEPARTEMENTS",
                                    })}
                                />
                            </div>
                        )}
                </div>

                {/** this part is for access management for Manager Role */}
                {role === "Manager" && (
                    <div
                        className={`py-2 mx-4 border-t ${belongsTo.includes(establishement._id)
                            ? "border-t-100"
                            : "border-n-0"
                            }  flex   flex-wrap ${direction === "col"
                                ? "flex-col"
                                : "justify-between items-center"
                            }`}
                    >
                        <div className="flex items-center">
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            {getCheckLabel(
                                intl.formatMessage({
                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                }),
                                planning.includes(establishement._id),
                                () => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                },
                            )}
                        </div>
                        {managePages.map((page, key) => (
                            <div
                                key={key}
                                className={`flex items-center flex-wrap ${direction === "row" && "justify-between"
                                    }`}
                            >
                                <ZCheckBox
                                    isChecked={isEstablishementRolePicked(
                                        establishement._id,
                                        page.value,
                                    )}
                                    onPress={() => {
                                        if (
                                            belongsTo.includes(
                                                establishement._id,
                                            )
                                        ) {
                                            checkManage(
                                                establishement._id,
                                                page.value,
                                            );
                                        }
                                    }}
                                />
                                {getCheckLabel(page.label, false, () => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        checkManage(
                                            establishement._id,
                                            page.value,
                                        );
                                    }
                                })}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ));
    };

    return (
        <div>
            <span
                className={`inline-block  mb-5 font-bold text-lg ${direction === "row" ? "text-v-800" : "text-n-700"
                    }`}
            >
                {title}
            </span>
            <div
                className={`${direction === "row" ? "" : "flex gap-4"}`}
            // style={{ overflow: "auto" }}
            >
                <ManageAccessSites establishements={branchsLabeled} />
            </div>
            {hasError && (
                <span className="text-error text-sm font-light w-full max-w-[20px]">
                    {error}
                </span>
            )}
        </div>
    );
}

interface EstablishementPickerInterface {
    pickerType?: "create" | "edit";
    direction?: "row" | "col";
    title: string;
    establishements: { name: string; _id: string }[];
    onChange: (field: string, value: any) => void;
    manage: any[];
    belongsTo: any[];
    planning: any[];
    selectedDepartment?: any[];
    role: "Employee" | "Manager" | "Administrator";
    departements: any[];
    multiplePlanning: boolean;
    error?: string;
    hasError?: boolean;
}

EstablishementPickerPopup.defaultValues = {
    pickerType: "edit",
    error: "",
    hasError: false,
};

export default EstablishementPickerPopup;
