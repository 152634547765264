import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEmployees } from "../../../hooks/useEmployees";
import { useSites } from "../../../hooks/useSites";
import { useCountries } from "../../../hooks/useCountries";
import EmployeesEdit from "../../../components/templates/EmployeesEdit";
import ProfileHeaderPage from "./ProfileHeaderPage";
import HRFolderPage from "./HRFolderPage";
import GeneralInfoPage from "./GeneralInfoPage";
import UnavailabilityPage from "./UnavailabilityPage";
import ChangePassword from "./ChangePasswordPage";
import PointeusePage from "./PointeusePage";
import RestoreEmployeeDialog from "../RestoreEmployeeDialog";
import ArchiveEmployeeDialog from "../ArchiveEmployeeDialog";
import { useBranch } from "../../../hooks/useBranch";
import { ReactComponent as LeftArrowSVG } from "./left-arrow.svg";

export default function EmployeesEditPage() {
    const params = useParams();
    const sitesActions = useSites();
    const countriesActions = useCountries();
    const [employeeRestore, setEmployeeRestore] = React.useState<any>(null);
    const [employeeDelete, setEmployeeDelete] = React.useState<any>(null);
    const navigate = useNavigate();
    const {
        getEmployee,
        employeeForEdit,
        getEmployeeInfos,
        getDisponibilities,
        getPrimesTypes,
    } = useEmployees();

    const { selectedBranchSettings } = useBranch();
    React.useEffect(() => {
        countriesActions.getCountries();
        if (!selectedBranchSettings?._id) {
            // navigate("/employees");
        } else {
            sitesActions.getSitesManagement();
            getPrimesTypes({ siteId: selectedBranchSettings?._id });
        }
    }, [selectedBranchSettings]);

    const { id } = params;

    React.useEffect(() => {
        if (id) {
            getEmployee({ id });
            getEmployeeInfos({ id });
            getDisponibilities({ id });
        }
        return () => {
            getEmployee({ id: undefined });
            getEmployeeInfos({ id: undefined });
            getDisponibilities({ id: undefined });
        };
    }, [id]);
    const bodyRef = React.useRef<HTMLDivElement>(null); // Change here

    const setFullHeight = () => {
        if (bodyRef.current) {
            bodyRef.current.style.height = `calc(${window.innerHeight}px - 90px)`;
        }
    };

    React.useEffect(() => {
        // Set full height on initial load
        setFullHeight();

        // Add event listener for window resize
        window.addEventListener("resize", setFullHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", setFullHeight);
        };
    }, []);
    return (
        <>
            {employeeRestore && (
                <RestoreEmployeeDialog
                    employee={employeeRestore}
                    show={employeeRestore ? true : false}
                    onHide={() => {
                        setEmployeeRestore(null);
                    }}
                />
            )}
            {employeeDelete && (
                <ArchiveEmployeeDialog
                    employee={employeeDelete}
                    show={employeeDelete ? true : false}
                    onHide={() => {
                        setEmployeeDelete(null);
                    }}
                />
            )}
            <div
                // className="container"
                ref={bodyRef}
                style={{
                    paddingTop: "10px",
                    width: "100%",
                    overflow: "auto",
                    // height: "calc(100% - 50px)",
                }}
            >
                {employeeForEdit && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "fit-content",
                                color: "#2A8BAB",
                                fontSize: "14px",
                                fontWeight: "500",
                                cursor: "pointer",
                                marginBottom: "10px",
                            }}
                            onClick={() => navigate("/employees")}
                        >
                            <LeftArrowSVG color="#5BB3D0" />
                            Retour
                        </div>
                        <EmployeesEdit
                            ProfileHeader={
                                <ProfileHeaderPage
                                    archiveAction={(emp: any) =>
                                        setEmployeeDelete(emp)
                                    }
                                    restoreAction={(emp: any) =>
                                        setEmployeeRestore(emp)
                                    }
                                />
                            }
                            HRFolder={<HRFolderPage />}
                            GeneralInfo={<GeneralInfoPage />}
                            Unavailability={<UnavailabilityPage />}
                            Pointeuse={<PointeusePage />}
                            ChangePassword={<ChangePassword />}
                        />
                    </>
                )}
            </div>
        </>
    );
}
