import axios, { AxiosError } from "axios";
import Swal from "sweetalert2";

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    FetchEmployeesRequest,
    CreateEployeeRequest,
    FetchEmployeeRequest,
    FetchEmployeeInfosRequest,
    FetchEmployeeDisponibilitiesRequest,
    FetchRegisterRequest,
    RestoreEmployeeRequest,
    ArchiveEmployeeRequest,
    UpdateEployeeRequest,
    DeleteUnavaibilityRequest,
    FetchEmployeeDocuments,
    AddEmployeeDocument,
    DeleteEmployeeDocument,
    FetchEmployeePrimes,
    CreateEmployeePrimes,
    UpdateEmployeePrimes,
    DeleteEmployeePrimes,
    FetchPrimesTypes,
    FetchContractsRequest,
    FetchEmployeeWorkHoursRequest,
    FetchEmployeeHolidaysRequest,
    UpdateEmployeePasswordRequest,
} from "./types";

import {
    newfetchEmployeesUrl,
    createEmployeeUrl,
    fetchEmployeeUrl,
    fetchEmployeeInfosUrl,
    fetchEmployeeDisponibilitiesUrl,
    createEmployeeDisponibilitiesUrl,
    updateEmployeeDisponibilitiesUrl,
    fetchRegisterUrl,
    restoreEmployeeUrl,
    archiveEmployeeUrl,
    updateEmployeeUrl,
    updateEmployeeInfosUrl,
    createContractUrl,
    updateContractUrl,
    removeUnavaibilityUrl,
    fetchEmployeeDocumentsUrl,
    addEmployeeDocumentUrl,
    deleteEmployeeDocumentUrl,
    fetchEmployeePrimesUrl,
    updateEmployeePrimesUrl,
    createEmployeePrimesUrl,
    deleteEmployeePrimesUrl,
    listPrimestypesUrl,
    fetchContractsUrl,
    fetchShiftsListUrl,
    fetchEmployeeHolidaysUrl,
    updateEmployeePasswordUrl,
} from "../api.routes";

import { asyncFileReader } from "../../../_helpers/FileHelpers";
import { API } from "../../../constants/env";

export const fetchEmployees = createAsyncThunk(
    "employees/fetchEmployees",
    async (payload: FetchEmployeesRequest, { rejectWithValue }) => {
        const { archived } = payload;
        // const selectedBranch = sessionStorage.getItem("selectedBranch");
        // let licenseId: any = undefined;
        // if (selectedBranch) {
        //     licenseId = JSON.parse(selectedBranch).license;
        // }
        try {
            const res = await axios.post(
                newfetchEmployeesUrl,
                {
                    archived,
                    // licenseId,
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            return {
                archived,
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error)
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
        }
    },
);

export const createEmployee = createAsyncThunk(
    "employees/createEmployee",
    async (payload: CreateEployeeRequest, { rejectWithValue }) => {
        const data: any = {
            fname: payload.fname,
            lname: payload.lname,
            email: payload.email,
            phone: payload.phone,
            pin: payload.pin,
            tempsTravail: payload.tempsDeTravailHebdomadaire
                ? parseFloat(payload.tempsDeTravailHebdomadaire)
                : 0,
            tempsTravailMonthly: payload.tempsDeTravailMensuel
                ? parseFloat(payload.tempsDeTravailMensuel)
                : 0,
            role: payload.role,
            belongsTo: payload.belongsTo,
            planning: payload.planning,
            manage: payload.manage,
            departements: payload.departements,
        };
        if (payload.file) {
            data.croppedImage = await asyncFileReader(payload.file);
        }
        try {
            const res = await axios.post(createEmployeeUrl, data, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const updateEmployee = createAsyncThunk(
    "employees/updateEmployee",
    async (payload: UpdateEployeeRequest, { rejectWithValue }) => {
        const data: any = {
            userId: payload.userId,
            fname: payload.fname,
            lname: payload.lname,
            email: payload.email,
            phone: payload.phone,
            pin: payload.pin,
            userMatricule: payload.userMatricule,
            tempsTravail: payload.tempsDeTravailHebdomadaire,
            tempsTravailMonthly: payload.tempsDeTravailMensuel,
            role: payload.role,
            belongsTo: payload.belongsTo,
            planning: payload.planning,
            manage: payload.manage,
            niveau: payload.niveau,
        };
        if (payload.picture) {
            if (typeof payload.picture === "string") {
                data.picture = payload.picture;
            } else {
                const fileBase64 = await asyncFileReader(payload.picture);
                data.croppedImage = fileBase64;
            }
        }

        try {
            const res = await axios.post(updateEmployeeUrl, data, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            return {
                data: res.data,
                status: "success",
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchEmployee = createAsyncThunk(
    "employees/fetchEmployee",
    async (payload: FetchEmployeeRequest, { rejectWithValue }) => {
        const { id } = payload;
        try {
            if (id) {
                const res = await axios.get(`${fetchEmployeeUrl}/${id}`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                });

                return res.data;
            } else {
                return undefined;
            }
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchEmployeeInfos = createAsyncThunk(
    "employees/fetchEmployeeInfos",
    async (payload: FetchEmployeeInfosRequest, { rejectWithValue }) => {
        const { id } = payload;
        try {
            if (id) {
                const res = await axios.get(`${fetchEmployeeInfosUrl}/${id}`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                });
                const infos = res.data;
                return {
                    infos,
                };
            } else {
                return {
                    infos: undefined,
                };
            }
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchEmployeeDisponibilities = createAsyncThunk(
    "employees/fetchEmployeeDisponibilities",
    async (
        payload: FetchEmployeeDisponibilitiesRequest,
        { rejectWithValue },
    ) => {
        const { id } = payload;
        try {
            if (id) {
                const res = await axios.get(
                    `${fetchEmployeeDisponibilitiesUrl}/${id}`,
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                const disponibilities = res.data;
                return {
                    disponibilities,
                };
            } else {
                return {
                    disponibilities: undefined,
                };
            }
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const createUserUnavaibility = createAsyncThunk(
    "employees/createUserUnavaibility",
    async (payload: any, { rejectWithValue }) => {
        try {
            const res = await axios.post(
                createEmployeeDisponibilitiesUrl,
                payload,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const updateUserUnavaibility = createAsyncThunk(
    "employees/updateUserUnavaibility",
    async (payload: any, { rejectWithValue }) => {
        try {
            const res = await axios.post(
                `${updateEmployeeDisponibilitiesUrl}/${payload._id}`,
                payload,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchRegister = createAsyncThunk(
    "employees/fetchRegister",
    async (payload: FetchRegisterRequest, { rejectWithValue }) => {
        try {
            const { id } = payload;
            const res: any = await axios.get(`${fetchRegisterUrl}/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return {
                register: res.data.sort(
                    (a: any, b: any) =>
                        new Date(b.created_at).getTime() -
                        new Date(a.created_at).getTime(),
                ),
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const restoreEmp = createAsyncThunk(
    "employees/restoreEmployee",
    async (payload: RestoreEmployeeRequest, { rejectWithValue }) => {
        try {
            const { id } = payload;
            const res: any = await axios.post(
                `${restoreEmployeeUrl}`,
                { userId: id },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const archiveEmp = createAsyncThunk(
    "employees/archiveEmployee",
    async (payload: ArchiveEmployeeRequest, { rejectWithValue }) => {
        try {
            const { id } = payload;
            const res: any = await axios.post(
                `${archiveEmployeeUrl}`,
                { userId: id },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const updateEmployeeInfos = createAsyncThunk(
    "employees/updateEmployeeInfos",
    async (payload: any, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(updateEmployeeInfosUrl, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchContracts = createAsyncThunk(
    "employees/fetchContracts",
    async (payload: FetchContractsRequest, { rejectWithValue }) => {
        try {
            const res: any = await axios.get(
                `${fetchContractsUrl}/${payload.userId}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const updateContract = createAsyncThunk(
    "employees/updateContract",
    async (payload: any, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(updateContractUrl, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const createContract = createAsyncThunk(
    "employees/createContract",
    async (payload: any, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(createContractUrl, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const removeUnavaibility = createAsyncThunk(
    "employees/removeUnavaibility",
    async (payload: DeleteUnavaibilityRequest, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(
                `${removeUnavaibilityUrl}/${payload.id}`,
                {},
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchEmployeeDocuments = createAsyncThunk(
    "employees/fetchEmployeeDocuments",
    async (payload: FetchEmployeeDocuments, { rejectWithValue }) => {
        try {
            const res: any = await axios.get(
                `${fetchEmployeeDocumentsUrl}/${payload.id}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const addEmployeeDocument = createAsyncThunk(
    "employees/addEmployeeDocument",
    async (payload: AddEmployeeDocument, { rejectWithValue }) => {
        try {
            const data = new FormData();
            data.append("employeeId", payload.employeeId);
            data.append("file", payload.file);
            const res: any = await axios.post(
                `${addEmployeeDocumentUrl}`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const deleteEmployeeDocument = createAsyncThunk(
    "employees/deleteEmployeeDocument",
    async (payload: DeleteEmployeeDocument, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(
                `${deleteEmployeeDocumentUrl}`,
                payload,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchEmployeePrimes = createAsyncThunk(
    "employees/fetchEmployeePrimes",
    async (payload: FetchEmployeePrimes, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(
                `${fetchEmployeePrimesUrl}`,
                payload,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const createEmployeePrimes = createAsyncThunk(
    "employees/createEmployeePrimes",
    async (payload: CreateEmployeePrimes, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(
                `${createEmployeePrimesUrl}`,
                payload,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const updateEmployeePrimes = createAsyncThunk(
    "employees/updateEmployeePrimes",
    async (payload: UpdateEmployeePrimes, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(
                `${updateEmployeePrimesUrl}`,
                payload,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const deleteEmployeePrimes = createAsyncThunk(
    "employees/deleteEmployeePrimes",
    async (payload: DeleteEmployeePrimes, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(
                `${deleteEmployeePrimesUrl}`,
                payload,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchPrimesTypes = createAsyncThunk(
    "employees/fetchPrimesTypes",
    async (payload: FetchPrimesTypes, { rejectWithValue }) => {
        try {
            const res: any = await axios.get(
                `${listPrimestypesUrl}/${payload.siteId}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchEmployeeWorkHours = createAsyncThunk(
    "employees/fetchWorkHours",
    async (payload: FetchEmployeeWorkHoursRequest, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(
                `${fetchShiftsListUrl}/${payload.employeeId}`,
                payload,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const fetchEmployeeHolidays = createAsyncThunk(
    "employees/fetchEmployeeHolidays",
    async (payload: FetchEmployeeHolidaysRequest, { rejectWithValue }) => {
        try {
            const res: any = await axios.get(
                `${fetchEmployeeHolidaysUrl}?userId=${
                    payload.employeeId
                }&year=${payload.year ? payload.year : ""}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            console.log("data: ", res.data);
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

export const updateEmployeePassword = createAsyncThunk(
    "employees/updateEmployeePassword",
    async (payload: UpdateEmployeePasswordRequest, { rejectWithValue }) => {
        try {
            const res: any = await axios.post(
                `${updateEmployeePasswordUrl}`,
                payload,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            return {
                status: "success",
                data: res.data,
            };
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue({
                    status: "fail",
                    data: error.response?.data,
                });
            }
        }
    },
);

// Employee Documents Services
export const uploadNewDocument = createAsyncThunk(
    "documents/uploadNewDocument",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        const { file, employeeId, fileName, folderId } = payload;
        if (token && payload.file && payload.employeeId && payload.fileName) {
            try {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("employeeId", employeeId);
                formData.append("fileName", fileName);
                formData.append("folderId", folderId);
                const res = await axios.post(
                    API.client.employees.addEmployeeDocumentUrl,
                    formData,
                    {
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                    },
                );

                return res;
            } catch (err) {
                Swal.fire({
                    icon: "error",
                    title: "Erreur de serveur",
                    text: "Une erreur inattendue s'est produite.",
                });
                return rejectWithValue(err);
            }
        }
    },
);

export const updateExistingDocument = createAsyncThunk(
    "documents/updateExistingDocument",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        const { fileName, folderId, fileId } = payload;
        if (token && fileName && fileId) {
            try {
                const res = await axios.put(
                    API.client.employees.updateEmployeeDocument(fileId),
                    {
                        fileName,
                        folderId: folderId || null,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    },
                );
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const deleteExistingDocument = createAsyncThunk(
    "documents/deleteExistingDocument",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.fileId) {
            try {
                const res = await fetch(
                    API.client.employees.deleteEmployeeDocumentUrl,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            file: payload.fileId,
                        }),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const deleteFolder = createAsyncThunk(
    "documents/deleteFolder",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.folderId) {
            try {
                const res = await axios.delete(
                    API.client.employees.folderRestEndpoint,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        data: {
                            folderId: payload.folderId,
                            employeeId: payload.employeeId,
                        },
                    },
                );
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const createEmployeeFolder = createAsyncThunk(
    "documents/createEmployeeFolder",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const res = await axios.post(
                    API.client.employees.folderRestEndpoint,
                    payload,
                    {
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                    },
                );
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const editEmployeeFolder = createAsyncThunk(
    "documents/editEmployeeFolder",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.folderId) {
            try {
                const res = await axios.put(
                    API.client.employees.folderRestEndpoint,
                    payload,
                    {
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                    },
                );
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const downloadRegistre = createAsyncThunk(
    "employees/downloadRegistre",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        const { siteId } = payload;
        console.log(`Downloading`);
        console.log(`payload = `, payload);
        if (token && siteId) {
            console.log(`enter`);

            try {
                const res = await axios.get(
                    API.client.employees.downloadRegistre(siteId),
                    {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    },
                );
                return res.data;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
