import React from "react";
import PersonalInfo from "../../../components/templates/EmployeesEdit/PersonalInfo";
import { useEmployees } from "../../../hooks/useEmployees";
import { useCountries } from "../../../hooks/useCountries";
import { destructFields, generateFields } from "../../../_helpers/FormHelpers";
import { UpdateEployeeInfosRequest } from "../../../redux/features/employees/types";
import moment from "moment";
import { useIntl } from "react-intl";
import { useNotificationContext } from "../../../Context/Notification";
import { getCountriesOptions, genderOptions } from "../../../Options";
import Swal from "sweetalert2";

export default function PersonalInfoPage({ }: PersonalInfoPageInterface) {
    const { employeeInfos, actionLoading, editEmployeeInfos } = useEmployees();
    const intl = useIntl();
    const { countries } = useCountries();
    const { addNotification } = useNotificationContext();
    const [employee, setEmployee] = React.useState<Employee>();

    const getGenderOption = (value: any) => {
        if (value === "") return value;
        let index = genderOptions.findIndex((val) => val.value == value);
        if (index !== -1) {
            return genderOptions[index];
        } else {
            return "";
        }
    };

    const getCountryOption = (value: any) => {
        const countriesOptions = getCountriesOptions(countries);
        if (value === "") return value;
        let index = countriesOptions.findIndex((val) => val.value == value);
        if (index !== -1) {
            return countriesOptions[index];
        } else {
            return "";
        }
    };
    React.useEffect(() => {
        if (employeeInfos) {
            setEmployee(() => {
                return generateFields([
                    { name: "_id", value: employeeInfos._id },
                    { name: "adress", value: employeeInfos.adress },
                    { name: "birthday", value: employeeInfos.birthday },
                    { name: "city", value: employeeInfos.city },
                    { name: "cityOfBirth", value: employeeInfos.cityOfBirth },
                    {
                        name: "departmentOfBirth",
                        value: employeeInfos.departmentOfBirth,
                    },
                    {
                        name: "gender",
                        value: getGenderOption(employeeInfos.gender),
                    },
                    {
                        name: "nationality",
                        value: getCountryOption(employeeInfos.nationality),
                    },
                    { name: "placeOfBirth", value: employeeInfos.placeOfBirth },
                    { name: "socialNumber", value: employeeInfos.socialNumber },
                    { name: "zipCode", value: employeeInfos.zipCode },
                    { name: "nirKey", value: employeeInfos.nirKey },
                    { name: "nirID", value: employeeInfos.nirID },
                ]);
            });
        }
    }, [employeeInfos]);

    const onChange = (field: string, value: any) => {
        if (employee) {
            if (
                field === "departmentOfBirth" &&
                isNaN(parseInt(value)) &&
                String(value) !== ""
            ) {
                Swal.fire({
                    icon: "error",
                    title: "Oups...",
                    text: "La valeur n'est pas un nombre",
                });
            } else {
                const newState = {
                    ...employee,
                    [field]: {
                        ...employee[field],
                        value,
                    },
                };
                setEmployee(newState);
            }
        }
    };

    const onBlur = (field: string) => {
        if (employee) {
            const newState = {
                ...employee,
                [field]: {
                    ...employee[field],
                    touched: true,
                },
            };
            setEmployee(newState);
        }
    };

    const cancelAction = () => {
        setEmployee(() => {
            return generateFields([
                { name: "adress", value: employeeInfos.adress },
                { name: "birthday", value: employeeInfos.birthday },
                { name: "city", value: employeeInfos.city },
                { name: "cityOfBirth", value: employeeInfos.cityOfBirth },
                {
                    name: "departmentOfBirth",
                    value: employeeInfos.departmentOfBirth,
                },
                { name: "gender", value: employeeInfos.gender },
                { name: "nationality", value: employeeInfos.nationality },
                { name: "placeOfBirth", value: employeeInfos.placeOfBirth },
                { name: "socialNumber", value: employeeInfos.socialNumber },
                { name: "zipCode", value: employeeInfos.zipCode },
                { name: "_id", value: employeeInfos._id },
            ]);
        });
    };

    const onSubmit = () => {
        if (employee) {
            const values = destructFields(employee);
            if (
                String(values.socialNumber)?.length > 0 &&
                String(values.socialNumber)?.length < 13
            ) {
                Swal.fire({
                    icon: "error",
                    title: "Saisie incorrecte",
                    text: "La longueur du numéro de sécurité sociale ne peut pas être inférieure à 15 caractères",
                });
                return;
            }
            editEmployeeInfos({
                ...values,
                // birthday: values.birthday ? moment(values).toISOString() : null,
                gender:
                    values.gender && values.gender !== "string"
                        ? values.gender.value
                        : "",
                nationality:
                    values.nationality && values.nationality !== "string"
                        ? values.nationality.value
                        : "",
            } as UpdateEployeeInfosRequest).then((res) => {
                const data: any = res.payload;
                if (data.status === "success") {
                    addNotification({
                        title: intl.formatMessage({
                            id: "EMPLOYEE.INFOS.EDIT.NOTIFICATION.SUCCESS.TITLE",
                        }),
                        message: intl.formatMessage({
                            id: "EMPLOYEE.INFOS.EDIT.NOTIFICATION.SUCCESS.MESSAGE",
                        }),
                        type: "success",
                    });
                } else if (data.status === "fail") {
                    addNotification({
                        title: intl.formatMessage({
                            id: "EMPLOYEE.INFOS.EDIT.NOTIFICATION.FAIL.TITLE",
                        }),
                        message: intl.formatMessage({
                            id: "EMPLOYEE.INFOS.EDIT.NOTIFICATION.FAIL.MESSAGE",
                        }),
                        type: "fail",
                    });
                }
            });
        }
    };

    return (
        <>
            {employee && countries && (
                <PersonalInfo
                    onSubmit={onSubmit}
                    cancelAction={cancelAction}
                    countries={countries}
                    employee={employee}
                    onBlur={onBlur}
                    onChange={onChange}
                    actionLoading={actionLoading}
                />
            )}
        </>
    );
}

interface PersonalInfoPageInterface { }

interface Employee {
    [key: string]: any;
}
