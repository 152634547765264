import React from "react";
import "./style.css";
import TextData from "../../atoms/TextData";
import NotificationItem from "./NotificationItem";
import { ReactComponent as CheckSeenSVG } from "./check_seen.svg";
import {
    NotificationState,
    NotificationsState,
} from "../../../redux/features/notifications/types";
import { ROUTER } from "../../../constants/env";
import { Link } from "react-router-dom";
import NotificationsNoRecord from "../../templates/NotificationsNoRecord";
export default function NotificationCard(props: NotificationCardProps) {
    const {
        show,
        onHide,
        head,
        notifications,
        setNotificationsSeen,
        getNotifications,
    } = props;
    React.useEffect(() => {
        getNotifications();
    }, []);
    // let notifications = {
    //     old: [],
    //     today: [],
    //     unread: 0,
    // };

    return (
        <div className={`notifications-card ${show ? "" : "hidden"}`}>
            <div className="head" style={{ height: "auto" }}>
                <TextData color="white" size="18px" fontWeight="bold">
                    {head.title}
                </TextData>
                <Link to={ROUTER.SETTINGS.NOTIFICATIONS}>
                    <div className="settings-icon" onClick={() => onHide()}>
                        {head.Icon}
                    </div>
                </Link>
            </div>
            <div className="content">
                {notifications?.old?.length === 0 &&
                    notifications?.today?.length === 0 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                // height: "100px",
                                color: "red",
                            }}
                        >
                            <NotificationsNoRecord />
                        </div>
                    )}
                {(notifications.today?.length > 0 ||
                    notifications.old?.length > 0) && (
                        <TextData
                            fontWeight="bold"
                            size="12px"
                            color={`${notifications?.unread > 0 ? "#2A8BAB" : "#6F78AB"
                                }`}
                            style={{
                                position: "absolute",
                                right: "10px",
                                cursor: "pointer",
                                display: "flex",
                                gap: "3px",
                            }}
                            onClick={setNotificationsSeen}
                        >
                            <CheckSeenSVG />
                            Marquer comme lues
                        </TextData>
                    )}
                {notifications.today?.length > 0 && (
                    <div
                        // className="today"
                        style={{
                            borderBottom: `${notifications.old?.length > 0
                                    ? "1px solid rgb(209, 209, 209)"
                                    : ""
                                }`,
                            paddingBottom: `${notifications.old?.length > 0 ? "10px" : ""
                                }`,
                            marginBottom: `${notifications.old?.length > 0 ? "10px" : ""
                                }`,
                        }}
                    >
                        <div className="sub-head">
                            <TextData
                                fontWeight="bold"
                                size="12px"
                                color="#6F78AB"
                            >
                                Aujourd'hui
                            </TextData>
                        </div>

                        {notifications.today.map(
                            (
                                notification: NotificationState,
                                index: number,
                            ) => {
                                const {
                                    title,
                                    type,
                                    content,
                                    seen,
                                    created_at,
                                    clicked,
                                    _id,
                                } = notification;
                                return (
                                    <NotificationItem
                                        key={index}
                                        id={_id}
                                        title={title}
                                        type={type}
                                        content={content}
                                        seen={seen}
                                        created_at={created_at}
                                        clicked={clicked}
                                        isToday={true}
                                    />
                                );
                            },
                        )}
                    </div>
                )}
                <div className="old">
                    {notifications.old?.length > 0 && (
                        <div className="sub-head">
                            <TextData
                                fontWeight="bold"
                                size="12px"
                                color="#6F78AB"
                            >
                                Anciennes
                            </TextData>
                        </div>
                    )}
                    {notifications?.old.map(
                        (notification: NotificationState, index: number) => {
                            const {
                                title,
                                type,
                                content,
                                seen,
                                created_at,
                                clicked,
                                _id,
                            } = notification;
                            return (
                                <NotificationItem
                                    key={index}
                                    id={_id}
                                    title={title}
                                    type={type}
                                    content={content}
                                    seen={seen}
                                    created_at={created_at}
                                    clicked={clicked}
                                    isToday={false}
                                />
                            );
                        },
                    )}
                </div>
            </div>
            {(notifications?.old?.length > 0 ||
                notifications?.today?.length > 0) && (
                    <div className="notifications-card-foot">
                        <a href={ROUTER.NOTIFICATIONS}>Toutes les notifications</a>
                    </div>
                )}
        </div>
    );
}

interface NotificationCardProps {
    show: boolean;
    onHide: () => void;
    getNotifications: () => void;
    setNotificationsSeen: () => void;
    head: { Icon: React.ReactNode; title: string };
    notifications: any;
}
