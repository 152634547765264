import React from "react";
import Datatable from "../../../components/organism/Datatable";
import * as columnFormatters from "./column-formatters";
import CongesNoRecords from "../../../components/templates/CongesNoRecords";
import Loading from "../../../components/atoms/Loading";
import TextData from "../../../components/atoms/TextData";
import Table from "../../../components/organism/Table";
import moment from "moment";

const CongesTable = ({
    userHolidays,
    openVacationRequestDialog,
    updateEmployeeValue,
    openAddConge,
    holidayLoading,
    closeTraitement,
}: CongesTableProps) => {
    const loadHolidayData = () => {
        let res: any = [];
        if (userHolidays && userHolidays?.length > 0) {
            const sortedHolidays = [...userHolidays]?.sort((a: any, b: any) => {
                return (
                    moment(b?.created_at)?.valueOf() -
                    moment(a?.created_at)?.valueOf()
                );
            });
            res = sortedHolidays?.map((holidayItem: any, index: number) => {
                const { created_at, type } = holidayItem;
                return {
                    dataRow: [
                        {
                            name: "fname",
                            value: columnFormatters.NameColumnFormatter(
                                holidayItem,
                            ),
                            style: {
                                padding: "10px 0",
                                paddingLeft: "15px",
                                color: "#4c5690",
                                backgroundColor: `${index % 2 === 0 ? "#ffffff" : "#f9fafc"
                                    }`,
                            },
                        },
                        {
                            name: "created_at",
                            value: columnFormatters.DepositDateColumnFormatter(
                                created_at,
                            ),
                            style: {
                                padding: "10px 0",
                                textAlign: "left" as "left",
                                color: "#4C5690",
                                backgroundColor: `${index % 2 === 0 ? "#ffffff" : "#f9fafc"
                                    }`,
                            },
                        },
                        {
                            name: "updatedAt",
                            value: columnFormatters.VacationDateColumn(
                                holidayItem,
                            ),
                            style: {
                                padding: "10px 0",
                                textAlign: "left" as "left",
                                color: "#4C5690",
                                backgroundColor: `${index % 2 === 0 ? "#ffffff" : "#f9fafc"
                                    }`,
                            },
                        },
                        {
                            name: "Nombre des jours",
                            value: columnFormatters.DaysNumberColumn(
                                holidayItem,
                            ),
                            style: {
                                padding: "10px 0",
                                textAlign: "center" as "center",
                                color: "#4C5690",
                                backgroundColor: `${index % 2 === 0 ? "#ffffff" : "#f9fafc"
                                    }`,
                            },
                        },
                        {
                            name: "type",
                            value: columnFormatters.TypeColumnFormatter(type),
                            style: {
                                padding: "10px 0",
                                textAlign: "left" as "left",
                                color: "#4C5690",
                                backgroundColor: `${index % 2 === 0 ? "#ffffff" : "#f9fafc"
                                    }`,
                            },
                        },
                        {
                            name: "actions",
                            value: columnFormatters.ActionColumnFormatter(
                                holidayItem,
                                {
                                    openVacationRequestDialog,
                                    updateEmployeeValue,
                                    closeTraitement,
                                },
                            ),
                            style: {
                                padding: "10px 10px 10px 0 ",
                                backgroundColor: `${index % 2 === 0 ? "#ffffff" : "#f9fafc"
                                    }`,
                            },
                        },
                    ],
                };
            });
        }
        return res;
    };
    const HolidayTableProps = {
        fields: {
            fieldRow: [
                {
                    title: (
                        <TextData
                            color="#4c5690"
                            variant="label"
                            className={[
                                "cursor-pointer",
                                "font-medium",
                                "text-lg",
                            ]}
                        >
                            Nom et prénom
                        </TextData>
                    ),
                    name: "name",
                    variant: "label",
                    style: {
                        paddingLeft: "15px",
                        borderTop: "none",
                        width: "28%",
                    },
                    titleContainerStyle: {
                        display: "flex",
                        alignItems: "center",
                    },
                },
                {
                    title: (
                        <TextData
                            color="#4c5690"
                            variant="label"
                            className={[
                                "cursor-pointer",
                                "font-medium",
                                "text-lg",
                            ]}
                        >
                            Déposé le
                        </TextData>
                    ),
                    name: "name",
                    variant: "label",
                    style: {
                        borderTop: "none",
                        width: "10%",
                        padding: "10px 0",
                    },
                    titleContainerStyle: {
                        display: "flex",
                        alignItems: "left",
                    },
                },
                {
                    title: (
                        <TextData
                            color="#4c5690"
                            variant="label"
                            className={[
                                "cursor-pointer",
                                "font-medium",
                                "text-lg",
                            ]}
                        >
                            Date de l'absence
                        </TextData>
                    ),
                    name: "name",
                    variant: "label",
                    style: {
                        paddingLeft: "15px",
                        borderTop: "none",
                        width: "19%",
                        padding: "10px 0",
                    },
                    titleContainerStyle: {
                        display: "flex",
                        alignItems: "center",
                    },
                },
                {
                    title: (
                        <TextData
                            color="#4c5690"
                            variant="label"
                            className={[
                                "cursor-pointer",
                                "font-medium",
                                "text-lg",
                            ]}
                        >
                            Nombre des jours
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        padding: "10px 0",
                        width: "27%",
                        textAlign: "center" as "center",
                    },
                },
                {
                    title: (
                        <TextData
                            color="#4c5690"
                            variant="label"
                            className={[
                                "cursor-pointer",
                                "font-medium",
                                "text-lg",
                            ]}
                        >
                            Type de congé
                        </TextData>
                    ),
                    name: "name",
                    variant: "label",
                    style: {
                        paddingLeft: "15px",
                        borderTop: "none",
                        width: "19%",
                        padding: "10px 0",
                    },
                    titleContainerStyle: {
                        display: "flex",
                        alignItems: "center",
                    },
                },
                {
                    title: <></>,
                    name: "name",
                    variant: "label",
                    style: {
                        paddingRight: "15px",
                        borderTop: "none",
                        width: "100%",
                    },
                    titleContainerStyle: {
                        display: "flex",
                        alignItems: "center",
                    },
                },
            ],
            onClickRow: () => { },
            style: {
                border: "1px solid #EDEFF5",
                borderTop: "none",
                position: "sticky" as "sticky",
                top: 0,
                backgroundColor: "white",
            },
            className: [],
        },

        data: loadHolidayData(),
    };

    return (
        <>
            {holidayLoading ? (
                <>
                    <div style={{ height: 400 }}>
                        <Loading width="400px" height="200px" />
                    </div>
                </>
            ) : userHolidays?.length ? (
                <Table {...HolidayTableProps} />
            ) : (
                <CongesNoRecords openAddConge={openAddConge} />
            )}
        </>
    );
};

interface CongesTableProps {
    holidayLoading: boolean;
    userHolidays: any[];
    openVacationRequestDialog: () => void;
    updateEmployeeValue: (value: any) => void;
    openAddConge: () => void;
    closeTraitement?: () => void;
}

export default CongesTable;
