import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useEmployees } from "../../../hooks/useEmployees";
import RegisterTable from "./RegisterTable";
import RegisterFilter from "./RegisterFilter";
import ZAlert from "../../../components/atoms/ZAlert";
import Pagination from "../../../components/molecules/Pagination";
import { getRangeData } from "../../../_helpers/TableHelpers";
import { CircleInfos, AdressBook } from "../../../Icons";
import { Header, Footer, Body } from "../../../components/atoms/Container";
import { useBranch } from "../../../hooks/useBranch";
import PickEstablishement from "../../../components/molecules/PickEstablishement";
import { ROUTER } from "../../../constants/env";
const alertBody = () => {
    return (
        <div>
            <div className="mb-4">
                <h4 className="text-lg font-bold">
                    <FormattedMessage id="REGISTER.ALERT.TITLE" />
                </h4>
                <p className="text-base font-medium">
                    <FormattedMessage id="REGISTER.ALERT.PARAGRAPH" />
                </p>
            </div>
            <div className="mb-4">
                <h5 className="text-md font-bold">
                    <FormattedMessage id="REGISTER.ALERT.SUBTITLE" />
                </h5>
                <p className="text-base font-medium">
                    <FormattedMessage
                        id="REGISTER.ALERT.PARAGRAPH2"
                        values={{
                            ticketRange: (
                                <span className="font-semibold">
                                    <FormattedMessage
                                        id="FROM_TO_TEXT"
                                        values={{
                                            from: "1500 €",
                                            to: "3000 €",
                                        }}
                                    />
                                </span>
                            ),
                            inspectTicket: (
                                <span className="font-semibold">450 €</span>
                            ),
                        }}
                    />
                </p>
            </div>
            <div>
                <p className="text-base font-medium">
                    (<FormattedMessage id="REGISTER.ALERT.PARAGRAPH3" />)
                </p>
            </div>
        </div>
    );
};

export default function RegisterPage() {
    const {
        getRegister,
        register,
        filterBy,
        onUpdateFilterBy,
        handleDownloadRegistre,
    } = useEmployees();
    const { selectedBranchSettings } = useBranch();
    const intl = useIntl();
    React.useEffect(() => {
        if (selectedBranchSettings?._id) {
            getRegister({ id: selectedBranchSettings?._id });
        }
    }, [selectedBranchSettings]);

    const [queryParams, setQueryParams] = React.useState({
        page: 1,
        totalSize: register?.length,
        sizePerPage: 10,
    });

    React.useEffect(() => {
        setQueryParams((prev) => ({
            ...prev,
            totalSize: register?.length,
        }));
    }, [register]);

    const onPageChange = (page: number) => {
        setQueryParams((prev) => ({
            ...prev,
            page,
        }));
    };
    // import jsPDF from "jspdf";

    const handleDownload = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            try {
                const siteId = JSON.parse(selectedBranch)._id;
                let data = await handleDownloadRegistre(siteId);
                window.open(ROUTER.STATIC_FILE2(data.payload.link), "_blank");
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }

        //         const token = localStorage.getItem("token"); // Make sure to change the key based on your implementation
        //         if (!token) {
        //             throw new Error("No token found");
        //         }
        //         const response = await fetch(url, {
        //             method: "GET",
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 Authorization: `Bearer ${token}`,
        //             },
        //         });
        //         if (!response.ok) {
        //             throw new Error(`HTTP error! Status: ${response.status}`);
        //         }
        //         const users = await response.json();
        //         // Creating the PDF
        //         const doc = new jsPDF();
        //         const userList = users
        //             .map(
        //                 (user: any, index: any) => `${index + 1}. ${user.fname}`
        //             )
        //             .join("\n");
        //         doc.text(userList, 10, 10);
        //         doc.save("users.pdf");
    };

    return (
        <div>
            <Header>
                <h2 className="text-v-700 font-bold text-9xl">
                    {intl.formatMessage({ id: "EMPLOYEE.REGISTER.TITLE" })}
                </h2>
            </Header>
            {selectedBranchSettings?._id ? (
                <>
                    <div
                        style={{
                            maxHeight: "calc(100vh - 230px)",
                            overflow: "auto",
                        }}
                    >
                        <div
                            style={{
                                marginBottom: "10px",
                            }}
                        >
                            <ZAlert
                                icon={<CircleInfos />}
                                formatter={alertBody}
                            />
                        </div>
                        <Body>
                            <div
                                style={{
                                    padding: "0 20px",
                                }}
                            >
                                <RegisterFilter
                                    filterBy={filterBy}
                                    onUpdateFilterBy={onUpdateFilterBy}
                                    handleDownload={handleDownload}
                                />
                            </div>
                            <div
                                id="registerTable"
                                style={{
                                    padding: "0 20px",
                                }}
                            >
                                <RegisterTable
                                    data={getRangeData(
                                        register,
                                        queryParams.page,
                                        queryParams.sizePerPage,
                                    )}
                                />
                            </div>
                        </Body>
                    </div>
                    <Footer>
                        <div className="flex justify-center mt-4 mb-4">
                            <Pagination
                                paginationProps={{
                                    totalSize: queryParams.totalSize,
                                    page: queryParams.page,
                                    sizePerPage: queryParams.sizePerPage,
                                    onPageChange: onPageChange,
                                }}
                            />
                        </div>
                    </Footer>
                </>
            ) : (
                <Body>
                    <div className="py-8 text-n-500">
                        <PickEstablishement
                            Icon={<AdressBook />}
                            title={intl.formatMessage({ id: "MENU.REGISTER" })}
                            desc={intl.formatMessage({
                                id: "REGISTER.PLEASE_SELECT_BRANCH",
                            })}
                        />
                    </div>
                </Body>
            )}
        </div>
    );
}
