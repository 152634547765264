import * as React from "react";

import "./style.scss";

const ProgressSteps = (props: ProgressStepsProps) => {
    const { initialStep, steps, activeColor, inactiveColor } = props;
    const [activeStep, setActiveStep] = React.useState(initialStep || 1);
    const totalSteps = steps?.length;
    React.useEffect(() => {
        if (initialStep && totalSteps >= initialStep && initialStep > 0) {
            setActiveStep(initialStep ?? 1);
        }
    }, [initialStep]);

    const nextStep = () => {
        if (totalSteps >= activeStep) setActiveStep(activeStep + 1);
    };
    const prevStep = () => {
        if (activeStep > 0) setActiveStep(activeStep - 1);
    };
    const width =
        totalSteps <= activeStep + 1
            ? "100%"
            : `${(100 / (totalSteps - 1)) * activeStep}%`;
    const borderStyle = (step: number) => {
        if (activeStep === step) {
            return `3px solid ${activeColor}`;
        } else if (activeStep > step) {
            return "none";
        } else {
            return `3px solid ${inactiveColor}`;
        }
    };

    return (
        <div className='progress-steps-container'>
            <div className='step-container'>
                <div
                    className='step-wrapper-before'
                    style={{
                        backgroundColor: inactiveColor,
                    }}
                />
                {steps.map(({ label, stepName }, index) => {
                    const step = index + 1;
                    return (
                        <div className='step-wrapper' key={step}>
                            <div className='steps-stepName-container'>
                                <span
                                    style={{
                                        color:
                                            activeStep >= step
                                                ? activeColor
                                                : inactiveColor,
                                    }}
                                    className='step-name'
                                    key={step}
                                >
                                    {stepName}
                                </span>
                            </div>
                            <div
                                className='step-style'
                                style={{
                                    border: borderStyle(step),
                                }}
                            >
                                {activeStep > step ? (
                                    <div
                                        className='check-mark'
                                        style={{
                                            backgroundColor: activeColor,
                                        }}
                                    >
                                        L
                                    </div>
                                ) : (
                                    <span
                                        className='step-count'
                                        style={{
                                            backgroundColor:
                                                activeStep >= step
                                                    ? activeColor
                                                    : inactiveColor,
                                        }}
                                    ></span>
                                )}
                            </div>
                            <div className='steps-label-container'>
                                <span
                                    style={{
                                        color:
                                            activeStep >= step
                                                ? activeColor
                                                : inactiveColor,
                                    }}
                                    className='step-label'
                                    key={step}
                                >
                                    {label}
                                </span>
                            </div>
                        </div>
                    );
                })}
                <div
                    className='step-wrapper-after'
                    style={{
                        width: width,
                        backgroundColor: activeColor,
                    }}
                />
            </div>
        </div>
    );
};
interface stepsType {
    stepName: string;
    label: string;
}
interface ProgressStepsProps {
    steps: stepsType[];
    initialStep?: number;
    activeColor?: string;
    inactiveColor?: string;
}
ProgressSteps.defaultProps = {
    steps: [
        {
            stepName: "ETAPE 1",
            label: "Etablissement",
        },
        {
            stepName: "ETAPE 2",
            label: "Planning",
        },
        {
            stepName: "ETAPE 3",
            label: "Pointeuse",
        },
        {
            stepName: "ETAPE 4",
            label: "Planning",
        },
        {
            stepName: "ETAPE 5",
            label: "Pointeuse",
        },
    ],
    initialStep: 1,
    activeColor: "#2A8BAB",
    inactiveColor: "#6F78AB",
};
export default ProgressSteps;
