import React from "react";
import { useIntl } from "react-intl";
import Button from "../../atoms/ZButton";
import UnavailabilityTable from "./UnavavaibiliyTable";
import NoUnavaibilityFound from "./NoUnavaibilityFound";
import { AddSquare } from "../../../Icons";

export default function Unavailability({
  data,
  queryParams,
  openEditUnavailabilityDialog,
  openEditDeleteUnavailabilityDialog
}: UnavailabilityInterface) {
  const intl = useIntl();

  return (
    <div>
      {data?.length ? (
        <>
          <div className="flex justify-between">
            <h2 className="text-v-800 font-bold text-9xl">
              {intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAILABILITY" })}
            </h2>
            <Button
              containerClasses="bg-t-500-op-1 hover:bg-t-500-op-3 text-t-500"
              title={intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY" })}
              leftComponent={<AddSquare />}
              onClick={() => openEditUnavailabilityDialog(null)}
            />
          </div>
          <div>
            <UnavailabilityTable
              data={data}
              queryParams={queryParams}
              openEditUnavailabilityDialog={(unavaibility) =>
                openEditUnavailabilityDialog(unavaibility)
              }
              openEditDeleteUnavailabilityDialog={openEditDeleteUnavailabilityDialog}
            />

          </div>
        </>
      ) : (
        <NoUnavaibilityFound
          createNewUnavaibility={() => openEditUnavailabilityDialog(null)}
        />
      )}
    </div>
  );
}

interface UnavailabilityInterface {
  data: any[];
  queryParams: {};
  openEditUnavailabilityDialog: (unavaibility: any) => void;
  openEditDeleteUnavailabilityDialog: (unavaibility: any) => void;
}
