import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import {
    getMobileService,
    getTimeTrackerService,
    updateMobileService,
    updateTimeTrackerService,
} from "../redux/features/settings/service";
import { updateMobileSetting } from "../redux/features/settings/settingsSlice";

export const useMobile = () => {
    const dispatch = useAppDispatch();
    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;
    const mobile = useAppSelector((state: any) => state.settings).mobile;

    const getMobile = async () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings?._id) {
            dispatch(
                getMobileService({
                    token,
                    branchID: selectedBranchSettings?._id,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    const saveMobile = (payload: any) => {
        dispatch(updateMobileSetting(payload));
    };

    const updateMobile = () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings?._id && mobile) {
            dispatch(
                updateMobileService({
                    token,
                    branchID: selectedBranchSettings?._id,
                    mobile,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                    getMobile();
                }
            });
        }
    };

    return {
        mobile,
        getMobile,
        saveMobile,
        updateMobile,
    };
};
