import React from "react";
import { Rect } from "@dnd-kit/core/dist/utilities";
import { extractNumber } from "../../../_helpers/Functions";
import Input from "../../../components/atoms/Input";
import ToggleButton from "../../../components/atoms/Toggle";
import { useBranch } from "../../../hooks/useBranch";
import { useMobile } from "../../../hooks/useMobile";
import IntegrationCard from "../../../components/molecules/IntegrationCard";

import Jalia from "../../../pages/Settings/icons/jalia.svg";
import Popina from "../../../pages/Settings/icons/popina.svg";
import Silae from "../../../pages/Settings/icons/silae.svg";
import TextData from "../../../components/atoms/TextData";
import Modal from "../../../components/atoms/Modal";
import PopUpCard from "../../../components/molecules/PopUpCard";
import Swal from "sweetalert2";

import PreviousMap_ from "postcss/lib/previous-map";

function ActivationPopup(props: any) {
    const {
        display,
        keyProvider,

        onAdd,
        onChangeKey,
        onClose,
    } = props;
    const CardHeader = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "25px auto 25px 40px",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                >
                    Connexion à {keyProvider.caisseName}
                </TextData>
            </div>
        );
    };
    const handleClose = () => {
        onClose?.();
    };

    return (
        <Modal modalEnabled={display} onClose={handleClose}>
            <div className="attendance-container">
                <PopUpCard
                    containerWidth="620px"
                    display={display}
                    onClose={handleClose}
                    isCloseIcon={true}
                    header={<CardHeader />}
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleClose}
                    button2Text={keyProvider?.isEdit ? "Modifier" : "Ajouter"}
                    onClickButton2={onAdd}
                // button2Text={isShiftEditing ? "Supprimer" : "Ajouter"}
                // onClickButton2={
                //     isShiftEditing
                //         ? handleDeleteShift
                //         : toggleTab
                //           ? handleAddShift
                //           : handleAddAbsence
                // }
                // button2Style={
                //     isShiftEditing
                //         ? {
                //               backgroundColor: "#eb2f06",
                //               color: "white",
                //               fontSize: "14px",
                //           }
                //         : undefined
                // }
                // button3Text={isShiftEditing ? "Modifier" : undefined}
                // onClickButton3={
                //     isShiftEditing ? handleModifyShift : undefined
                // }
                >
                    <div
                        style={{
                            padding: "25px 40px",
                        }}
                    >
                        {" "}
                        <Input
                            captionText="Jeton d'accès"
                            captionColor="#2a346d"
                            placeholder=""
                            value={keyProvider.key}
                            required
                            onChange={(value: string) => onChangeKey(value)}
                            inputWidth="100%"
                            captionSize="12px"
                            inputRadius="4px"
                            textColor="#4C5690"
                            textSize={14}
                            iconLocation="right"
                            borderColor="#C7CBE2"
                            borderWidth={1}
                            style={{
                                inputStyle: {
                                    minHeight: "48px",
                                    fontWeight: "400",
                                    padding: "10px",
                                },
                                captionStyle: {
                                    fontWeight: "500",
                                },
                            }}
                        />
                    </div>
                </PopUpCard>
            </div>
        </Modal>
    );
}
const zeltyImg =
    "https://consumersiteimages.trustpilot.net/business-units/5d8c78625d09830001a175ba-198x149-1x.jpg";
function Caisses() {
    const { Settings, updateRemoteConfigs } = useBranch();
    const [openActivationPopup, setOpenActivationPopup] = React.useState(false);
    const [keyProvider, setKeyProvider] = React.useState({
        provider: "",
        caisseName: "",
        isEdit: false,
        key: "",
    });
    const handleOpenActivationPopup = (
        provider: string,
        caisseName: string,
    ) => {
        setKeyProvider((prev: any) => ({
            ...prev,
            provider,
            key:
                Settings?.analysisConfig?.salesApi?.provider === provider
                    ? Settings?.analysisConfig?.salesApi?.credentials?.apiKey
                    : "",
            isEdit: Settings?.analysisConfig?.salesApi?.provider === provider,
            caisseName,
        }));
        setOpenActivationPopup(true);
    };

    const handleUnactivation = (provider: string, caisseName: string) => {
        Swal.fire({
            title: "Désactiver la caisse",
            text: `Voulez-vous vraiment désactiver ${caisseName}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Désactiver",
            cancelButtonText: "Annuler",
            customClass: {
                confirmButton: "swal-button-confirm",
                cancelButton: "swal-button-cancel",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(" noww desactivateees", Settings?.analysisConfig);
                updateRemoteConfigs(null);
            }
        });
    };

    const handleOnChangeKey = (newKey: string) => {
        setKeyProvider((prev: any) => ({
            ...prev,
            key: newKey,
        }));
    };
    const handleAddKey = () => {
        updateRemoteConfigs({
            provider: keyProvider.provider,
            credentials: {
                apiKey: keyProvider.key,
            },
        })?.then((res) => {
            const data: any = res.payload;
            if (data.salesApi) {
                setOpenActivationPopup(false);
            } else if (data.message) {
                Swal.fire({
                    title: "Erreur!",
                    text: data.message,
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
        });
    };
    const handleUpdateKey = () => { };
    return (
        <div className="caisses-container">
            <IntegrationCard
                textButton={
                    Settings?.analysisConfig?.salesApi?.provider === "Pragma"
                        ? "Désactiver"
                        : "Activer"
                }
                onClick={
                    Settings?.analysisConfig?.salesApi?.provider === "Pragma"
                        ? handleUnactivation.bind(null, "Pragma", "Popina")
                        : handleOpenActivationPopup.bind(
                            null,
                            "Pragma",
                            "Popina",
                        )
                }
                activated={
                    Settings?.analysisConfig?.salesApi?.provider === "Pragma"
                }
                disabled={
                    Settings?.analysisConfig?.salesApi?.provider &&
                    Settings?.analysisConfig?.salesApi?.provider !== "Pragma"
                }
                logo={Popina}
                body={{
                    title: "Popina",
                    description:
                        "Caisse enregistreuse sur iPad intuitive et rapide.",
                }}
            />
            <IntegrationCard
                textButton={
                    Settings?.analysisConfig?.salesApi?.provider === "Zelty"
                        ? "Désactiver"
                        : "Activer"
                }
                onClick={
                    Settings?.analysisConfig?.salesApi?.provider === "Zelty"
                        ? handleUnactivation.bind(null, "Zelty", "Zelty")
                        : handleOpenActivationPopup.bind(null, "Zelty", "Zelty")
                }
                activated={
                    Settings?.analysisConfig?.salesApi?.provider === "Zelty"
                }
                disabled={
                    Settings?.analysisConfig?.salesApi?.provider &&
                    Settings?.analysisConfig?.salesApi?.provider !== "Zelty"
                }
                logo={zeltyImg}
                body={{
                    title: "Zelty",
                    description:
                        "Solution de caisse enregistreuse cloud dédiée à la restauration",
                }}
            />
            <ActivationPopup
                display={openActivationPopup}
                onClose={() => setOpenActivationPopup(false)}
                keyProvider={keyProvider}
                onChangeKey={handleOnChangeKey}
                onUpdate={handleUpdateKey}
                onAdd={handleAddKey}
            />
        </div>
    );
}
export const ItegrationProps = () => {
    const {
        branchSettings,
        handleToggleSilae,
        handleToggleShowCaisse,
        Settings,
        setProductivity,
    } = useBranch();
    return [
        {
            headerTitle: "Caisse enregistreuse",
            headerDescription:
                "Esperoo s’intègre avec des solutions de caisses enregistreuses pour synchroniser les ventes, facilitant ainsi le suivi des heures travaillées et l’optimisation des plannings.",
            configOptions: [
                {
                    optionTitle: "",
                    optionDescription: "",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13px",
                    descriptionWeight: "normal",
                    optionInput: <Caisses />,
                },

                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Productivité",
                    optionDescription:
                        "Le champ productivité permet de définir et de suivre les objectifs de productivité de votre entreprise. En fixant ces objectifs, vous pouvez planifier efficacement vos équipes. Lorsque l'objectif est atteint, il est indiqué en vert ; sinon, il apparaît en orange.",

                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="100px"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value={Settings.analysisConfig?.productivity}
                            type="number"
                            onChange={(value: any) =>
                                setProductivity(Number(value))
                            }
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },

                {
                    optionTitle: "Accès rapide à Popina",
                    optionDescription:
                        "Activez cette option pour ajouter un bouton sur la pointeuse et basculer facilement vers Popina.",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13px",
                    descriptionWeight: "normal",
                    optionInput: (
                        <ToggleButton
                            isChecked={branchSettings?.canSeeCaisse}
                            onClick={handleToggleShowCaisse}
                        />
                    ),
                },
            ],
        },
        {
            headerTitle: "Logiciels de paie",
            headerDescription:
                "Esperoo se connecte aux logiciels de paie pour faciliter la transmission des données, réduisant ainsi les erreurs et simplifiant le traitement des bulletins de salaire.",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13px",
                    descriptionWeight: "normal",
                    optionInput: (
                        <IntegrationCard
                            textButton={
                                branchSettings?.enableSilae
                                    ? "Désactiver"
                                    : "Activer"
                            }
                            onClick={handleToggleSilae}
                            activated={branchSettings?.enableSilae}
                            logo={Silae}
                            body={{
                                title: "Silae",
                                description:
                                    "Logiciel de paie puissant et automatisé.",
                            }}
                        />
                    ),
                },
            ],
        },
    ];
};