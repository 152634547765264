import * as React from "react";

import { useBranch } from "../../hooks/useBranch";
import { useTimeTracker } from "../../hooks/useTimeTracker";

import TextData from "../../components/atoms/TextData";
import Button from "../../components/atoms/Button";

import SettingSection from "../../components/organism/SettingSection";

import CommingSoon from "./Elements/CommingSoon";
import NoAccess from "./Elements/NoAccess";

import { ReactComponent as NoAccessSVG } from "./icons/no-access.svg";
import Loading from "../../components/atoms/Loading";
export default function Branch(props: BranchProps) {
    const {
        settings,
        selectedBranch,
        commingSoon,
        noAccessParams,
        includeButtons,
        submitButtonText,
        handleSubmitButton,
        cancelButtonText,
        handleCancelButton,
    } = props;
    const { ImageComponent, title, description } = noAccessParams ?? {};

    const {
        selectedBranchSettings,
        branchSettings,
        getBranchSettings,
        updateBranchSettings,
        getSiteSettings,
        setPauseRepasConditions,
        loading,
    } = useBranch();
    const { updateTimeTracker, saveTimeTracker } = useTimeTracker();
    const [activateCommingSoon, setActivateCommingSoon] =
        React.useState(commingSoon);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);
    React.useEffect(() => {
        getBranchSettings();
        getSiteSettings();
        setPauseRepasConditions();
    }, [selectedBranchSettings]);
    React.useEffect(() => {
        saveTimeTracker({ repas: branchSettings.useRepas });
    }, [branchSettings.useRepas]);

    React.useEffect(() => {
        setActivateCommingSoon(commingSoon);
    }, [commingSoon]);

    const handleSubmitChanges = () => {
        handleSubmitButton?.();
        updateBranchSettings();
        updateTimeTracker();
    };
    if (loading || isLoading) {
        return (
            <div
                style={{
                    height: "calc(100vh - 250px)",
                    overflow: "auto",
                    position: "relative",
                }}
            >
                <Loading />
            </div>
        );
    }
    return (
        <div className="branch-container">
            {/* Show the no-access component if use didn't select the branch */}
            {!selectedBranchSettings?._id && <NoAccess />}
            {/* Show the CommingSoon component if the branch page is in development */}
            {activateCommingSoon && <CommingSoon />}

            {/* Display branch title */}
            <div
                style={{
                    height: "calc(100vh - 290px)",
                    overflow: "auto",
                }}
            >
                <div>
                    <TextData
                        color="#2D2AAD"
                        size="24px"
                        width="auto"
                        fontWeight="bolder"
                        style={{
                            padding: "20px",
                        }}
                    >
                        {selectedBranch}
                    </TextData>
                </div>
                {/* Display branch content */}
                {settings?.map((elem, index) => {
                    if (Object.keys(elem)?.length === 0) {
                        return null;
                    }
                    return (
                        <SettingSection
                            groupTitle={elem.headerTitle}
                            groupDescription={elem.headerDescription}
                            configOptions={elem.configOptions}
                            key={index}
                        />
                    );
                })}
            </div>
            {includeButtons && (
                <div className="settings-footer">
                    <Button
                        style={{
                            backgroundColor: "#EDEFF5",
                            color: "#2A346D",
                            fontSize: "14px",
                        }}
                        backgroundColor="#EDEFF5"
                        textColor="#2A346D"
                        onClick={handleCancelButton}
                        width="120px"
                        height="40px"
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        style={{
                            color: "white",
                            fontSize: "14px",
                        }}
                        backgroundColor="#2A8BAB"
                        textColor="white"
                        onClick={handleSubmitChanges}
                        width="120px"
                        height="40px"
                    >
                        {/* {submitButtonText} */}
                        Enregistrer
                    </Button>
                </div>
            )}
        </div>
    );
}

interface ConfigOptionTemplate {
    optionTitle: string;
    titleColor?: string;
    titleSize?: string;
    titleWeight?: string;
    optionDescription: string;
    descriptionColor?: string;
    descriptionSize?: string;
    descriptionWeight?: string;
    optionInput: React.ReactNode;
}
interface BranchProps {
    commingSoon?: boolean;
    selectedBranch: string | null;
    noAccessParams?: {
        ImageComponent?: React.ReactNode;
        title?: string;
        description?: string;
    };
    settings?: {
        headerTitle?: string;
        headerDescription?: string;
        configOptions?: React.ReactNode | ConfigOptionTemplate[];
    }[];
    includeButtons?: boolean;
    submitButtonText?: string;
    handleSubmitButton?: () => void;
    cancelButtonText?: string;
    handleCancelButton?: () => void;
}
Branch.defaultProps = {
    commingSoon: false,
    selectedBranch: "716 Coffe Louange - Lac 2",
    noAccessParams: {
        ImageComponent: NoAccessSVG,
        title: "Paramètres",
        description:
            "Veuillez sélectionner un établissement pour accéder aux paramètres !",
    },
    settings: [],
    includeButtons: true,
    submitButtonText: "Enregistrer",
    handleSubmitButton: () => { },
    cancelButtonText: "Annuler",
    handleCancelButton: () => { },
};
