import * as React from "react";

import "./style.scss";
import PopUpCard from "../../molecules/PopUpCard";
import ProfilePicture from "../../molecules/ProfilePicture";
import NewServiceCard from "../NewServiceCard";
import EmployeeInfoHeaderRow from "../../molecules/EmployeeInfoHeaderRow";
import Picture from "../../atoms/Picture";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import TimingComponent from "../../molecules/TimingComponent";
import { SelectedShift } from "../../../redux/features/reports/types";
import { ROUTER } from "../../../constants/env";
import { useReport } from "../../../hooks/useReport";
import { useBranch } from "../../../hooks/useBranch";
import Loading from "../../atoms/Loading";
import moment from "moment";
import {
    capitalize,
    formatTime,
    convertMinutesToHours,
} from "../../../_helpers/Functions";
import { ChevronLeft, ChevronRight } from "../../../Icons";
import { ReactComponent as ChevronSVG } from "./Chevron.svg";

export const EmployeeInfoHeader = (props: EmployeeInfoHeaderProps) => {
    const {
        loading,

        employeeInfoStyle,
        profileImage,
        imageWidth,
        imageHeight,
        imageRadius,
        imageStyle,

        fullnameColor,
        fullnameSize,
        fullnameWeight,
        firstName,
        lastName,

        dotColor,
        positionColor,
        positionSize,
        positionWidth,
        position,

        weekHours,
        weekHoursColor,
        weekHoursSize,
        weekHoursWidth,
    } = props;

    const globalProps = {
        pictureProps: {
            imgPath: ROUTER.STATIC_IMG(profileImage),
            width: imageWidth,
            height: imageHeight,
            style: { borderRadius: imageRadius, ...imageStyle },
        },
        fullnameProps: {
            variant: "label" as "label",
            color: fullnameColor,
            size: fullnameSize,
            fontWeight: fullnameWeight,
            whiteSpace: "nowrap" as "nowrap",
        },
        dotProps: {
            className: "dot",
            style: { backgroundColor: dotColor },
        },
        positionProps: {
            variant: "label" as "label",
            color: positionColor,
            size: positionSize,
            width: positionWidth,
            fontWeight: "400",
        },
    };
    if (loading) {
        return (
            <div
                style={{
                    position: "relative",
                    width: "150px",
                    height: "auto",
                }}
            >
                <Loading width="100px" height="50px" />
            </div>
        );
    } else {
        return (
            <div
                className="employee-info-popup-container"
                style={employeeInfoStyle}
            >
                <div className="employee-picture">
                    <ProfilePicture
                        profileImage={profileImage}
                        firstName={firstName}
                        lastName={lastName}
                        imageWidth={imageWidth}
                        imageHeight={imageHeight}
                        imageRadius={imageRadius}
                        pictureProps={globalProps.pictureProps}
                        altColor={dotColor}
                    />
                </div>
                <div className="employee-info">
                    <TextData
                        {...globalProps.fullnameProps}
                    >{`${firstName} ${lastName}`}</TextData>
                    {/* <TextData variant="label" width="auto" size="14" color="#2A346D">
            {weekHours}
          </TextData> */}

                    {position && (
                        <div className="employee-position">
                            <div {...globalProps.dotProps}></div>
                            <TextData {...globalProps.positionProps}>
                                {position === "Administrator"
                                    ? "Administrateur"
                                    : position == "Manager"
                                        ? "Manager"
                                        : "Employé"}
                            </TextData>
                        </div>
                    )}
                </div>
            </div>
        );
    }
};
interface EmployeeInfoHeaderProps {
    loading?: boolean;
    employeeInfoStyle?: React.CSSProperties;

    profileImage: string;
    imageWidth?: string;
    imageHeight?: string;
    imageRadius?: string;
    imageStyle?: React.CSSProperties;

    fullnameColor?: string;
    fullnameSize?: string;
    fullnameWeight?: string;
    firstName: string;
    lastName: string;

    dotColor?: string;
    positionColor?: string;
    positionSize?: string;
    positionWidth?: string;
    position: string;

    weekHours: string | null;
    weekHoursColor?: string;
    weekHoursSize?: string;
    weekHoursWidth?: string;
}
EmployeeInfoHeader.defaultProps = {
    loading: false,
    profileImage: "http://localhost:5000/images//profile/1674425179648.png",
    imageWidth: "48px",
    imageHeight: "48px",
    imageRadius: "50%",
    fullnameColor: "#101844",
    fullnameSize: "20px",
    fullnameWeight: "bold",
    firstName: "Jacob",
    lastName: "Thornton",
    dotColor: "green",
    positionColor: "#4C5690",
    positionSize: "13px",
    positionWidth: "auto",
    position: "Manager",
    weekHours: "20h00",
    weekHoursColor: "#2A346D",
    weekHoursSize: "11px",
    weekHoursWidth: "auto",
};

export default function WorkHoursOverviewCard(
    props: WorkHoursOverviewCardProps,
) {
    const { selectedShift, display, onClose } = props;
    const {
        report,
        selectedBranchs,
        setFirstDayOfWeek,
        setLastDayOfWeek,
        getWeeklyWorkHours,
        getEmployeeFullReport,
    } = useReport();
    const { branchSettings } = useBranch();

    const timeFomatter = (timeInMinutes: any) => {
        if (!report?.showWorkedHoursInMinutes)
            return convertMinutesToHours(timeInMinutes);
        else return formatTime(timeInMinutes);
    };
    const [weekNumber, setPlanningProperties] = React.useState<number>(-1);
    const [fDay, setFirstDay] = React.useState<any>(null);
    React.useEffect(() => {
        if (selectedShift && selectedShift.firstDayOfWeek) {
            const weekNumber = getWeekNumber(selectedShift.firstDayOfWeek);
            setPlanningProperties(weekNumber);
            setFirstDay(selectedShift.firstDayOfWeek);
        }
    }, [fDay]);
    // React.useEffect(() => {
    //     if (selectedShift && selectedShift.firstDayOfWeek && weekNumber > 0) {
    //         // getWeeklyWorkHours(weekNumber, report.showedEmployee.id);
    //         getEmployeeFullReport(
    //             weekNumber,
    //             report.year,
    //             report.showedEmployee.id,
    //         );
    //     }
    // }, [weekNumber]);
    if (!selectedShift) return null;
    const {
        fname,
        lname,
        picture,
        role,
        firstDayOfWeek,
        lastDayOfWeek,
        fullReport,
        totalWeekRepas,
        totalWeekWorkedDays,
        totalWeekHours,
    } = selectedShift;

    const RenderShifts = () => {
        const result = fullReport?.map((theDay: any) => {
            let duration = 0;
            let repas = 0;
            let branchName = "";
            let comment = "";
            const Timing = theDay?.data?.map((action: any) => {
                const { type, info } = action;
                switch (type) {
                    case "indispo":
                        return (
                            <div>
                                <TextData>Non Disponible</TextData>
                            </div>
                        );
                    case "absence-plan":
                        return (
                            <div>
                                <TextData>{info?.typeAbsence}</TextData>
                            </div>
                        );
                    case "absence-shift":
                        return (
                            <div>
                                <TextData>{info?.absence}</TextData>
                            </div>
                        );
                    case "worked-shift":
                        duration += info?.duration;
                        repas += info?.meals;
                        branchName =
                            info?.establissement === ""
                                ? ""
                                : info?.branchName?.map((branchN: any) => (
                                    <div>{branchN}</div>
                                ));
                        comment += ` ${info?.comment}`;
                        return info?.timing?.map((shift: any) => {
                            return (
                                <div>
                                    <TimingComponent
                                        startTime={shift?.startTime}
                                        endTime={shift?.endTime}
                                        hasPause={shift?.pause}
                                        sideWidth="50px"
                                        decoratorWidth="160px"
                                    />
                                </div>
                            );
                        });

                    case "conge":
                        return (
                            <div>
                                <TextData>{info?.type}</TextData>
                            </div>
                        );

                    default:
                        return (
                            <div>
                                <TextData>
                                    veuillez contacter le support
                                </TextData>
                            </div>
                        );
                }
            });
            return (
                <tr className="overview-card-body">
                    <td>
                        {capitalize(
                            moment(theDay.date, "DD-MM-YYYY").format(
                                "ddd DD/MM/YY",
                            ),
                        )}
                    </td>
                    <td className="timing-field">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {Timing}
                        </div>
                    </td>
                    <td
                        style={{
                            color: theDay.totalHours === 0 ? "red" : "",
                        }}
                    >
                        {theDay.totalHours === 0
                            ? 0
                            : timeFomatter(String(theDay.totalHours))}
                    </td>
                    {report?.showWorkedHours && <td>{theDay.workedDays}</td>}
                    {report.showMeals && branchSettings.useRepas && (
                        <td>{theDay.totalRepas}</td>
                    )}

                    <td>{branchName}</td>

                    <td>{comment}</td>
                </tr>
            );
        });

        if (result) {
            return result;
        } else return <></>;
    };

    //change the first day of the week from the report slice on left arrow click
    const handleLeftClick = async () => {
        //get the first and last dates of the previous week
        const previousWeek = moment(firstDayOfWeek, "D MMM YYYY").subtract(
            1,
            "week",
        );
        const previousWeekLastDay = moment(
            lastDayOfWeek,
            "D MMM YYYY",
        ).subtract(1, "week");
        setFirstDayOfWeek(previousWeek.format("D MMM YYYY"));
        setLastDayOfWeek(previousWeekLastDay.format("D MMM YYYY"));
        setPlanningProperties(getWeekNumber(previousWeek));
        getEmployeeFullReport(
            previousWeek.isoWeek(),
            previousWeek.isoWeekYear(),
            report.showedEmployee.id,
        );
    };

    //change the first day of the week from the report slice on right arrow click
    const handleRightClick = async () => {
        //get the first and last dates of the next week
        const nextWeek = moment(firstDayOfWeek, "D MMM YYYY").add(1, "week");
        const nextWeekLastDay = moment(lastDayOfWeek, "D MMM YYYY").add(
            1,
            "week",
        );
        setFirstDayOfWeek(nextWeek.format("D MMM YYYY"));
        setLastDayOfWeek(nextWeekLastDay.format("D MMM YYYY"));
        setPlanningProperties(getWeekNumber(nextWeek));
        getEmployeeFullReport(
            nextWeek.isoWeek(),
            nextWeek.isoWeekYear(),
            report.showedEmployee.id,
        );
    };
    function getWeekNumber(date: any) {
        return moment(date, "D MMM YYYY").isoWeek();
    }

    return (
        <div className="workhours-popup-container">
            <PopUpCard
                activateFooter={false}
                display={display}
                onClose={onClose}
                styleCloseIcon={{
                    height: "auto",
                    marginRight: "37px",
                    width: "24px",
                    border: "1px solid #edeff533",
                    borderTopRightRadius: "20px",
                    cursor: "pointer",
                }}
                headerStyle={{
                    fontSize: "22px",
                    fontWeight: "700",
                    color: "#2A346D",
                }}
                popupHeaderStyle={{
                    // height: "80px",
                    backgroundColor: "#edeff54c",
                    borderBottom: "1px solid #C7CBE2",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                }}
                header={
                    <EmployeeInfoHeader
                        employeeInfoStyle={{
                            justifyContent: "flex-start",
                        }}
                        loading={!fname}
                        firstName={fname}
                        lastName={lname}
                        position={role}
                        positionSize={"10px"}
                        // weekHours={`${firstDayOfWeek} - ${lastDayOfWeek}`} //TODO:
                        weekHours={""} //TODO:
                        profileImage={picture ?? ""}
                        imageWidth={"40px"}
                        imageHeight={"40px"}
                        fullnameSize={"16px"}
                    />
                }
                containerWidth="1080px"
            >
                <div className="work-hours-overview-card">
                    <div className="date-info">
                        <div className="week-number">
                            <TextData
                                color="#6F78AB"
                                size="12px"
                                fontWeight="400"
                                width="fit-content"
                            >
                                Semaine {weekNumber}
                            </TextData>
                        </div>
                        <div className="date-range">
                            <ChevronSVG
                                style={{ cursor: "pointer" }}
                                onClick={handleLeftClick}
                            />
                            <TextData
                                color="#2A346D"
                                size="16px"
                                fontWeight="600"
                                style={{
                                    fontFamily: "DM Sans",
                                    textAlign: "center",
                                }}
                                width="250px"
                            >
                                {`${firstDayOfWeek} - ${lastDayOfWeek}`}
                            </TextData>
                            <ChevronSVG
                                style={{
                                    transform: "rotate(180deg)",
                                    cursor: "pointer",
                                }}
                                onClick={handleRightClick}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            maxHeight: "70vh",
                            height: "70vh",
                            overflow: "auto",
                        }}
                    >
                        {report.loading ? (
                            <div
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "#f8f9fb",
                                    borderRadius: "8px",
                                }}
                            >
                                <Loading width="350px" height="350px" />
                            </div>
                        ) : (
                            <table>
                                <tr
                                    className="overview-card-header"
                                    style={{
                                        position: "sticky",
                                        top: "0",
                                    }}
                                >
                                    <th>Date</th>
                                    <th>Horaire</th>
                                    <th>Durée</th>
                                    {report.showWorkedHours && (
                                        <th>Jours travaillés</th>
                                    )}
                                    {report.showMeals &&
                                        branchSettings.useRepas && (
                                            <th>Repas</th>
                                        )}

                                    <th>Etablissement</th>

                                    <th>Commentaire</th>
                                </tr>
                                {RenderShifts()}
                                {/* {shifts?.map((shift: any) => {
                                const {
                                    date,
                                    timing,
                                    duration,
                                    branchName,
                                    meals,
                                    comment,
                                } = shift;
                                return (
                                    <tr className='overview-card-body'>
                                        <td>{date}</td>
                                        <td className='timing-field'>
                                            {timing?.map((time: any) => {
                                                const {
                                                    startTime,
                                                    endTime,
                                                    pause,
                                                } = time;
                                                return (
                                                    <TimingComponent
                                                        startTime={startTime}
                                                        endTime={endTime}
                                                        hasPause={pause}
                                                    />
                                                );
                                            })}
                                        </td>
                                        <td>{duration}</td>
                                        <td>{meals}</td>
                                        <td>{branchName}</td>
                                        <td>{comment}</td>
                                    </tr>
                                );
                            })} */}
                                <tr
                                    className="overview-card-footer"
                                    style={{
                                        position: "sticky",
                                        bottom: "0",
                                    }}
                                >
                                    <td>Total</td>
                                    <td></td>
                                    <td
                                        style={{
                                            color: `${totalWeekHours === 0
                                                ? "red"
                                                : ""
                                                }`,
                                        }}
                                    >
                                        {totalWeekHours === 0
                                            ? 0
                                            : timeFomatter(
                                                String(totalWeekHours),
                                            )}
                                    </td>
                                    {report.showWorkedHours && (
                                        <td>{totalWeekWorkedDays}</td>
                                    )}
                                    {report.showMeals &&
                                        branchSettings.useRepas && (
                                            <td>{totalWeekRepas}</td>
                                        )}

                                    <td></td>

                                    <td></td>
                                </tr>
                            </table>
                        )}
                    </div>
                </div>
            </PopUpCard>
        </div>
    );
}
// totalWeekHours
// :
// 1579

// :
// 0
// :
// 1

// :
// 1

interface WorkHoursOverviewCardProps {
    // selectedShift: SelectedShift;
    selectedShift: any;
    display?: boolean;
    onClose?: (data?: any) => void;
}
WorkHoursOverviewCard.defaultProps = {
    selectedShift: {
        fname: "Unknown",
        lname: "Unknown",
        picture: "Unknown",
        role: "Unknown",
        shifts: [],
        firstDayOfWeek: "Unknown",
        lastDayOfWeek: "Unknown",
        totalHours: 0,
        totalPauses: 0,
        totalMeals: 0,
    },
};

// const renderShifts = () => {
//     const startDay = moment(firstDayOfWeek, "D MMM YYYY");
//     const endDay = moment(lastDayOfWeek, "D MMM YYYY");
//     let result: any[] = [];

//     if (shifts !== undefined) {
//         while (startDay.isSameOrBefore(endDay, "day")) {
//             let foundShift = false;
//             for (let i = 0; i < shifts?.length; i++) {
//                 const {
//                     date,
//                     timing,
//                     duration,
//                     branchName,
//                     meals,
//                     comment,
//                 } = shifts[i];
//                 if (date === startDay.format("ddd DD/MM/YY")) {
//                     result.push(
//                         <tr className="overview-card-body">
//                             <td>{capitalize(date)}</td>
//                             <td className="timing-field">
//                                 <div>
//                                     {timing?.map((time: any) => {
//                                         const {
//                                             startTime,
//                                             endTime,
//                                             pause,
//                                         } = time;
//                                         return (
//                                             <TimingComponent
//                                                 startTime={startTime}
//                                                 endTime={endTime}
//                                                 hasPause={
//                                                     report.showBreaks &&
//                                                     branchSettings.usePause
//                                                         ? pause
//                                                         : null
//                                                 }
//                                             />
//                                         );
//                                     })}
//                                 </div>
//                             </td>
//                             <td
//                                 style={{
//                                     color: `${duration === 0 ? "red" : ""}`,
//                                 }}
//                             >
//                                 {duration === 0
//                                     ? 0
//                                     : timeFomatter(String(duration))}
//                             </td>
//                             {report?.showWorkedHours && (
//                                 <td>
//                                     {report?.totalPerDay?.[i].workedDays ||
//                                         0}
//                                 </td>
//                             )}
//                             {report.showMeals &&
//                                 branchSettings.useRepas && (
//                                     <td>
//                                         {" "}
//                                         {report?.totalPerDay?.[i].repas ||
//                                             0}
//                                     </td>
//                                 )}
//                             <td>{branchName}</td>
//                             <td>{comment}</td>
//                         </tr>,
//                     );
//                     foundShift = true;
//                 }
//             }
//             if (!foundShift) {
//                 result.push(
//                     <tr className="overview-card-body">
//                         <td>
//                             {capitalize(startDay.format("ddd DD/MM/YY"))}
//                         </td>
//                         <td className="timing-field">
//                             <TimingComponent
//                                 startTime=""
//                                 endTime=""
//                                 hasPause={false}
//                             />
//                         </td>
//                         <td
//                             style={{
//                                 color: "red",
//                             }}
//                         >
//                             0
//                         </td>
//                         {report.showWorkedHours && <td>0</td>}
//                         {report.showMeals && <td>0</td>}
//                         <td></td>
//                         <td></td>
//                     </tr>,
//                 );
//             }
//             startDay.add(1, "day");
//         }
//     } else {
//         while (startDay <= endDay) {
//             result.push(
//                 <tr className="overview-card-body">
//                     <td>{capitalize(startDay.format("ddd DD/MM/YY"))}</td>
//                     <td className="timing-field">
//                         <TimingComponent
//                             startTime=""
//                             endTime=""
//                             hasPause={false}
//                         />
//                     </td>
//                     <td
//                         style={{
//                             color: "red",
//                         }}
//                     >
//                         0
//                     </td>
//                     {report.showMeals && <td>0</td>}
//                     <td></td>
//                     <td></td>
//                 </tr>,
//             );
//             startDay.add(1, "day");
//         }
//     }

//     return result;
// };
