import * as React from "react";

import "./style.scss";
import logoPNG from "./logo.png";

import TextData from "../../atoms/TextData";
import Picture from "../../atoms/Picture";
import Button from "../../atoms/Button";
import { ReactComponent as TickSVG } from "./tick.svg";

export default function IntegrationCard(props: IntergrationCardProps) {
    const {
        activated,
        logo,
        logoBackgroundColor,
        body,
        bodyColor,
        textButton,
        buttonColor,
        buttonTextColor,
        onClick,
        disabled,
    } = props;
    return (
        <div
            className="integration-card-container"
            style={{
                outline: `1px solid #C7CBE2`,
                overflow: "hidden",
            }}
        >
            <div className="integration-logo">
                <Picture
                    imgPath={logo}
                    style={{
                        maxHeight: "30px",
                    }}
                />
                {activated && (
                    <span
                        className="text-[12px] flex items-center"
                        style={{
                            gap: "2px",
                            padding: "2px 5px",
                            borderRadius: "5px",
                            backgroundColor: "#d2ffd4",
                            color: "#4caf50",
                        }}
                    >
                        <TickSVG color="#4caf50" width={14} height={14} />
                        Activé
                    </span>
                )}
            </div>
            <div
                style={{
                    backgroundColor: "white",
                }}
                className="integration-body"
            >
                <TextData width="auto" color="#2A346D" id="title">
                    {body?.title}
                </TextData>
                <TextData color="#6F78AB" width="auto" id="description">
                    {body?.description}
                </TextData>
            </div>
            <div
                className="group relative"
                style={{
                    backgroundColor: "#f4f9fb",
                    borderTop: "1px solid #C7CBE2",
                    padding: "15px",
                    marginTop: "auto",
                }}
            >
                {disabled && (
                    <div className="absolute group-hover:inline-block hidden text-base rounded top-[-15px] bg-[#ffdddd] text-[red] p-1">
                        Vous avez déjà une caisse activée
                    </div>
                )}
                <Button
                    onClick={onClick}
                    textColor={activated ? "red" : "#2a8bab"}
                    backgroundColor={"white"}
                    padding={"2px 5px"}
                    height="fit-content"
                    style={{
                        border: `1px solid ${activated ? "red" : !disabled ? "#2a8bab" : "#f4f9fb"}`,
                        opacity: disabled ? "0.5" : "1",
                        cursor: disabled ? "not-allowed" : "pointer",
                        fontSize: "12px",
                    }}
                    disabled={disabled}
                >
                    {textButton}
                </Button>
                {/* <span
                        className="text-[12px] flex items-center"
                        style={{
                            gap: "2px",
                            padding: "2px 5px",
                            borderRadius: "5px",
                            backgroundColor: "#d2ffd4",
                            color: "#4caf50",
                        }}
                    >
                        <TickSVG color="#4caf50" width={14} height={14} />
                        Activé
                    </span> */}
            </div>
        </div>
    );
}

interface IntergrationCardProps {
    activated?: boolean;
    logo?: string;
    logoBackgroundColor?: string[];
    body?: {
        title?: string;
        description?: string;
    };
    bodyColor?: string[];
    textButton?: string;
    buttonColor?: string[];
    buttonTextColor?: string[];
    onClick?: () => void;
    disabled?: boolean;
}

IntegrationCard.defaultProps = {
    activated: true,
    logo: logoPNG,
    logoBackgroundColor: ["#fff", "#f4f9fb"],
    body: {
        title: "Jalia",
        description:
            "Lorem, ipsum dolor amet consectetur adipisicing elit Qui, nemo et? Perferendis beatae nesciunt neque doloremque at adipisci",
    },
    bodyColor: ["#fff", "#fff"],
    textButton: "Activer",
    buttonColor: ["#2A8BAB", "#FFFFFF"],
    buttonTextColor: ["#FFFFFF", "#6F78AB"],
    onClick: () => { },
    disabled: false,
};
