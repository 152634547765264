import React from "react";
import { ChevronDown } from "../../../Icons";
import Theme from "../../../tailwind-theme";

export default function ZSelect({
    containerClasses,
    innerContainerClasses,
    headerStyle,
    value,
    placeholder,
    options,
    formatter,
    onChange,
    dropDownIcon,
    isMulti = false,
}: ZSelectInterface) {
    const [showDropDown, setShowDropDown] = React.useState(false);
    const toggleDropDown = () => {
        setShowDropDown((prev) => !prev);
    };

    const closeDropDown = () => {
        setShowDropDown(false);
    };

    const getValue = (): string | React.ReactNode => {
        if (!value) return placeholder || "";
        let index = -1;
        for (let i = 0; i < options?.length; ++i) {
            if (options[i].value.toString() === value.toString()) {
                index = i;
                break;
            }
        }
        if (index === -1) {
            return "";
        }
        if (formatter) {
            return formatter(options[index].data);
        }
        return options[index].label;
    };

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        document.addEventListener("click", (event) => {
            if (
                ref &&
                ref.current &&
                !ref.current.contains(event.target as Node)
            ) {
                closeDropDown();
            }
        });
        return () => {
            document.removeEventListener("click", () => { });
        };
    }, []);

    const filterOptions = (): OptionInterface[] => {
        if (!value) return options;
        if (isMulti) {
            let val = value as OptionInterface[];
            return [];
        } else {
            let val = value as OptionInterface;
            return options.filter((op) => op.value !== val.value);
        }
    };

    return (
        <>
            <div
                ref={ref}
                onClick={toggleDropDown}
                style={headerStyle}
                className={`${containerClasses}  h-[48px] px-2 relative  cursor-pointer rounded-lg min-w-[150px] ${showDropDown
                        ? "bg-transparent border border-t-300"
                        : "bg-n-0"
                    }`}
            >
                <div className="flex h-full gap-3 justify-between items-center text-n-700 font-semibold text-base ">
                    <div>{getValue()}</div>
                    {dropDownIcon ? (
                        <ChevronDown color={Theme.colors["n-500"]} />
                    ) : (
                        <div className={`${showDropDown ? "rotate-180" : ""}`}>
                            <ChevronDown color={Theme.colors["n-500"]} />
                        </div>
                    )}
                </div>

                <div
                    className={`absolute top-[48px] left-0 rounded-lg _shadow w-full bg-white z-10 max-h-[200px] overflow-y-scroll ${innerContainerClasses} ${showDropDown === true ? "" : "hidden"
                        }`}
                >
                    {filterOptions().map((op, key) => (
                        <div
                            className="text-n-600 font-semibold text-base px-3 py-2 rounded-lg hover:text-t-500 hover:bg-t-100"
                            key={key}
                        >
                            {formatter ? formatter(op.data) : op.label}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

interface ZSelectInterface {
    containerClasses?: string;
    innerContainerClasses?: string;
    headerStyle?: React.CSSProperties;
    value?: OptionInterface | OptionInterface[];
    placeholder?: string;
    options: OptionInterface[];
    formatter?: (op: OptionInterface) => React.ReactNode;
    onChange: (value: OptionInterface) => void;
    dropDownIcon?: string;
    isMulti?: boolean;
}

interface OptionInterface {
    value: string;
    label: string;
    data?: any;
}
