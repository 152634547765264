import * as React from "react";
import Input from "../../atoms/Input";
import TextData from "../../atoms/TextData";

import "./style.scss";
import clockSVG from "./clock.svg";

export default function SettingSection(props: SettingSectionProps) {
    const {
        optionHeader,
        groupTitle,
        groupOnClick,
        titleColor,
        titleSize,
        titleWeight,
        isComing,
        groupDescription,
        descriptionColor,
        descriptionSize,
        configOptions,
    } = props;
    const renderConfigOptions = () => {
        if (Array.isArray(configOptions)) {
            return configOptions.map((configOption, key) => {
                const {
                    optionHeader,
                    optionTitle,
                    titleColor,
                    titleSize,
                    titleWeight,
                    optionDescription,
                    descriptionColor,
                    descriptionSize,
                    descriptionWeight,
                    optionInput,
                    optionInput2,
                } = configOption;
                return (
                    <div className="option-container" key={key}>
                        <br />
                        {optionHeader && optionHeader}
                        <div className="option-container-1">
                            {optionTitle && (
                                <div>
                                    <TextData
                                        width="auto"
                                        color={titleColor}
                                        size={titleSize}
                                        fontWeight={titleWeight}
                                    >
                                        {optionTitle}
                                    </TextData>
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        color={descriptionColor}
                                        size={descriptionSize}
                                        fontWeight={descriptionWeight}
                                    >
                                        {optionDescription}
                                    </TextData>
                                </div>
                            )}
                            <div>{optionInput}</div>
                        </div>
                        {optionInput2 && (
                            <div className="option-container-2">
                                {optionInput2}
                            </div>
                        )}
                    </div>
                );
            });
        } else {
            return configOptions;
        }
    };

    return (
        <div
            className="SettingSection-container"
            style={{
                position: "relative",
            }}
        >
            <div className="config-group" onClick={groupOnClick}>
                <TextData
                    width="80%"
                    color={titleColor}
                    size={titleSize}
                    fontWeight={titleWeight}
                >
                    {groupTitle}
                </TextData>
                <TextData
                    variant="label"
                    width="80%"
                    color={descriptionColor}
                    size={descriptionSize}
                >
                    {groupDescription}
                </TextData>
            </div>
            <div className="config-options">{renderConfigOptions()}</div>
        </div>
    );
}
interface ConfigOptionTemplate {
    optionHeader?: any;
    optionTitle: string;
    titleColor?: string;
    titleSize?: string;
    isComing?: boolean;
    titleWeight?: string;
    optionDescription: string;
    descriptionColor?: string;
    descriptionSize?: string;
    descriptionWeight?: string;
    optionInput: React.ReactNode;
    optionInput2?: React.ReactNode;
}
interface SettingSectionProps {
    //config-group
    optionHeader?: string;
    groupTitle?: string;
    groupOnClick?: (data: any) => void;
    titleColor?: string;
    titleSize?: string;
    titleWeight?: string;
    groupDescription?: string;
    descriptionColor?: string;
    descriptionSize?: string;
    isComing?: boolean;
    //config-option
    configOptions?: React.ReactNode | ConfigOptionTemplate[];
}
SettingSection.defaultProps = {
    //config-group
    groupTitle: "Heure de travail",
    titleColor: "#2A346D",
    titleSize: "16px",
    isComing: false,
    titleWeight: "bolder",
    groupDescription:
        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor.",
    descriptionColor: "#6F78AB",
    descriptionSize: "13px",
    //config-option
    configOptions: [
        // {
        //     optionTitle: "Heure d'ouverture",
        //     titleColor: "#2A346D",
        //     titleSize: "16px",
        //     titleWeight: "bolder",
        //     optionDescription:
        //         "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor.",
        //     descriptionColor: "#6F78AB",
        //     descriptionSize: "13",
        //     descriptionWeight: "normal",
        //     optionInput: (
        //         <Input
        //             captionText={null}
        //             inputWidth='auto'
        //             placeholder=''
        //             borderColor='gray'
        //             borderWidth={1}
        //             value='09:05'
        //             type='time'
        //             style={{
        //                 inputStyle: {
        //                     padding: "10px 5px",
        //                     outline: "none",
        //                 },
        //             }}
        //         />
        //     ),
        // },
        // {
        //     optionTitle: "Heure de fermeture",
        //     titleColor: "#2A346D",
        //     titleSize: "16px",
        //     titleWeight: "bolder",
        //     optionDescription:
        //         "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor.",
        //     descriptionColor: "#6F78AB",
        //     descriptionSize: "13",
        //     descriptionWeight: "normal",
        //     optionInput: (
        //         <Input
        //             captionText={null}
        //             inputWidth='auto'
        //             placeholder=''
        //             borderColor='gray'
        //             borderWidth={1}
        //             value='09:05'
        //             type='time'
        //             style={{
        //                 inputStyle: {
        //                     padding: "10px 5px",
        //                     outline: "none",
        //                 },
        //             }}
        //         />
        //     ),
        // },
        // {
        //     optionTitle: "Pause par défaut (en minutes)",
        //     titleColor: "#2A346D",
        //     titleSize: "16px",
        //     titleWeight: "bolder",
        //     optionDescription:
        //         "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor.",
        //     descriptionColor: "#6F78AB",
        //     descriptionSize: "13",
        //     descriptionWeight: "normal",
        //     optionInput: (
        //         <Input
        //             captionText={null}
        //             inputWidth='6em'
        //             placeholder=''
        //             borderColor='gray'
        //             borderWidth={1}
        //             value='30 min'
        //             type='text'
        //             style={{
        //                 inputStyle: {
        //                     padding: "10px 5px",
        //                     outline: "none",
        //                 },
        //             }}
        //         />
        //     ),
        // },
    ],
};
