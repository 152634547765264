import * as React from "react";
import "./styles/schedule.style.scss";

import SettingSection from "../../components/organism/SettingSection";
import { useSchedule } from "../../hooks/useSchedule";
import { useBranch } from "../../hooks/useBranch";
import NoAccess from "./Elements/NoAccess";
import { ReactComponent as NoAccessSVG } from "./icons/no-access.svg";
import Button from "../../components/atoms/Button";
import Loading from "../../components/atoms/Loading";

export default function Schedule(props: ScheduleProps) {
    const { getScheduleSettings } = useSchedule();
    const { selectedBranchSettings, loading } = useBranch();
    const {
        commingSoon,
        noAccessParams,
        settings,
        includeButtons,
        submitButtonText,
        handleSubmitButton,
        cancelButtonText,
        handleCancelButton,
    } = props;
    const { ImageComponent, title, description } = noAccessParams ?? {};
    const [scheduleSettings, setScheduleSettings] = React.useState(settings);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);
    React.useEffect(() => {
        getScheduleSettings();
    }, [selectedBranchSettings]);
    React.useEffect(() => {
        setScheduleSettings(settings);
    }, [settings]);
    if (loading || isLoading) {
        return (
            <div
                style={{
                    height: "calc(100vh - 250px)",
                    overflow: "auto",
                    position: "relative",
                }}
            >
                <Loading />
            </div>
        );
    }
    return (
        <div className="schedule-container">
            <div
                style={{
                    height: "calc(100vh - 290px)",
                    overflow: "auto",
                }}
            >
                {!selectedBranchSettings?._id && <NoAccess />}
                {scheduleSettings?.map((elem, index) => {
                    return (
                        <SettingSection
                            groupTitle={elem.headerTitle}
                            groupDescription={elem.headerDescription}
                            configOptions={elem.configOptions}
                            titleColor={elem.titleColor}
                            descriptionColor={elem.descriptionColor}
                            isComing={elem.isComing}
                        />
                    );
                })}
            </div>
            {includeButtons && (
                <div className="settings-footer">
                    <Button
                        style={{
                            backgroundColor: "#EDEFF5",
                            color: "#2A346D",
                            fontSize: "14px",
                        }}
                        backgroundColor="#EDEFF5"
                        textColor="#2A346D"
                        onClick={handleCancelButton}
                        width="120px"
                        height="40px"
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#2A8BAB",
                            color: "white",
                            fontSize: "14px",
                        }}
                        backgroundColor="#2A8BAB"
                        textColor="white"
                        onClick={handleSubmitButton}
                        width="120px"
                        height="40px"
                    >
                        {/* {submitButtonText} */}
                        Enregistrer
                    </Button>
                </div>
            )}
        </div>
    );
}

interface ConfigOptionTemplate {
    optionTitle: string;
    titleColor?: string;
    titleSize?: string;
    titleWeight?: string;
    optionDescription: string;
    descriptionColor?: string;
    descriptionSize?: string;
    descriptionWeight?: string;
    optionInput: React.ReactNode;
}
interface ScheduleProps {
    commingSoon?: boolean;
    noAccessParams?: {
        ImageComponent?: React.ReactNode;
        title?: string;
        description?: string;
    };
    settings?: {
        isComing?: boolean;
        headerTitle?: string;
        headerDescription?: string;
        titleColor?: string;
        titleSize?: string;
        titleWeight?: string;
        groupDescription?: string;
        descriptionColor?: string;
        descriptionSize?: string;
        configOptions?: React.ReactNode | ConfigOptionTemplate[];
    }[];
    includeButtons?: boolean;
    submitButtonText?: string;
    handleSubmitButton?: () => void;
    cancelButtonText?: string;
    handleCancelButton?: () => void;
}
Schedule.defaultProps = {
    commingSoon: false,
    noAccessParams: {
        ImageComponent: NoAccessSVG,
        title: "Paramètres",
        description:
            "Veuillez sélectionner un établissement pour accéder aux paramètres !",
    },
    settings: [],
    includeButtons: true,
    submitButtonText: "Modifier",
    handleSubmitButton: () => { },
    cancelButtonText: "Annuler",
    handleCancelButton: () => { },
};
