import { useEffect } from "react";
import Swal from "sweetalert2";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";

import {
    getAdministrativeService,
    getDPAEService,
    updateAdministrativeService,
    updateDPAEinfo,
} from "../redux/features/settings/service";

import {
    updateAdministrativeDPAE,
    updateAdministrativeState,
} from "../redux/features/settings/settingsSlice";
import { administrativePayload } from "../redux/features/settings/types";

export const useAdministrative = () => {
    const dispatch = useAppDispatch();

    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings
    ).selectedBranch;

    const administrative = useAppSelector(
        (state: any) => state.settings
    ).administrative;

    const getAdministrative = () => {
        const token = localStorage.getItem("token");
        if (selectedBranchSettings?._id && token) {
            dispatch(
                getAdministrativeService({
                    branchID: selectedBranchSettings?._id,
                    token,
                })
            ).then((res) => { });
        }
    };

    const updateAdministrative = async () => {
        const token = localStorage.getItem("token");
        if (selectedBranchSettings?._id && token) {
            const actionResponse: any = await dispatch(
                updateAdministrativeService({
                    token,
                    branchID: selectedBranchSettings?._id,
                    administrative: {
                        enableDPAE: administrative.enableDPAE,
                        enableContractTemplates:
                            administrative.enableContractTemplates,
                        enableCollectiveAgreement:
                            administrative.enableCollectiveAgreement,
                        enforceCollectiveAgreement:
                            administrative.enforceCollectiveAgreement,
                    },
                })
            );
            if (actionResponse?.error) {
                return {
                    error: true,
                    message: "something went wrong!!!",
                    options: actionResponse,
                };
            } else {
                return { error: false, payload: actionResponse.payload };
            }
            console.log("actionResponse:", actionResponse);
        }
    };

    const setAdministrative = (payload: administrativePayload) => {
        dispatch(updateAdministrativeState(payload));
    };

    return {
        administrative,
        getAdministrative,
        updateAdministrative,
        setAdministrative,
    };
};
