 
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ROUTER } from "../constants/env";

import { signIn, verifyToken } from "../redux/features/auth/service";

import {
    updateEmail,
    updatePassword,
    toggleIsRememberedMe,
    updateToken,
    updateLoading,
    logout,
    resetAllAuth,
    setNoAccess,
} from "../redux/features/auth/authSlice";
import { AuthState, SignInRequest } from "../redux/features/auth/types";
import { shallowEqual } from "react-intl/src/utils";
import { usePlanning } from "./usePlanning";
import { resetAllEmp } from "../redux/features/employees/employeesSlice";
import { resetAllCountries } from "../redux/features/countries/countriesSlice";
import { resetAllEmpWorkHours } from "../redux/features/employeeWorkHours/employeeWorkHourSlice";
import { resetAllHolidays } from "../redux/features/holiday/holidaySlice";
import { resetAllLeaveBalance } from "../redux/features/leaveBalance/leaveBalanceSlice";
import { resetAllNotifications } from "../redux/features/notifications/notificationsSlice";
import { resetAllPlanning } from "../redux/features/planning/planningSlice";
import { resetAllPointeuse } from "../redux/features/pointeuse/pointeuseSlice";
import { resetAllReport } from "../redux/features/reports/reportSlice";
import { resetAllSettings } from "../redux/features/settings/settingsSlice";
import { resetAllSites } from "../redux/features/sites/sitesSlice";
import { resetAllVacation } from "../redux/features/vacation/vacationSlice";
import { resetAllVacationRule } from "../redux/features/vacationRules/vacationRuleSlice";
import { resetAllUsers } from "../redux/features/users/userSlice";
export const useLogin = () => {
    let auth = useAppSelector((state: any) => state.auth, shallowEqual);
    const { resetPlanning } = usePlanning();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const setEmail = (email: string) => {
        dispatch(updateEmail({ email }));
    };
    const setPassword = (password: string) => {
        dispatch(updatePassword({ password }));
    };
    const setIsRememberedMe = (isRememberedMe: boolean) => {
        dispatch(toggleIsRememberedMe({ isRememberedMe }));
    };
    const setToken = (token: string) => {
        dispatch(updateToken({ token }));
    };

    const handleUserRedirection = (userData: any) => {
        // navigate(ROUTER.PLANNING);
        console.log(" the user userData = = ", userData);
        // 1- planning:, 2-pointuse:Pointeuse, 3-employees:Employés, 4-rapport:Rapports

        // let manage: any = {};
        // userData?.manage?.map((manageSite: any) => {
        //     const { site, roles } = manageSite;
        //     if (userData.license.planningOn === false) {
        //         manage[site] = roles.filter(
        //             (role: string) => role !== "Planning",
        //         );
        //     } else {
        //         manage[site] = roles;
        //     }
        // });

        // console.log(" manage sites =  = = ", manage);

        //page to redirect to:
        let allRoles: String[] = ["", "", "", "", ""];
        userData?.manage?.map((manageSite: any) => {
            const { roles } = manageSite;
            roles?.map((role: string) => {
                if (
                    role === "Planning" &&
                    !allRoles[0] &&
                    userData.license.planningOn
                ) {
                    allRoles[0] = role;
                }
                if (role === "Pointeuse" && !allRoles[1]) {
                    allRoles[1] = role;
                }
                if (role === "Employés" && !allRoles[2]) {
                    allRoles[2] = role;
                }
                if (role === "Rapports" && !allRoles[3]) {
                    allRoles[3] = role;
                }
            });
        });
        allRoles = allRoles?.filter((role) => role);
        let routeToRedirect = "/";
        if (allRoles && allRoles?.length > 0) {
            switch (allRoles[0]) {
                case "Planning":
                    routeToRedirect = "/planning";
                    break;
                case "Pointeuse":
                    routeToRedirect = "/pointeuse";
                    break;

                case "Employés":
                    routeToRedirect = "/employees";
                    break;

                case "Rapports":
                    routeToRedirect = "/report/worked_hours";
                    break;

                default:
                    routeToRedirect = "/";
            }
        } else {
            dispatch(setNoAccess(true));
        }
        navigate(routeToRedirect);

        //routes[manage[siteId][0]] || "/";
        //pages[manage[siteId]] this show the array of pages we should display to the user in the sidebar
        // I should store these two objects in the redux to use them later in the routes file and sidebar / and use it here to redirect the user after login
    };

    const login = async () => {
        const { email, password, isRememberedMe } = auth;
        dispatch(signIn({ username: email, password, isRememberedMe })).then(
            (res) => {
                if (
                    res?.meta?.requestStatus === "fulfilled" &&
                    res.payload?.token &&
                    res.payload?.role !== "Employee"
                ) {
                    handleUserRedirection(res.payload);
                    // window.location.reload();
                    // window.location.href = ROUTER.PLANNING;

                    Sentry.setUser({
                        email: auth?.user?.email ?? undefined,
                        id: auth?.user?._id ?? undefined,
                    });
                }
            },
        );
    };

    const signout = async () => {
        /*
        resetAllAuth
        resetAllCountries
        resetAllEmp
        resetAllEmpWorkHours
        resetAllHolidays
        resetAllLeaveBalance
        resetAllNotifications

        resetAllPlanning
        resetAllPointeuse
        resetAllReport
        resetAllSettings
        resetAllSites
        resetAllUsers
        resetAllVacation
        resetAllVacationRule
        */
        Sentry.setUser(null);

        dispatch(logout({}));

        await dispatch(resetAllAuth());
        await dispatch(resetAllCountries());
        await dispatch(resetAllEmp());
        await dispatch(resetAllEmpWorkHours());
        await dispatch(resetAllHolidays());
        await dispatch(resetAllLeaveBalance());
        await dispatch(resetAllNotifications());
        await dispatch(resetAllPlanning());
        await dispatch(resetAllPointeuse());
        await dispatch(resetAllReport());
        await dispatch(resetAllSettings());
        await dispatch(resetAllSites());
        await dispatch(resetAllUsers());
        await dispatch(resetAllVacation());
        await dispatch(resetAllVacationRule());
        ////////////////////
        await resetPlanning();
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/login";
    };

    const checkMe = async () => {
        const token = localStorage.getItem("token");
        dispatch(
            verifyToken({
                token: token,
            }),
        );
    };

    return {
        auth,
        setEmail,
        setPassword,
        setIsRememberedMe,
        setToken,
        login,
        checkMe,
        user: auth.user,
        signout,
        noAccess: auth.noAccess,
    };
};
