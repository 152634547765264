import * as React from "react";

import TextData from "../../../atoms/TextData";

import { CurrencyValue } from "../../../../_helpers/typeInterfaces";
import "./style.scss";

import { useAnalytics } from "../../../../hooks/useAnalytics";
import { convertMinutesToHours } from "../../../../_helpers/Functions";
import Input from "../../../atoms/Input";
import { formatToTwoDecimals } from "../../../../_helpers/NumbersHelpers";

function DataTitleCell(props: any) {
    const { value, style, name } = props;
    return {
        value: (
            <TextData
                width="auto"
                color="#2A346D"
                size="14px"
                fontWeight="bold"
            >
                {value}
            </TextData>
        ),
        name,
        style,
    };
}

const DataValueCell = (props: any) => {
    const {
        data,
        style,
        name,
        color,
        onBlur,
        isEditing = false,
        setIsEditing,
        key,
    } = props;
    // const [isEditing, setIsEditing] = React.useState(false);
    // const [newAmount, setNewAmount] = React.useState(data.amount ?? 0);

    if (typeof data == "string") {
        return {
            value: (
                <TextData
                    color={color ?? "#2A346D"}
                    size="16px"
                    fontWeight="bold"
                    width="auto"
                    style={{
                        textAlign: "center",
                    }}
                >
                    {data}
                </TextData>
            ),
            name,
            style,
        };
    } else if ("currency" in data && "amount" in data) {
        const { currency, amount, rounded = false } = data;
        // if (rounded) {
        return {
            value: (
                <>
                    {isEditing && !data.readOnly ? (
                        <TextData
                            width="auto"
                            color="#2A346D"
                            size="14px"
                            fontWeight="bold"
                            style={{
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <input
                                defaultValue={
                                    data.realAmount ?? data.amount ?? 0
                                }
                                type="number"
                                // onChange={(e: any) =>
                                //     setNewAmount(e.target.value)
                                // }
                                min="0"
                                onBlur={async (e) => {
                                    if (!data.readOnly) {
                                        await onBlur?.(e.target.value);
                                        setIsEditing?.("");
                                    }
                                }}
                                style={{
                                    border: "1px solid #2a346d",
                                    padding: "8px",
                                    borderRadius: "5px",
                                    // maxWidth: "200px",
                                    width: "100px",
                                }}
                                autoFocus
                                readOnly={data.readOnly ?? false}
                            />
                        </TextData>
                    ) : (
                        <div
                            style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid gray",
                                margin: "0 20%",
                                padding: "5%",
                                borderRadius: "5px",
                            }}
                            onClick={() => !data.readOnly && setIsEditing(key)}
                        >
                            <TextData
                                variant="label"
                                color="#2A346D"
                                size="16px"
                                fontWeight="bold"
                                width="auto"
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {data.amount}
                            </TextData>
                            <TextData
                                variant="label"
                                color="#2A346D"
                                size="12px"
                                fontWeight="bold"
                                width="auto"
                                style={{
                                    textAlign: "center",
                                    paddingBottom: "5px",
                                }}
                            >
                                {`${data.currency}`}
                            </TextData>
                        </div>
                    )}
                </>
            ),
            name,
            style,
        };
    } else {
        return {
            value: null,
            name,
            style,
        };
    }
};
function ContentTable(isBudget: boolean = true, showSales: boolean = true) {
    const { data, configs, updateAnalyticsPerDay } = useAnalytics();
    const showedData = React.useMemo(() => {
        if (isBudget) {
            return data?.estimatedReport?.dailyReports || [];
        } else return data?.actualReport?.dailyReports || [];
    }, [data, isBudget]);
    const [editingDate, setIsEditing] = React.useState("");
    return [
        {
            dataRow: [
                DataTitleCell({
                    value: "Ventes HT",
                    name: "name",
                    style: {
                        color: "#4C5690",
                        paddingLeft: "40px",
                        height: "80px",
                        borderRight: "1px solid #EDEFF5",
                    },
                }),
                ...showedData.map((item: any) =>
                    DataValueCell({
                        data: {
                            currency: "Є",
                            amount: showSales
                                ? formatToTwoDecimals(item?.sales ?? 0)
                                : "***",
                            realAmount: formatToTwoDecimals(item?.sales ?? 0),
                            rounded: true,
                            readOnly: !isBudget && configs?.salesApi?.provider,
                        },
                        onBlur: (newValue: string) =>
                            updateAnalyticsPerDay({
                                reportID: data?._id ?? null,
                                reportDate: item?.reportDate?.split("T")[0],
                                sales: Number(newValue),
                                reportType: isBudget
                                    ? "estimatedReport"
                                    : "actualReport",
                            }),
                        name: "testt",
                        key: item?.reportDate,
                        isEditing: editingDate === item?.reportDate,
                        setIsEditing,

                        style: {
                            color: "#4C5690",
                            height: "80px",
                            borderRight: "1px solid #EDEFF5",
                        },
                    }),
                ),
            ],
            onClickRow: () => {
                // updateDateRange();
            },
            style: {
                cursor: "pointer",
                borderBottom: "1px solid #EDEFF5",
            },
            className: ["hover:bg-slate-100"],
        },
        {
            dataRow: [
                DataTitleCell({
                    value: isBudget ? "Heures planifiés" : "Heures travaillées",
                    name: "name",
                    style: {
                        color: "#4C5690",
                        paddingLeft: "40px",
                        height: "80px",
                        borderRight: "1px solid #EDEFF5",
                    },
                }),
                ...showedData.map((item: any) =>
                    DataValueCell({
                        data: `${formatToTwoDecimals(item?.workingHours ?? 0)}h`,
                        name: "email",
                        style: {
                            color: "#4C5690",
                            height: "80px",
                            borderRight: "1px solid #EDEFF5",
                        },
                    }),
                ),
            ],
            onClickRow: () => { },
            style: {
                cursor: "pointer",
                borderBottom: "1px solid #EDEFF5",
            },
            className: ["hover:bg-slate-100"],
        },
        {
            dataRow: [
                DataTitleCell({
                    value: "Productivité",
                    name: "name",
                    style: {
                        color: "#4C5690",
                        paddingLeft: "40px",
                        height: "80px",
                        borderRight: "1px solid #EDEFF5",
                    },
                }),
                ...showedData.map((item: any) =>
                    DataValueCell({
                        data: `${formatToTwoDecimals(item?.productivity ?? 0)}Є/h`,
                        name: "email",
                        color:
                            item?.productivity === undefined ||
                                configs?.productivity === undefined
                                ? "blue"
                                : item?.productivity === configs?.productivity
                                    ? "#00a42e"
                                    : item?.productivity < configs?.productivity
                                        ? "orange"
                                        : item?.productivity >
                                        configs?.productivity && "red",
                        style: {
                            color: "#4C5690",

                            height: "80px",
                            borderRight: "1px solid #EDEFF5",
                        },
                    }),
                ),
            ],
            onClickRow: () => { },
            style: {
                cursor: "pointer",
                borderBottom: "1px solid #EDEFF5",
            },
            className: ["hover:bg-slate-100"],
        },
        // {
        //     dataRow: [
        //         DataTitleCell({
        //             value: "Masse salariale",
        //             name: "name",
        //             style: {
        //                 color: "#4C5690",
        //                 paddingLeft: "40px",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: { currency: "Є", amount: 367 },
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: { currency: "Є", amount: 367 },
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: { currency: "Є", amount: 367 },
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: { currency: "Є", amount: 367 },
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: { currency: "Є", amount: 367 },
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: { currency: "Є", amount: 367 },
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: { currency: "Є", amount: 367 },
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //     ],
        //     onClickRow: () => {},
        //     style: {
        //         cursor: "pointer",
        //         borderBottom: "1px solid #EDEFF5",
        //     },
        //     className: ["hover:bg-slate-100"],
        // },
        // {
        //     dataRow: [
        //         DataTitleCell({
        //             value: "Ratio masse salariale",
        //             name: "name",
        //             style: {
        //                 color: "#4C5690",
        //                 paddingLeft: "40px",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: "0%",
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: "0%",
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: "0%",
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: "0%",
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: "0%",
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: "0%",
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //         DataValueCell({
        //             data: "0%",
        //             name: "email",
        //             style: {
        //                 color: "#4C5690",
        //                 height: "80px",
        //                 borderRight: "1px solid #EDEFF5",
        //             },
        //         }),
        //     ],
        //     onClickRow: () => {},
        //     style: {
        //         cursor: "pointer",
        //         borderBottom: "1px solid #EDEFF5",
        //     },
        //     className: ["hover:bg-slate-100"],
        // },
    ];
}

export default ContentTable;