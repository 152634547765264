import React, { useEffect, useState } from "react";
import { ReactComponent as InfoSVG } from "./info.svg";
import { ReactComponent as SearchSVG } from "./search.svg";
import { ReactComponent as TickSVG } from "./tick.svg";

import "./style.css";
import TextData from "../../../components/atoms/TextData";
import Button from "../../../components/atoms/Button";
import Checkbox from "../../atoms/Checkbox";
import moment from "moment";
import { ReactComponent as Chevron } from "./Chevron.svg";
import usePointeuse from "../../../hooks/usePointeuse";
import { calculateTimePeriod } from "../../../_helpers/pointeuseFunctions";
import ReportDisplayOption from "../../molecules/ReportDisplayOption";
///--footer
import SelectInput from "../../atoms/SelectInput";
import Calendar from "../../organism/Calendar";
import { useBranch } from "../../../hooks/useBranch";
import ProfilePicture from "../../molecules/ProfilePicture";
import { employeeRoleColor } from "../../../_helpers/Functions";
import { ROUTER } from "../../../constants/env";
import { useTimeTracker } from "../../../hooks/useTimeTracker";
import { ReactComponent as UnLockedSVG } from "./unlocked.svg";
import { ReactComponent as LockedSVG } from "./locked.svg";
import axios from "axios";
import {
    fetchMonthlyMarkedDatesUrl,
    fetchShiftsWeekUrl,
} from "../../../redux/features/api.routes";
///---footer -end
function PointeuseTableHead(props: any) {
    const {
        selectedDay,
        setSelectedDay,
        onValidateJourney,
        photoAllowed,
        validated,
        locked,
        numberOfEmps,
    } = props;
    const [today, setToday] = useState<moment.Moment>(moment());
    const {
        pointeuseOptions,
        setHidePause,
        setHideDays,
        setHideMeals,
        setShowTimeInMinutes,
        getPointeuseOptionsFromLocalStorage,
        getShifList,
        data,
        employeesById,
    } = usePointeuse();
    React.useEffect(() => {
        getPointeuseOptionsFromLocalStorage();
    }, []);
    React.useEffect(() => {
        setOptions((prev) => {
            const updatedOptions = [...prev];
            updatedOptions[0].checked = pointeuseOptions.hidePause || false;
            updatedOptions[1].checked = pointeuseOptions.hideDays || false;
            updatedOptions[2].checked = pointeuseOptions.hideMeals || false;
            updatedOptions[3].checked =
                pointeuseOptions.showTimeInMinutes || false;
            return updatedOptions;
        });
    }, [pointeuseOptions]);

    const [options, setOptions] = React.useState([
        {
            text: "Cacher les pauses",
            checked: false,
        },
        {
            text: "Cacher les jours",
            checked: false,
        },
        {
            text: "Cacher les repas",
            checked: false,
        },
        {
            text: "Afficher les heures travaillées (en min)",
            checked: false,
        },
    ]);
    const handleOptionActions: any = [
        setHidePause,
        setHideDays,
        setHideMeals,
        setShowTimeInMinutes,
    ];
    const handleOptionChange = (selectedOption: any) => {
        setOptions((prevOptions) => {
            const newOptions = prevOptions.map((option, index) => {
                if (option.text === selectedOption.text) {
                    handleOptionActions[index](selectedOption.checked);
                    return {
                        ...option,
                        checked: selectedOption.checked,
                    };
                }
                return option;
            });
            return newOptions;
        });
    };
    const { branchSettings } = useBranch();
    ///--footer
    const { toSelectEmployees, addEmployeeToPointeuse, pointeuseEmployees } =
        usePointeuse();
    const { selectedBranchSettings } = useBranch();
    const pictureProps = {
        // imgPath: ROUTER.STATIC_IMG(employee?.picture),
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        style: { borderRadius: "50%" },
    };
    const items: any = [
        ...toSelectEmployees?.map((item: any) => ({
            // label: <ItemLabel employee={item} />,
            label: item.fname + " " + item.lname,
            value: item._id,
            icon: (
                <div
                    style={{
                        marginRight: "8px",
                    }}
                >
                    <ProfilePicture
                        profileImage={item?.picture}
                        firstName={item?.fname}
                        lastName={item?.lname}
                        pictureProps={{
                            ...pictureProps,
                            imgPath: ROUTER.STATIC_IMG(item?.picture),
                        }}
                        altColor={employeeRoleColor(item?.role)}
                    />
                </div>
            ),
        })),
    ];
    const handleItemSelection = async (option: any) => {
        const employeeId = option.value;
        const employee: any = pointeuseEmployees.find(
            (emp: any) => emp._id === employeeId,
        );
        let res: any = await addEmployeeToPointeuse({
            employeeId,
            licenseId: employee.license[0],
            siteId: selectedBranchSettings?._id,
            day: selectedDay,
        });
        if (res?.payload?.status === "success") {
            await getShifList({
                from: selectedDay,
                to: selectedDay,
                siteId: selectedBranchSettings?._id,
                licenseId: selectedBranchSettings.license,
            });
        }
    };
    const [openCalendar, setOpenCalendar] = React.useState(false);
    const handleDaySelection = () => { };
    const hideOptions = React.useMemo(() => {
        let arr: number[] = [];
        if (!branchSettings.usePause) {
            arr.push(0);
        }
        if (!branchSettings.useRepas) {
            arr.push(2);
        }
        return arr;
    }, [branchSettings.usePause, branchSettings.useRepas]);
    const [isTraiterDisabled, setIsTraiterDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (Object.keys(data)?.length === 0) {
            setIsTraiterDisabled(false);
        } else {
            const isThereInvalidDuration = Object.keys(data)
                .map(
                    (key) =>
                        employeesById?.[key] &&
                        data[key]?.normal?.duration <= 0 &&
                        data[key]?.absent?.length === 0 &&
                        data[key]?.deleted?.length === 0,
                )
                .filter((item) => item);
            if (isThereInvalidDuration?.length > 0) {
                setIsTraiterDisabled(true);
            } else {
                setIsTraiterDisabled(false);
            }
        }
    }, [data, employeesById]);
    const [markedDays, setMarkedDays] = useState<any>({});
    const handleCalendarMonthChange = async (month: moment.Moment) => {
        try {
            const res = await axios.post(
                fetchMonthlyMarkedDatesUrl,
                {
                    siteId: selectedBranchSettings?._id,
                    month: month.month(),
                    year: month.year(),
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            setMarkedDays({
                ...markedDays,
                ...res.data,
            });
        } catch (err) {
            // TODO - handle network error here.
        }
    };
    return (
        <thead className="pointeuse-table-head">
            <tr>
                <th colSpan={6}>
                    <div
                        className="data-head"
                        style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "5px 10px",
                        }}
                    >
                        <div>
                            <SelectInput
                                id="employeePointeuseSelection"
                                isSearchable={false}
                                // menuPlacement='top'
                                placeholder="Ajouter un employé"
                                options={items}
                                selectedValue={null}
                                isChanging={false}
                                title=""
                                isDisabled={locked}
                                style={{
                                    backgroundColor: "white",
                                    borderRadius: 5,
                                    border: "1px solid #ccc",
                                    // padding: "7px 25px",
                                    width: "280px",
                                    minWidth: "220px",
                                    color: "#2A346D",
                                    position: "relative",
                                }}
                                styleOption={{
                                    paddingRight: 15,
                                    color: "#4C5690",
                                }}
                                onSelectOption={handleItemSelection}
                            />
                        </div>
                        <div
                            style={{
                                width: "calc(100% - 350px)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5px",
                                // marginLeft: "150px",
                            }}
                        >
                            <Chevron
                                width={30}
                                height={30}
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    setSelectedDay((prev: any) =>
                                        moment(prev)
                                            .add(-1, "days")
                                            .format("YYYY-MM-DD"),
                                    )
                                }
                            />
                            <TextData
                                color="#11106E"
                                size="20"
                                fontWeight="bold"
                                style={{
                                    textTransform: "capitalize",
                                    position: "relative",
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    setOpenCalendar((prev: any) => !prev)
                                }
                            >
                                {/* Jeudi 14 décembre
                                 */}
                                {moment(selectedDay).format("dddd D MMMM")}
                            </TextData>
                            <Chevron
                                width={30}
                                height={30}
                                style={{
                                    transform: "rotate(180deg)",
                                    cursor: moment(selectedDay)
                                        .add(1, "days")
                                        .isAfter(moment())
                                        ? "not-allowed"
                                        : "pointer",
                                    opacity: moment(selectedDay)
                                        .add(1, "days")
                                        .isAfter(moment())
                                        ? "0.2"
                                        : "1",
                                }}
                                onClick={() =>
                                    setSelectedDay((prev: any) => {
                                        const today = moment();
                                        const selectedDayMoment = moment(
                                            prev,
                                        ).add(1, "days");
                                        if (!selectedDayMoment.isAfter(today)) {
                                            return moment(prev)
                                                .add(1, "days")
                                                .format("YYYY-MM-DD");
                                        } else return prev;
                                    })
                                }
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: "calc(45%)",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    borderRadius: "4px",
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                }}
                                className={`fixed   z-50`}
                            >
                                <Calendar
                                    calenderContainerClasses="border _shaddow"
                                    open={openCalendar}
                                    close={() => setOpenCalendar(false)}
                                    disabledFrom={today}
                                    onChange={(value: any) => {
                                        setSelectedDay(
                                            value.format("YYYY-MM-DD"),
                                        );
                                    }}
                                    value={selectedDay}
                                    monthEffect={handleCalendarMonthChange}
                                    showWeekCount={true}
                                    markedDays={markedDays}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <ReportDisplayOption
                                options={options}
                                handleOptionChange={handleOptionChange}
                                hideOptions={hideOptions}
                            />
                            {validated ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        width="170px"
                                        padding="7px 15px"
                                        textColor="#2A8BAB"
                                        backgroundColor={
                                            locked ? "red" : "#00a42e"
                                        }
                                        style={{
                                            display: "flex",
                                            // gap: 5,
                                            fontSize: "16px",
                                            gap: "0.25rem",
                                            borderTopRightRadius: "0",
                                            borderBottomRightRadius: "0",
                                            margin: "0",
                                            opacity: locked ? "0.5" : "1",
                                            cursor: locked
                                                ? "not-allowed"
                                                : "pointer",
                                        }}
                                        height="30px"
                                        onClick={() =>
                                            !locked &&
                                            onValidateJourney({
                                                validate: false,
                                            })
                                        }
                                    >
                                        <TextData size="14px" color="white">
                                            Journée{" "}
                                            {locked ? "verrouillée" : "traitée"}
                                        </TextData>
                                    </Button>
                                    <Button
                                        width="30px"
                                        // padding="7px 15px"
                                        textColor="#2A8BAB"
                                        // backgroundColor="#b0dbbc"
                                        backgroundColor="white"
                                        style={{
                                            display: "flex",
                                            // gap: 5,
                                            fontSize: "15px",
                                            // gap: "0.25rem",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0",
                                            margin: "0",
                                            border: locked
                                                ? "2px solid red"
                                                : "2px solid #00a42e",
                                            opacity: locked
                                                ? "0.5"
                                                : validated
                                                    ? "1"
                                                    : "0",
                                            transition:
                                                "opacity 0.5s ease-in-out",
                                            cursor: locked
                                                ? "not-allowed"
                                                : "pointer",
                                        }}
                                        // width='auto'
                                        // padding='0.75rem 20px'
                                        height="30px"
                                        onClick={() =>
                                            !locked &&
                                            onValidateJourney({
                                                validate: true,
                                                lock: !locked,
                                            })
                                        }
                                    >
                                        {locked ? (
                                            <LockedSVG
                                                height={20}
                                                width={20}
                                                color="red"
                                            />
                                        ) : (
                                            <UnLockedSVG
                                                height={20}
                                                width={20}
                                                color="#00a42e"
                                            />
                                        )}
                                    </Button>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        disabled={isTraiterDisabled}
                                        width="170px"
                                        padding="7px 15px"
                                        textColor="white"
                                        backgroundColor="#2d2aad"
                                        style={{
                                            display: "flex",
                                            fontSize: "16px",
                                            gap: "0.25rem",
                                            width: "max-content",
                                        }}
                                        height="30px"
                                        onClick={() =>
                                            onValidateJourney({
                                                validate: true,
                                            })
                                        }
                                    >
                                        <TextData size="14px" color="white">
                                            Traiter la journée
                                        </TextData>
                                    </Button>

                                    <Button
                                        width="30px"
                                        // padding="7px 15px"
                                        textColor="#2A8BAB"
                                        backgroundColor="#b0dbbc"
                                        style={{
                                            display: "flex",
                                            // gap: 5,
                                            fontSize: "14px",
                                            // gap: "0.25rem",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0",
                                            margin: "0",
                                            border: "1px solid #2d2aad",
                                            opacity: "0",
                                        }}
                                        // width='auto'
                                        // padding='0.75rem 20px'
                                        height="40px"
                                        disabled
                                    >
                                        <UnLockedSVG
                                            height={20}
                                            width={20}
                                            color="#2d2aad"
                                        />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </th>
            </tr>
            <tr>
                <th
                    style={{
                        minWidth: "15%",
                        maxWidth: "17%",
                        width: "15%",
                    }}
                >
                    <div
                        className="title center"
                        style={{
                            alignItems: "flex-start",
                            padding: "0 15px",
                        }}
                    >
                        <div
                            className="center"
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            Employé ({numberOfEmps})
                        </div>
                        <div></div>
                    </div>
                </th>
                <th
                    style={{
                        minWidth: "17%",
                        width: "17%",
                        maxWidth: "20%",
                    }}
                >
                    <div className="title" style={{}}>
                        <div className="main-title">Planning</div>
                        <div className="sub-titles">
                            <div
                                style={{
                                    width: "calc(100% / 2)",
                                }}
                            >
                                Début
                            </div>
                            <div
                                style={{
                                    width: "calc(100% / 2)",
                                }}
                            >
                                Fin
                            </div>
                        </div>
                    </div>
                </th>
                <th
                    style={{
                        minWidth: "17%",
                        width: "17%",
                        maxWidth: "20%",
                    }}
                >
                    <div className="title" style={{}}>
                        <div className="main-title">Shift badgé</div>
                        <div className="sub-titles">
                            <div
                                style={{
                                    width: "calc(100% / 2)",
                                }}
                            >
                                Début
                            </div>
                            <div
                                style={{
                                    width: "calc(100% / 2)",
                                }}
                            >
                                Fin
                            </div>
                        </div>
                    </div>
                </th>
                <th
                    style={{
                        width: "18%",
                    }}
                >
                    <div
                        className="title"
                        style={{
                            width: "100%",
                        }}
                    >
                        <div className="main-title">Shift réel</div>
                        <div className="sub-titles">
                            <div
                                style={{
                                    width: "37%",
                                }}
                            >
                                Début
                            </div>
                            <div
                                style={{
                                    width: "37%",
                                }}
                            >
                                Fin
                            </div>
                            {!pointeuseOptions.hidePause &&
                                branchSettings.usePause && (
                                    <div
                                        style={{
                                            width: "26%",
                                        }}
                                    >
                                        Pause
                                    </div>
                                )}
                        </div>
                    </div>
                </th>
                {photoAllowed && (
                    <th
                        style={{
                            width: "8%",
                        }}
                    >
                        <div
                            className="title"
                            style={{
                                justifyContent: "center",
                                gap: 5,
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Photos/Signatures
                            </div>
                            <div
                                className="photo-icon"
                                style={{
                                    position: "relative",
                                    zIndex: "5",
                                }}
                            >
                                <InfoSVG
                                    height={20}
                                    width={20}
                                    style={{ cursor: "pointer" }}
                                />
                                <div
                                    className="alert-message"
                                    style={{
                                        position: "absolute",
                                        right: "-15px",
                                        borderRadius: "8px",
                                        top: "calc(100% + 15px)",
                                        padding: "20px",
                                        color: "white",
                                        backgroundColor: "#101844",
                                        fontSize: "14px",
                                        width: "250px",
                                        textAlign: "left",
                                        fontWeight: "300",
                                        zIndex: "99",
                                    }}
                                >
                                    Les photos seront supprimées
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            margin: "0 3px",
                                        }}
                                    >
                                        30 jours
                                    </span>
                                    après leur prise, même si elle n'ont pas été
                                    traitées.
                                </div>
                            </div>
                        </div>
                    </th>
                )}
                <th
                    style={{
                        minWidth: "20%",
                        maxWidth: "27%",
                    }}
                >
                    <div className="title">
                        <div
                            style={{
                                display: "flex",
                                fontWeight: "bold",
                                width: "100%",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <div
                                style={{
                                    width: "calc(100% / 4)",
                                }}
                            >
                                Durée
                            </div>
                            {!pointeuseOptions.hideDays && (
                                <div
                                    style={{
                                        width: "calc(100% / 4)",
                                    }}
                                >
                                    Nb jours
                                </div>
                            )}
                            {!pointeuseOptions.hideMeals &&
                                branchSettings.useRepas && (
                                    <div
                                        style={{
                                            width: "calc(100% / 4)",
                                        }}
                                    >
                                        Repas
                                    </div>
                                )}
                            <div
                                style={{
                                    width: "calc(100% / 4)",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",

                                    gap: 5,
                                }}
                            >
                                <div>Statut</div>
                            </div>
                        </div>
                    </div>
                </th>
            </tr>
        </thead>
    );
}
export default React.memo(PointeuseTableHead);
