import React, { useEffect } from "react";
import ZToggleButton from "../../atoms/ZToggleButton";
import ZCheckBox from "../../atoms/ZCheckbox";
import ZSelect from "../../atoms/ZSelect";
import { FormattedMessage, useIntl } from "react-intl";
import SelectInput from "../../atoms/SelectInput";
// import SelectInput from "../SelectInput";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const managePages = [
    { value: "Planning", label: <FormattedMessage id={"MENU.PLANNING"} /> },
    { value: "Pointeuse", label: <FormattedMessage id={"MENU.POINTEUSE"} /> },
    { value: "Employés", label: <FormattedMessage id={"MENU.EMPLOYEES"} /> },
    { value: "Rapports", label: <FormattedMessage id={"MENU.RAPPORTS"} /> },
];

function EstablishementPicker({
    pickerType,
    establishements,
    title,
    manage,
    role,
    belongsTo,
    planning,
    selectedDepartment,
    onChange,
    direction = "row",
    departements,
    multiplePlanning,
}: EstablishementPickerInterface) {
    const [branchsLabeled, setBranchsLabeled] = React.useState(() => {
        const sessionUser = sessionStorage.getItem("user");
        let sessionLicense: any[] = [];
        if (sessionUser) {
            sessionLicense = JSON.parse(sessionUser).realLicense;
        }
        if (sessionLicense?.length > 1) {
            return establishements.map((etab: any) => {
                const LicenseEnseigne = sessionLicense.find((license: any) => {
                    return license._id === etab.license;
                });
                let enseigneName = "";
                if (LicenseEnseigne) {
                    enseigneName = LicenseEnseigne.enseigne;
                }
                const etablissement = {
                    ...etab,
                    name: `${enseigneName} - ${etab.name}`,
                };
                return etablissement;
            });
        } else {
            return establishements;
        }
    });
    const getTogglLabel = (text: string, active: boolean) => (
        <label
            className={`font-bold text-md ${active ? "text-n-800" : "text-n-600"
                }`}
        >
            {text}
        </label>
    );
    const getCheckLabel = (text: string | React.ReactNode, active: boolean) => (
        <label
            className={`font-semibold text-base ml-2 ${active ? "text-t-500" : "text-n-500"
                }`}
        >
            {text}
        </label>
    );

    const intl = useIntl();

    const checkManage = (establishementId: string, value: string) => {
        const newVals = [];
        let index = -1;
        manage.forEach((establishement, i) => {
            if (establishement.site === establishementId) {
                index = i;
            } else {
                newVals.push(establishement);
            }
        });
        if (index === -1) {
            newVals.push({
                site: establishementId,
                roles: [value],
            });
        } else {
            let roles = [...manage[index].roles];
            let roleIndex = roles.indexOf(value);
            if (roleIndex !== -1) {
                roles.splice(roleIndex, 1);
            } else {
                roles.push(value);
            }
            newVals.push({
                site: establishementId,
                roles: roles,
            });
        }
        onChange("manage", newVals);
    };

    const isEstablishementRolePicked = (
        establishementId: string,
        value: string,
    ) => {
        let checked = false;
        manage.forEach((establishement) => {
            if (establishement.site === establishementId) {
                checked = establishement.roles.includes(value);
            }
        });
        return checked;
    };
    React.useEffect(() => {
        if (pickerType === "create") {
            let etablishmentList = [];

            switch (role) {
                case "Administrator":
                    etablishmentList = establishements.map(
                        (etab: any) => etab._id,
                    );
                    onChange("planning", []);
                    break;
                case "Employee":
                case "Manager":
                    if (establishements && establishements?.length === 1) {
                        etablishmentList = [establishements[0]._id];
                    }
                    break;
                default:
                    break;
            }

            onChange("belongsTo", etablishmentList);
        }
    }, [role]);
    React.useEffect(() => {
        if (
            pickerType === "create" &&
            (role === "Employee" || role === "Manager")
        ) {
            onChange("planning", belongsTo);
        }
    }, [belongsTo, role]);
    const [currentSlide, setCurrentSlide] = React.useState<number>(0);
    const settings = {
        dots: true,
        infinite: false,
        speed: 200,
        slidesToShow: 5,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => setCurrentSlide(next),
    };
    const ManageAccessSites = React.memo((props: any) => {
        let { establishements } = props;
        const [selectedDept, setSelectedDept] = React.useState<any>([]);

        establishements = establishements?.map((etab: any) => {
            const depts = departements.find(
                (dept: any) => dept.site === etab._id,
            );
            if (depts) {
                return {
                    ...etab,
                    departments: depts.depts,
                };
            } else {
                return {
                    ...etab,
                    departments: [],
                };
            }
        });
        const selectedDepartments = (establishement: any) => {
            const selectedSite = selectedDepartment?.find(
                (item: any) => item.site === establishement._id,
            );
            if (!selectedSite) {
                return [];
            }
            const existSiteDepts = departements?.find(
                (item: any) => item.site === establishement._id,
            );
            if (!existSiteDepts) {
                return [];
            }
            const existedSelectedDepts = selectedSite.depts;
            const existedDepts = existSiteDepts.depts;

            const values = existedDepts
                .map((existDept: any) => {
                    if (existedSelectedDepts.includes(existDept._id)) {
                        return {
                            label: existDept.name,
                            value: existDept._id,
                        };
                    }
                })
                .filter((item: any) => item);
            if (!values) {
                return [];
            }
            return values;
        };
        if (role === "Administrator") {
            return (
                <Slider {...settings} initialSlide={currentSlide}>
                    {establishements.map((establishement: any, key: number) => (
                        <div key={establishement._id}>
                            <div
                                style={{
                                    borderWidth: "1px",
                                    borderRadius: "0.5rem",
                                    width: "500px !important",
                                    margin: "0 3px",
                                    backgroundColor: "red !important",
                                    padding: "10px",
                                }}
                                className="border rounded-md w-500px-important mx-10px bg-red-important p-2 bg-white"
                            // className={`${
                            //     belongsTo.includes(establishement._id)
                            //         ? "border-t-100"
                            //         : "border-n-0"
                            // }  eta `}
                            >
                                <div
                                    key={key}
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                        gap: "1.25rem",
                                    }}
                                    className={`${direction === "row" ? "" : "flex-col"}`}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "0.5rem",
                                        }}
                                        className={`${direction === "row"
                                            ? "min-w-[100px]"
                                            : "w-full"
                                            } `}
                                    >
                                        <ZToggleButton
                                            leftComponent={getTogglLabel(
                                                establishement.name,
                                                belongsTo.includes(
                                                    establishement._id,
                                                ),
                                            )}
                                            isChecked={belongsTo.includes(
                                                establishement._id,
                                            )}
                                            onPress={() => {
                                                let newVals = [...belongsTo];
                                                if (
                                                    belongsTo.indexOf(
                                                        establishement._id,
                                                    ) !== -1
                                                ) {
                                                    newVals.splice(
                                                        belongsTo.indexOf(
                                                            establishement._id,
                                                        ),
                                                        1,
                                                    );
                                                } else {
                                                    newVals.push(
                                                        establishement._id,
                                                    );
                                                }
                                                onChange("belongsTo", newVals);
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center">
                                        <ZCheckBox
                                            isChecked={planning.includes(
                                                establishement._id,
                                            )}
                                            onPress={() => {
                                                if (
                                                    belongsTo.includes(
                                                        establishement._id,
                                                    )
                                                ) {
                                                    let newVals = [...planning];
                                                    if (
                                                        planning.indexOf(
                                                            establishement._id,
                                                        ) !== -1
                                                    ) {
                                                        newVals.splice(
                                                            planning.indexOf(
                                                                establishement._id,
                                                            ),
                                                            1,
                                                        );
                                                    } else {
                                                        newVals.push(
                                                            establishement._id,
                                                        );
                                                    }
                                                    onChange(
                                                        "planning",
                                                        newVals,
                                                    );
                                                }
                                            }}
                                        />
                                        <label>
                                            {getCheckLabel(
                                                intl.formatMessage({
                                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                                }),
                                                planning.includes(
                                                    establishement._id,
                                                ),
                                            )}
                                        </label>
                                    </div>
                                    {multiplePlanning &&
                                        establishement?.departments?.length >
                                        0 && (
                                            <div
                                                className={`${direction === "row"
                                                    ? "ml-auto"
                                                    : "hidden"
                                                    }`}
                                            >
                                                <SelectInput
                                                    title=""
                                                    options={establishement?.departments?.map(
                                                        (dept: any) => ({
                                                            label: dept.name,
                                                            value: dept._id,
                                                        }),
                                                    )}
                                                    isMultiple={true}
                                                    selectedValue={selectedDepartments(
                                                        establishement,
                                                    )}
                                                    onSelectOption={async (
                                                        department: any,
                                                    ) => {
                                                        let updatedDept: any[] =
                                                            [];
                                                        if (
                                                            selectedDepartment
                                                        ) {
                                                            updatedDept = [
                                                                ...selectedDepartment?.filter(
                                                                    (
                                                                        dept: any,
                                                                    ) =>
                                                                        dept.site !==
                                                                        establishement._id,
                                                                ),
                                                            ];
                                                        }
                                                        updatedDept = [
                                                            ...updatedDept,
                                                            {
                                                                site: establishement._id,
                                                                depts: department.map(
                                                                    (
                                                                        dept: any,
                                                                    ) =>
                                                                        dept.value,
                                                                ),
                                                            },
                                                        ].filter(
                                                            (dept: any) =>
                                                                dept.depts
                                                                    ?.length > 0,
                                                        );
                                                        // await setSelectedDept(updatedDept);

                                                        onChange(
                                                            "departements",
                                                            updatedDept,
                                                        );
                                                    }}
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "MENU.DEPARTEMENTS",
                                                        },
                                                    )}
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            );
        } else if (role === "Manager") {
            return (
                <Slider {...settings} initialSlide={currentSlide}>
                    {establishements.map((establishement: any, key: number) => (
                        <div key={establishement._id}>
                            <div
                                style={{
                                    borderWidth: "1px",
                                    borderRadius: "0.5rem",
                                    width: "500px !important",
                                    margin: "0 3px",
                                    backgroundColor: "red !important",
                                    padding: "10px",
                                }}
                                className="border rounded-md w-500px-important mx-10px bg-red-important p-2 bg-white"
                            // className={`${
                            //     belongsTo.includes(establishement._id)
                            //         ? "border-t-100"
                            //         : "border-n-0"
                            // }  eta `}
                            >
                                <div
                                    key={key}
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                        gap: "1.25rem",
                                    }}
                                    className={`${direction === "row" ? "" : "flex-col"}`}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "0.5rem",
                                        }}
                                        className={`${direction === "row"
                                            ? "min-w-[100px]"
                                            : "w-full"
                                            } `}
                                    >
                                        <ZToggleButton
                                            leftComponent={getTogglLabel(
                                                establishement.name,
                                                belongsTo.includes(
                                                    establishement._id,
                                                ),
                                            )}
                                            isChecked={belongsTo.includes(
                                                establishement._id,
                                            )}
                                            onPress={() => {
                                                let newVals = [...belongsTo];
                                                if (
                                                    belongsTo.indexOf(
                                                        establishement._id,
                                                    ) !== -1
                                                ) {
                                                    newVals.splice(
                                                        belongsTo.indexOf(
                                                            establishement._id,
                                                        ),
                                                        1,
                                                    );
                                                } else {
                                                    newVals.push(
                                                        establishement._id,
                                                    );
                                                }
                                                onChange("belongsTo", newVals);
                                            }}
                                        />
                                    </div>
                                    {multiplePlanning &&
                                        establishement?.departments?.length >
                                        0 && (
                                            <div
                                                className={`${direction === "row"
                                                    ? "ml-auto"
                                                    : "hidden"
                                                    }`}
                                            >
                                                <SelectInput
                                                    style={{
                                                        padding: "0",
                                                        height: "40px",
                                                    }}
                                                    isMultiple={true}
                                                    title=""
                                                    options={establishement?.departments?.map(
                                                        (dept: any) => ({
                                                            label: dept.name,
                                                            value: dept._id,
                                                        }),
                                                    )}
                                                    selectedValue={selectedDepartments(
                                                        establishement,
                                                    )}
                                                    onSelectOption={async (
                                                        department: any,
                                                    ) => {
                                                        let updatedDept: any[] =
                                                            [];
                                                        if (
                                                            selectedDepartment
                                                        ) {
                                                            updatedDept = [
                                                                ...selectedDepartment?.filter(
                                                                    (
                                                                        dept: any,
                                                                    ) =>
                                                                        dept.site !==
                                                                        establishement._id,
                                                                ),
                                                            ];
                                                        }
                                                        updatedDept = [
                                                            ...updatedDept,
                                                            {
                                                                site: establishement._id,
                                                                depts: department.map(
                                                                    (
                                                                        dept: any,
                                                                    ) =>
                                                                        dept.value,
                                                                ),
                                                            },
                                                        ].filter(
                                                            (dept: any) =>
                                                                dept.depts
                                                                    ?.length > 0,
                                                        );
                                                        // await setSelectedDept(updatedDept);

                                                        onChange(
                                                            "departements",
                                                            updatedDept,
                                                        );
                                                    }}
                                                    // onChange={() => {}}
                                                    menuPlacement="auto"
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "MENU.DEPARTEMENTS",
                                                        },
                                                    )}
                                                />
                                            </div>
                                        )}
                                </div>
                                {/** this part is for access management for Manager Role */}
                                <div
                                    className={`py-2 mx-4 border-t ${belongsTo.includes(establishement._id)
                                        ? "border-t-100"
                                        : "border-n-0"
                                        }  flex   flex-wrap ${direction === "col"
                                            ? "flex-col"
                                            : "justify-between items-center"
                                        }`}
                                    style={{
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div className="flex items-center">
                                        <ZCheckBox
                                            isChecked={planning.includes(
                                                establishement._id,
                                            )}
                                            onPress={() => {
                                                if (
                                                    belongsTo.includes(
                                                        establishement._id,
                                                    )
                                                ) {
                                                    let newVals = [...planning];
                                                    if (
                                                        planning.indexOf(
                                                            establishement._id,
                                                        ) !== -1
                                                    ) {
                                                        newVals.splice(
                                                            planning.indexOf(
                                                                establishement._id,
                                                            ),
                                                            1,
                                                        );
                                                    } else {
                                                        newVals.push(
                                                            establishement._id,
                                                        );
                                                    }
                                                    onChange(
                                                        "planning",
                                                        newVals,
                                                    );
                                                }
                                            }}
                                        />
                                        <label>
                                            {getCheckLabel(
                                                intl.formatMessage({
                                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                                }),
                                                planning.includes(
                                                    establishement._id,
                                                ),
                                            )}
                                        </label>
                                    </div>
                                    {managePages.map((page, key) => (
                                        <div
                                            key={key}
                                            className={`flex items-center flex-wrap ${direction === "row" &&
                                                "justify-between"
                                                }`}
                                        >
                                            <ZCheckBox
                                                isChecked={isEstablishementRolePicked(
                                                    establishement._id,
                                                    page.value,
                                                )}
                                                onPress={() => {
                                                    if (
                                                        belongsTo.includes(
                                                            establishement._id,
                                                        )
                                                    ) {
                                                        checkManage(
                                                            establishement._id,
                                                            page.value,
                                                        );
                                                    }
                                                }}
                                            />
                                            <label>
                                                {getCheckLabel(
                                                    page.label,
                                                    false,
                                                )}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            );
        } else if (role === "Employee") {
            return (
                <Slider {...settings} initialSlide={currentSlide}>
                    {establishements.map((establishement: any, key: number) => (
                        <div key={establishement._id}>
                            <div
                                style={{
                                    borderWidth: "1px",
                                    borderRadius: "0.5rem",
                                    width: "500px !important",
                                    margin: "0 3px",
                                    backgroundColor: "red !important",
                                    padding: "10px",
                                }}
                                className="border rounded-md w-500px-important mx-10px bg-red-important p-2 bg-white"
                            // className={`${
                            //     belongsTo.includes(establishement._id)
                            //         ? "border-t-100"
                            //         : "border-n-0"
                            // }  eta `}
                            >
                                <div
                                    key={key}
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                        gap: "1.25rem",
                                    }}
                                    className={`${direction === "row" ? "" : "flex-col"}`}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "0.5rem",
                                        }}
                                        className={`${direction === "row"
                                            ? "min-w-[100px]"
                                            : "w-full"
                                            } `}
                                    >
                                        <ZToggleButton
                                            leftComponent={getTogglLabel(
                                                establishement.name,
                                                belongsTo.includes(
                                                    establishement._id,
                                                ),
                                            )}
                                            isChecked={belongsTo.includes(
                                                establishement._id,
                                            )}
                                            onPress={() => {
                                                let newVals = [...belongsTo];
                                                if (
                                                    belongsTo.indexOf(
                                                        establishement._id,
                                                    ) !== -1
                                                ) {
                                                    newVals.splice(
                                                        belongsTo.indexOf(
                                                            establishement._id,
                                                        ),
                                                        1,
                                                    );
                                                } else {
                                                    newVals.push(
                                                        establishement._id,
                                                    );
                                                }
                                                onChange("belongsTo", newVals);
                                            }}
                                        />
                                    </div>

                                    <div className="flex items-center">
                                        <ZCheckBox
                                            isChecked={planning.includes(
                                                establishement._id,
                                            )}
                                            onPress={() => {
                                                if (
                                                    belongsTo.includes(
                                                        establishement._id,
                                                    )
                                                ) {
                                                    let newVals = [...planning];
                                                    if (
                                                        planning.indexOf(
                                                            establishement._id,
                                                        ) !== -1
                                                    ) {
                                                        newVals.splice(
                                                            planning.indexOf(
                                                                establishement._id,
                                                            ),
                                                            1,
                                                        );
                                                    } else {
                                                        newVals.push(
                                                            establishement._id,
                                                        );
                                                    }
                                                    onChange(
                                                        "planning",
                                                        newVals,
                                                    );
                                                }
                                            }}
                                        />
                                        <label>
                                            {getCheckLabel(
                                                intl.formatMessage({
                                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                                }),
                                                planning.includes(
                                                    establishement._id,
                                                ),
                                            )}
                                        </label>
                                    </div>
                                    {multiplePlanning &&
                                        establishement?.departments?.length >
                                        0 && (
                                            <div
                                                className={`${direction === "row"
                                                    ? "ml-auto"
                                                    : "hidden"
                                                    }`}
                                            >
                                                <SelectInput
                                                    title=""
                                                    options={establishement?.departments?.map(
                                                        (dept: any) => ({
                                                            label: dept.name,
                                                            value: dept._id,
                                                        }),
                                                    )}
                                                    isMultiple={true}
                                                    selectedValue={selectedDepartments(
                                                        establishement,
                                                    )}
                                                    onSelectOption={async (
                                                        department: any,
                                                    ) => {
                                                        let updatedDept: any[] =
                                                            [];
                                                        if (
                                                            selectedDepartment
                                                        ) {
                                                            updatedDept = [
                                                                ...selectedDepartment?.filter(
                                                                    (
                                                                        dept: any,
                                                                    ) =>
                                                                        dept.site !==
                                                                        establishement._id,
                                                                ),
                                                            ];
                                                        }
                                                        updatedDept = [
                                                            ...updatedDept,
                                                            {
                                                                site: establishement._id,
                                                                depts: department.map(
                                                                    (
                                                                        dept: any,
                                                                    ) =>
                                                                        dept.value,
                                                                ),
                                                            },
                                                        ].filter(
                                                            (dept: any) =>
                                                                dept.depts
                                                                    ?.length > 0,
                                                        );
                                                        // await setSelectedDept(updatedDept);

                                                        onChange(
                                                            "departements",
                                                            updatedDept,
                                                        );
                                                    }}
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: "MENU.DEPARTEMENTS",
                                                        },
                                                    )}
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            );
        }

        return (
            <Slider {...settings} initialSlide={currentSlide}>
                {establishements.map((establishement: any, key: number) => (
                    <div key={establishement._id}>
                        <div
                            style={{
                                borderWidth: "1px",
                                borderRadius: "0.5rem",
                                marginTop: "0.75rem",
                                marginBottom: "0.75rem",
                            }}
                            className={`${belongsTo.includes(establishement._id)
                                ? "border-t-100"
                                : "border-n-0"
                                } `}
                        >
                            <div
                                key={key}
                                style={{
                                    display: "flex",
                                    padding: "1rem",
                                    gap: "1.25rem",
                                }}
                                className={`${direction === "row" ? "" : "flex-col"}`}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                    }}
                                    className={`${direction === "row"
                                        ? "min-w-[100px]"
                                        : "w-full"
                                        } `}
                                >
                                    <ZToggleButton
                                        leftComponent={getTogglLabel(
                                            establishement.name,
                                            belongsTo.includes(
                                                establishement._id,
                                            ),
                                        )}
                                        isChecked={belongsTo.includes(
                                            establishement._id,
                                        )}
                                        onPress={() => {
                                            let newVals = [...belongsTo];
                                            if (
                                                belongsTo.indexOf(
                                                    establishement._id,
                                                ) !== -1
                                            ) {
                                                newVals.splice(
                                                    belongsTo.indexOf(
                                                        establishement._id,
                                                    ),
                                                    1,
                                                );
                                            } else {
                                                newVals.push(
                                                    establishement._id,
                                                );
                                            }
                                            onChange("belongsTo", newVals);
                                        }}
                                    />
                                </div>
                                {role !== "Manager" && (
                                    <div className="flex items-center">
                                        <ZCheckBox
                                            isChecked={planning.includes(
                                                establishement._id,
                                            )}
                                            onPress={() => {
                                                if (
                                                    belongsTo.includes(
                                                        establishement._id,
                                                    )
                                                ) {
                                                    let newVals = [...planning];
                                                    if (
                                                        planning.indexOf(
                                                            establishement._id,
                                                        ) !== -1
                                                    ) {
                                                        newVals.splice(
                                                            planning.indexOf(
                                                                establishement._id,
                                                            ),
                                                            1,
                                                        );
                                                    } else {
                                                        newVals.push(
                                                            establishement._id,
                                                        );
                                                    }
                                                    onChange(
                                                        "planning",
                                                        newVals,
                                                    );
                                                }
                                            }}
                                        />
                                        <label>
                                            {getCheckLabel(
                                                intl.formatMessage({
                                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                                }),
                                                planning.includes(
                                                    establishement._id,
                                                ),
                                            )}
                                        </label>
                                    </div>
                                )}
                                {role !== "Administrator" &&
                                    multiplePlanning &&
                                    departements?.length > 0 && (
                                        <div
                                            className={`${direction === "row"
                                                ? "ml-auto"
                                                : "hidden"
                                                }`}
                                        >
                                            <SelectInput
                                                title=""
                                                options={departements}
                                                onSelectOption={() => { }}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id: "MENU.DEPARTEMENTS",
                                                    },
                                                )}
                                            />
                                        </div>
                                    )}
                            </div>

                            {/** this part is for access management for Manager Role */}
                            {role === "Manager" && (
                                <div
                                    className={`py-2 mx-4 border-t ${belongsTo.includes(establishement._id)
                                        ? "border-t-100"
                                        : "border-n-0"
                                        }  flex   flex-wrap ${direction === "col"
                                            ? "flex-col"
                                            : "justify-between items-center"
                                        }`}
                                >
                                    <div className="flex items-center">
                                        <ZCheckBox
                                            isChecked={planning.includes(
                                                establishement._id,
                                            )}
                                            onPress={() => {
                                                if (
                                                    belongsTo.includes(
                                                        establishement._id,
                                                    )
                                                ) {
                                                    let newVals = [...planning];
                                                    if (
                                                        planning.indexOf(
                                                            establishement._id,
                                                        ) !== -1
                                                    ) {
                                                        newVals.splice(
                                                            planning.indexOf(
                                                                establishement._id,
                                                            ),
                                                            1,
                                                        );
                                                    } else {
                                                        newVals.push(
                                                            establishement._id,
                                                        );
                                                    }
                                                    onChange(
                                                        "planning",
                                                        newVals,
                                                    );
                                                }
                                            }}
                                        />
                                        <label>
                                            {getCheckLabel(
                                                intl.formatMessage({
                                                    id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                                }),
                                                planning.includes(
                                                    establishement._id,
                                                ),
                                            )}
                                        </label>
                                    </div>
                                    {managePages.map((page, key) => (
                                        <div
                                            key={key}
                                            className={`flex items-center flex-wrap ${direction === "row" &&
                                                "justify-between"
                                                }`}
                                        >
                                            <ZCheckBox
                                                isChecked={isEstablishementRolePicked(
                                                    establishement._id,
                                                    page.value,
                                                )}
                                                onPress={() => {
                                                    if (
                                                        belongsTo.includes(
                                                            establishement._id,
                                                        )
                                                    ) {
                                                        checkManage(
                                                            establishement._id,
                                                            page.value,
                                                        );
                                                    }
                                                }}
                                            />
                                            <label>
                                                {getCheckLabel(
                                                    page.label,
                                                    false,
                                                )}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </Slider>
        );
    });
    const ManageAccessSitesWithoutSlider = React.memo((props: any) => {
        let { establishements } = props;
        const [selectedDept, setSelectedDept] = React.useState<any>([]);
        establishements = establishements?.map((etab: any) => {
            const depts = departements.find(
                (dept: any) => dept.site === etab._id,
            );
            if (depts) {
                return {
                    ...etab,
                    departments: depts.depts,
                };
            } else {
                return {
                    ...etab,
                    departments: [],
                };
            }
        });
        const selectedDepartments = (establishement: any) => {
            const selectedSite = selectedDepartment?.find(
                (item: any) => item.site === establishement._id,
            );
            if (!selectedSite) {
                return [];
            }
            const existSiteDepts = departements?.find(
                (item: any) => item.site === establishement._id,
            );
            if (!existSiteDepts) {
                return [];
            }
            const existedSelectedDepts = selectedSite.depts;
            const existedDepts = existSiteDepts.depts;

            const values = existedDepts
                .map((existDept: any) => {
                    if (existedSelectedDepts.includes(existDept._id)) {
                        return {
                            label: existDept.name,
                            value: existDept._id,
                        };
                    }
                })
                .filter((item: any) => item);
            if (!values) {
                return [];
            }
            return values;
        };
        if (role === "Administrator") {
            return establishements.map((establishement: any, key: number) => (
                <div
                    key={key}
                    style={{
                        borderWidth: "1px",
                        borderRadius: "0.5rem",
                        marginTop: "0.75rem",
                        marginBottom: "0.75rem",
                    }}
                    className={`${belongsTo.includes(establishement._id)
                        ? "border-t-100"
                        : "border-[#bec2d7]"
                        } `}
                >
                    <div
                        key={key}
                        style={{
                            display: "flex",
                            padding: "1rem",
                            gap: "1.25rem",
                        }}
                        className={`${direction === "row" ? "" : "flex-col"}`}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                            className={`${direction === "row" ? "min-w-[100px]" : "w-full"
                                } `}
                        >
                            <ZToggleButton
                                leftComponent={getTogglLabel(
                                    establishement.name,
                                    belongsTo.includes(establishement._id),
                                )}
                                isChecked={belongsTo.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    let newVals = [...belongsTo];
                                    if (
                                        belongsTo.indexOf(
                                            establishement._id,
                                        ) !== -1
                                    ) {
                                        newVals.splice(
                                            belongsTo.indexOf(
                                                establishement._id,
                                            ),
                                            1,
                                        );
                                    } else {
                                        newVals.push(establishement._id);
                                    }
                                    onChange("belongsTo", newVals);
                                }}
                            />
                        </div>
                        <div
                            className="flex items-center"
                            style={{
                                opacity: belongsTo.includes(establishement._id)
                                    ? "1"
                                    : "0.5",
                            }}
                        >
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            <label>
                                {getCheckLabel(
                                    intl.formatMessage({
                                        id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                    }),
                                    planning.includes(establishement._id),
                                )}
                            </label>
                        </div>
                        {multiplePlanning &&
                            establishement?.departments?.length > 0 && (
                                <div
                                    className={`${direction === "row"
                                        ? "ml-auto"
                                        : "hidden"
                                        }`}
                                >
                                    <SelectInput
                                        title=""
                                        options={establishement?.departments?.map(
                                            (dept: any) => ({
                                                label: dept.name,
                                                value: dept._id,
                                            }),
                                        )}
                                        isMultiple={true}
                                        selectedValue={selectedDepartments(
                                            establishement,
                                        )}
                                        onSelectOption={async (
                                            department: any,
                                        ) => {
                                            let updatedDept: any[] = [];
                                            if (selectedDepartment) {
                                                updatedDept = [
                                                    ...selectedDepartment?.filter(
                                                        (dept: any) =>
                                                            dept.site !==
                                                            establishement._id,
                                                    ),
                                                ];
                                            }
                                            updatedDept = [
                                                ...updatedDept,
                                                {
                                                    site: establishement._id,
                                                    depts: department.map(
                                                        (dept: any) =>
                                                            dept.value,
                                                    ),
                                                },
                                            ].filter(
                                                (dept: any) =>
                                                    dept.depts?.length > 0,
                                            );
                                            // await setSelectedDept(updatedDept);

                                            onChange(
                                                "departements",
                                                updatedDept,
                                            );
                                        }}
                                        placeholder={intl.formatMessage({
                                            id: "MENU.DEPARTEMENTS",
                                        })}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            ));
        } else if (role === "Manager") {
            return establishements.map((establishement: any, key: number) => (
                <div
                    key={key}
                    style={{
                        borderWidth: "1px",
                        borderRadius: "0.5rem",
                        marginTop: "0.75rem",
                        marginBottom: "0.75rem",
                    }}
                    className={`${belongsTo.includes(establishement._id)
                        ? "border-t-100"
                        : "border-[#bec2d7]"
                        } `}
                >
                    <div
                        key={key}
                        style={{
                            display: "flex",
                            padding: "1rem",
                            gap: "1.25rem",
                        }}
                        className={`${direction === "row" ? "" : "flex-col"}`}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                            className={`${direction === "row" ? "min-w-[100px]" : "w-full"
                                } `}
                        >
                            <ZToggleButton
                                leftComponent={getTogglLabel(
                                    establishement.name,
                                    belongsTo.includes(establishement._id),
                                )}
                                isChecked={belongsTo.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    let newVals = [...belongsTo];
                                    if (
                                        belongsTo.indexOf(
                                            establishement._id,
                                        ) !== -1
                                    ) {
                                        newVals.splice(
                                            belongsTo.indexOf(
                                                establishement._id,
                                            ),
                                            1,
                                        );
                                        onChange("belongsTo", newVals);
                                    } else {
                                        newVals.push(establishement._id);
                                        onChange("belongsTo", newVals);
                                    }
                                }}
                            />
                        </div>
                        {multiplePlanning &&
                            establishement?.departments?.length > 0 && (
                                <div
                                    className={`${direction === "row"
                                        ? "ml-auto"
                                        : "hidden"
                                        }`}
                                >
                                    <SelectInput
                                        style={{
                                            padding: "0",
                                            height: "40px",
                                        }}
                                        isMultiple={true}
                                        title=""
                                        options={establishement?.departments?.map(
                                            (dept: any) => ({
                                                label: dept.name,
                                                value: dept._id,
                                            }),
                                        )}
                                        selectedValue={selectedDepartments(
                                            establishement,
                                        )}
                                        onSelectOption={async (
                                            department: any,
                                        ) => {
                                            let updatedDept: any[] = [];
                                            if (selectedDepartment) {
                                                updatedDept = [
                                                    ...selectedDepartment?.filter(
                                                        (dept: any) =>
                                                            dept.site !==
                                                            establishement._id,
                                                    ),
                                                ];
                                            }
                                            updatedDept = [
                                                ...updatedDept,
                                                {
                                                    site: establishement._id,
                                                    depts: department.map(
                                                        (dept: any) =>
                                                            dept.value,
                                                    ),
                                                },
                                            ].filter(
                                                (dept: any) =>
                                                    dept.depts?.length > 0,
                                            );
                                            // await setSelectedDept(updatedDept);

                                            onChange(
                                                "departements",
                                                updatedDept,
                                            );
                                        }}
                                        // onChange={() => {}}
                                        menuPlacement="auto"
                                        placeholder={intl.formatMessage({
                                            id: "MENU.DEPARTEMENTS",
                                        })}
                                    />
                                </div>
                            )}
                    </div>
                    {/** this part is for access management for Manager Role */}

                    <div
                        className={`py-2 mx-4 border-t ${belongsTo.includes(establishement._id)
                            ? "border-t-100"
                            : "border-n-0"
                            }  flex   flex-wrap ${direction === "col"
                                ? "flex-col"
                                : "justify-between items-center"
                            }`}
                        style={{
                            opacity: belongsTo.includes(establishement._id)
                                ? "1"
                                : "0.5",
                        }}
                    >
                        <div className="flex items-center">
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        console.log(newVals);
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            <label>
                                {getCheckLabel(
                                    intl.formatMessage({
                                        id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                    }),
                                    planning.includes(establishement._id),
                                )}
                            </label>
                        </div>
                        {managePages.map((page, key) => (
                            <div
                                key={key}
                                className={`flex items-center flex-wrap ${direction === "row" && "justify-between"
                                    }`}
                            >
                                <ZCheckBox
                                    isChecked={isEstablishementRolePicked(
                                        establishement._id,
                                        page.value,
                                    )}
                                    onPress={() => {
                                        if (
                                            belongsTo.includes(
                                                establishement._id,
                                            )
                                        ) {
                                            checkManage(
                                                establishement._id,
                                                page.value,
                                            );
                                        }
                                    }}
                                />
                                <label>
                                    {getCheckLabel(page.label, false)}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            ));
        } else if (role === "Employee") {
            return establishements.map((establishement: any, key: number) => (
                <div
                    key={key}
                    style={{
                        borderWidth: "1px",
                        borderRadius: "0.5rem",
                        marginTop: "0.75rem",
                        marginBottom: "0.75rem",
                    }}
                    className={`${belongsTo.includes(establishement._id)
                        ? "border-t-100"
                        : "border-[#bec2d7]"
                        } `}
                >
                    <div
                        key={key}
                        style={{
                            display: "flex",
                            padding: "1rem",
                            gap: "1.25rem",
                        }}
                        className={`${direction === "row" ? "" : "flex-col"}`}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                            className={`${direction === "row" ? "min-w-[100px]" : "w-full"
                                } `}
                        >
                            <ZToggleButton
                                leftComponent={getTogglLabel(
                                    establishement.name,
                                    belongsTo.includes(establishement._id),
                                )}
                                isChecked={belongsTo.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    let newVals = [...belongsTo];
                                    if (
                                        belongsTo.indexOf(
                                            establishement._id,
                                        ) !== -1
                                    ) {
                                        newVals.splice(
                                            belongsTo.indexOf(
                                                establishement._id,
                                            ),
                                            1,
                                        );
                                    } else {
                                        newVals.push(establishement._id);
                                    }
                                    onChange("belongsTo", newVals);
                                }}
                            />
                        </div>

                        <div
                            className="flex items-center"
                            style={{
                                opacity: belongsTo.includes(establishement._id)
                                    ? "1"
                                    : "0.5",
                            }}
                        >
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            <label>
                                {getCheckLabel(
                                    intl.formatMessage({
                                        id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                    }),
                                    planning.includes(establishement._id),
                                )}
                            </label>
                        </div>
                        {multiplePlanning &&
                            establishement?.departments?.length > 0 && (
                                <div
                                    className={`${direction === "row"
                                        ? "ml-auto"
                                        : "hidden"
                                        }`}
                                >
                                    <SelectInput
                                        title=""
                                        options={establishement?.departments?.map(
                                            (dept: any) => ({
                                                label: dept.name,
                                                value: dept._id,
                                            }),
                                        )}
                                        isMultiple={true}
                                        selectedValue={selectedDepartments(
                                            establishement,
                                        )}
                                        onSelectOption={async (
                                            department: any,
                                        ) => {
                                            let updatedDept: any[] = [];
                                            if (selectedDepartment) {
                                                updatedDept = [
                                                    ...selectedDepartment?.filter(
                                                        (dept: any) =>
                                                            dept.site !==
                                                            establishement._id,
                                                    ),
                                                ];
                                            }
                                            updatedDept = [
                                                ...updatedDept,
                                                {
                                                    site: establishement._id,
                                                    depts: department.map(
                                                        (dept: any) =>
                                                            dept.value,
                                                    ),
                                                },
                                            ].filter(
                                                (dept: any) =>
                                                    dept.depts?.length > 0,
                                            );
                                            // await setSelectedDept(updatedDept);

                                            onChange(
                                                "departements",
                                                updatedDept,
                                            );
                                        }}
                                        placeholder={intl.formatMessage({
                                            id: "MENU.DEPARTEMENTS",
                                        })}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            ));
        }

        return establishements.map((establishement: any, key: number) => (
            <div
                key={key}
                style={{
                    borderWidth: "1px",
                    borderRadius: "0.5rem",
                    marginTop: "0.75rem",
                    marginBottom: "0.75rem",
                }}
                className={`${belongsTo.includes(establishement._id)
                    ? "border-t-100"
                    : "border-n-0"
                    } `}
            >
                <div
                    key={key}
                    style={{
                        display: "flex",
                        padding: "1rem",
                        gap: "1.25rem",
                    }}
                    className={`${direction === "row" ? "" : "flex-col"}`}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                        }}
                        className={`${direction === "row" ? "min-w-[100px]" : "w-full"
                            } `}
                    >
                        <ZToggleButton
                            leftComponent={getTogglLabel(
                                establishement.name,
                                belongsTo.includes(establishement._id),
                            )}
                            isChecked={belongsTo.includes(establishement._id)}
                            onPress={() => {
                                let newVals = [...belongsTo];
                                if (
                                    belongsTo.indexOf(establishement._id) !== -1
                                ) {
                                    newVals.splice(
                                        belongsTo.indexOf(establishement._id),
                                        1,
                                    );
                                } else {
                                    newVals.push(establishement._id);
                                }
                                onChange("belongsTo", newVals);
                            }}
                        />
                    </div>
                    {role !== "Manager" && (
                        <div className="flex items-center">
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            <label>
                                {getCheckLabel(
                                    intl.formatMessage({
                                        id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                    }),
                                    planning.includes(establishement._id),
                                )}
                            </label>
                        </div>
                    )}
                    {role !== "Administrator" &&
                        multiplePlanning &&
                        departements?.length > 0 && (
                            <div
                                className={`${direction === "row" ? "ml-auto" : "hidden"
                                    }`}
                            >
                                <SelectInput
                                    title=""
                                    options={departements}
                                    onSelectOption={() => { }}
                                    placeholder={intl.formatMessage({
                                        id: "MENU.DEPARTEMENTS",
                                    })}
                                />
                            </div>
                        )}
                </div>

                {/** this part is for access management for Manager Role */}
                {role === "Manager" && (
                    <div
                        className={`py-2 mx-4 border-t ${belongsTo.includes(establishement._id)
                            ? "border-t-100"
                            : "border-n-0"
                            }  flex   flex-wrap ${direction === "col"
                                ? "flex-col"
                                : "justify-between items-center"
                            }`}
                    >
                        <div className="flex items-center">
                            <ZCheckBox
                                isChecked={planning.includes(
                                    establishement._id,
                                )}
                                onPress={() => {
                                    if (
                                        belongsTo.includes(establishement._id)
                                    ) {
                                        let newVals = [...planning];
                                        if (
                                            planning.indexOf(
                                                establishement._id,
                                            ) !== -1
                                        ) {
                                            newVals.splice(
                                                planning.indexOf(
                                                    establishement._id,
                                                ),
                                                1,
                                            );
                                        } else {
                                            newVals.push(establishement._id);
                                        }
                                        onChange("planning", newVals);
                                    }
                                }}
                            />
                            <label>
                                {getCheckLabel(
                                    intl.formatMessage({
                                        id: "EMPLOYEE.ADD.LABEL.SHOW_IN_PLANNING",
                                    }),
                                    planning.includes(establishement._id),
                                )}
                            </label>
                        </div>
                        {managePages.map((page, key) => (
                            <div
                                key={key}
                                className={`flex items-center flex-wrap ${direction === "row" && "justify-between"
                                    }`}
                            >
                                <ZCheckBox
                                    isChecked={isEstablishementRolePicked(
                                        establishement._id,
                                        page.value,
                                    )}
                                    onPress={() => {
                                        if (
                                            belongsTo.includes(
                                                establishement._id,
                                            )
                                        ) {
                                            checkManage(
                                                establishement._id,
                                                page.value,
                                            );
                                        }
                                    }}
                                />
                                <label>
                                    {getCheckLabel(page.label, false)}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ));
    });
    return (
        <div>
            <span
                className={`inline-block  mb-5 font-bold text-lg ${direction === "row" ? "text-v-800" : "text-n-700"
                    }`}
            >
                {title}
            </span>
            {branchsLabeled?.length > 5 ? (
                <div
                    // className={`${direction === "row" ? "" : "flex gap-4"}`}
                    style={{
                        // overflowX: "auto",
                        backgroundColor: "#a6d3e2",
                        padding: "10px 30px 5px",
                        borderRadius: "5px",
                    }}
                // className="container-eta"
                >
                    {/* <Slider {...settings}> */}
                    <ManageAccessSites establishements={branchsLabeled} />
                    {/* </Slider> */}
                </div>
            ) : (
                <div
                    className={`${direction === "row" ? "" : "flex gap-4"}`}
                // style={{ overflow: "auto" }}
                >
                    <ManageAccessSitesWithoutSlider
                        establishements={branchsLabeled}
                    />
                </div>
            )}
        </div>
    );
}

interface EstablishementPickerInterface {
    pickerType?: "create" | "edit";
    direction?: "row" | "col";
    title: string;
    establishements: { name: string; _id: string }[];
    onChange: (field: string, value: any) => void;
    manage: any[];
    belongsTo: any[];
    planning: any[];
    selectedDepartment?: any[];
    role: "Employee" | "Manager" | "Administrator";
    departements: any[];
    multiplePlanning: boolean;
}

EstablishementPicker.defaultValues = {
    pickerType: "edit",
};

export default EstablishementPicker;
