import * as React from "react";
import { extractNumber } from "../../../_helpers/Functions";
import Button from "../../../components/atoms/Button";
import ColoredCircle from "../../../components/atoms/ColoredCircle";
import Input from "../../../components/atoms/Input";
import Ticket from "../../../components/atoms/Ticket";
import ToggleButton from "../../../components/atoms/Toggle";
import CustomOrderCard from "../../../components/organism/CustomOrderCard";
import NewTicketCard from "../../../components/organism/NewTicketCard";
import { useBranch } from "../../../hooks/useBranch";
import { useMobile } from "../../../hooks/useMobile";
import TicketsList from "../Elements/ScheduleElements/TicketsList";
import SearchSVG from "./search.svg";
import img1 from "./pictures/1.png";
import img2 from "./pictures/2.png";
import img3 from "./pictures/3.png";
import img4 from "./pictures/4.png";
import img6 from "./pictures/6.png";

import "../styles/vacation.style.css";
import TextData from "../../../components/atoms/TextData";
import DraggableOrderContainer from "../../../components/molecules/DraggableOrderContainer";
import Modal from "../../../components/atoms/Modal";
import PopUpCard from "../../../components/molecules/PopUpCard";
import Checkbox from "../../../components/atoms/Checkbox";
import Picture from "../../../components/atoms/Picture";
import { useVacation } from "../../../hooks/useVacation";
import { useVacationRule } from "../../../hooks/useVacationRule";
import { useEmployees } from "../../../hooks/useEmployees";
import { API, ROUTER } from "../../../constants/env";
import Swal from "sweetalert2";
import Loading from "../../../components/atoms/Loading";

function VacationList(props: any) {
    const { vacations, selectedVacation } = props;
    const {
        getAllVacations,
        loadVacationToUpdate,
        setDesignationToUpdate,
        setColorToUpdate,
        updateSelectedVacation,
        deleteSelectedVacation,
        setDesignationToCreate,
        setColorToCreate,
        submitNewVacation,
    } = useVacation();
    const data = vacations.map((vacation: any) => {
        return {
            id: vacation._id,
            name: vacation.designation,
            backgroundColor: vacation.color,
        };
    });
    const [selectedConge, setSelectedConge] = React.useState(selectedVacation);
    const listOfColors = () => {
        let colors = [
            "#1abc9c",
            "#16a085",
            "#2ecc71",
            "#27ae60",
            "#3498db",
            "#2980b9",
            "#9b59b6",
            "#8e44ad",
            "#34495e",
            "#2c3e50",
            "#f1c40f",
            "#f39c12",
            "#e67e22",
            "#d35400",
            "#e74c3c",
            "#c0392b",
            "#ecf0f1",
            "#bdc3c7",
            "#95a5a6",
            "#7f8c8d",
        ];
        colors = [
            ...colors,
            ...vacations.map((vacation: any) => vacation.color),
        ];
        return colors;
    };
    React.useEffect(() => {
        if (selectedVacation) {
            setSelectedConge(selectedVacation);
        }
    }, [selectedVacation]);

    const [updateCongeTypeStatus, setUpdateCongeTypeStatus] =
        React.useState(false);
    const handleUpdateConge = (id: any) => {
        console.log("🚀 handleUpdateTicket ~ id:", id);
        loadVacationToUpdate(id);
        setUpdateCongeTypeStatus(true);
    };
    const handleUpdateCongeDesignation = (designation: any) => {
        setDesignationToUpdate(designation);
    };
    const handleUpdateCongeColor = (color: any) => {
        setColorToUpdate(color);
    };

    const handleSubmitUpdatedCongeType = () => {
        console.log("Editer conge type");
        updateSelectedVacation();
        setUpdateCongeTypeStatus(false);
    };
    const handleCancelUpdatedCongeType = () => {
        setUpdateCongeTypeStatus(false);
    };

    const handleDeleteConge = async (id: any) => {
        console.log("before loading", id);
        await loadVacationToUpdate(id);
        console.log("after loading", id);
        console.log("after deleting", id);
        Swal.fire({
            title: "Êtes-vous certain(e) de vouloir supprimer ce type de congé ?",
            color: "#2A8BAB",
            showCancelButton: true,
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
            cancelButtonText: "Non",
            cancelButtonColor: "#EDEFF5",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteSelectedVacation();
            } else if (result.isDismissed) {
                Swal.fire({
                    title: "Cette opération a été annulée",
                    color: "#2A8BAB",
                    text: "",
                    icon: "info",
                });
            }
            setUpdateCongeTypeStatus(false);
        });
    };

    const [createCongeTypeStatus, setCreateCongeTypeStatus] =
        React.useState(false);
    const handleCreateCongeDesignation = (designation: any) => {
        setDesignationToCreate(designation);
    };
    const handleCreateCongeColor = (color: any) => {
        setColorToCreate(color);
    };
    const handleCreateNewCongeType = () => {
        submitNewVacation();
        setCreateCongeTypeStatus(false);
    };
    const handleCancelNewCongeType = () => {
        setCreateCongeTypeStatus(false);
    };

    return (
        <div className="vacation-types-container">
            <TicketsList
                tickets={data}
                handleTicketEdit={handleUpdateConge}
                handleTicketDelete={handleDeleteConge}
                isCreatePopup={createCongeTypeStatus}
                isUpdatePopup={updateCongeTypeStatus}
                setIsCreatePopup={setCreateCongeTypeStatus}
                setIsUpdatePopup={setUpdateCongeTypeStatus}
                createPopupCard={
                    <NewTicketCard
                        header="Ajouter un type de congés"
                        inputOnChange={handleCreateCongeDesignation}
                        onSelectionColor={handleCreateCongeColor}
                        onSubmitButton={handleCreateNewCongeType}
                        onClose={handleCancelNewCongeType}
                        onCancelButton={handleCancelNewCongeType}
                        colors={listOfColors()}
                        displayColorsCount={listOfColors()?.length}
                    />
                }
                UpdatePopupCard={
                    <NewTicketCard
                        header="Editer le type de congés"
                        inputValue={selectedConge.designation}
                        selectionColor={selectedConge.color}
                        inputOnChange={handleUpdateCongeDesignation}
                        onSelectionColor={handleUpdateCongeColor}
                        onSubmitButton={handleSubmitUpdatedCongeType}
                        button2Text="Modifier"
                        button1Text="Supprimer"
                        button1Style={{
                            backgroundColor: "red",
                            color: "white",
                        }}
                        onClose={handleCancelUpdatedCongeType}
                        onCancelButton={async () =>
                            await handleDeleteConge(selectedConge?._id)
                        }
                        colors={listOfColors()}
                        displayColorsCount={listOfColors()?.length}
                    />
                }
            />
        </div>
    );
}
function VacationPolitics(props: any) {
    const { rulesList, fetchVacationParams } = props;
    const {
        rules,
        loadVacationRules,
        loadRule,
        ruleOperation,
        setRuleDesignation,
        setRuleBaseWorkDays,
        setRuleBaseEntitlement,
        setRuleRestrictedBypassLimitDays,
        updateRule,
        deleteRule,
        loadAffectationRule,
        setRuleUsers,
        assignUsersToRule,
        resetRuleOperation,
    } = useVacationRule();

    const { getEmployees, employees, onUpdateFilterBy, filterBy } =
        useEmployees();
    const { selectedBranchSettings } = useBranch();

    const [users, setUsers] = React.useState<any>([]);
    const [byPassEnabled, setByPassEnabled] = React.useState(false);

    const [displayAffectation, setDisplayAffectation] = React.useState(false);
    const [displayEdit, setDisplayEdit] = React.useState(false);
    const [isAllSelected, setIsAllSelected] = React.useState(false);

    React.useEffect(() => {
        console.log("React.useEffect");
        loadVacationRules();
        getEmployees({ archived: false });
    }, []);

    // React.useEffect(() => {
    //     if (employees && ruleOperation) {
    //         setUsers(
    //             employees
    //                 .map((emp) => {
    //                     if (
    //                         ruleOperation.users &&
    //                         ruleOperation.users.includes(emp._id)
    //                     ) {
    //                         return {
    //                             _id: emp._id,
    //                             fname: emp.fname,
    //                             lname: emp.lname,
    //                             picture: ROUTER.STATIC_IMG(emp.picture),
    //                             checked: true,
    //                         };
    //                     } else {
    //                         return {
    //                             _id: emp._id,
    //                             fname: emp.fname,
    //                             lname: emp.lname,
    //                             picture: ROUTER.STATIC_IMG(emp.picture),
    //                             checked: false,
    //                         };
    //                     }
    //                 })
    //                 .sort((a, b) => {
    //                     // Sort by "checked" property (true values come first)
    //                     if (a.checked && !b.checked) return -1;
    //                     if (!a.checked && b.checked) return 1;

    //                     // If "checked" values are the same, sort alphabetically by "fname" and "lname"
    //                     const aName = `${a.fname} ${a.lname}`;
    //                     const bName = `${b.fname} ${b.lname}`;

    //                     return aName.localeCompare(bName);
    //                 }) ?? [],
    //         );
    //     }
    // }, [employees, ruleOperation]);

    React.useEffect(() => {
        if (ruleOperation) {
            setByPassEnabled(ruleOperation.restrictedBypassLimitDays > 0);
        }
    }, [ruleOperation]);

    React.useEffect(() => {
        if (isAllSelected) {
            setUsers(
                users.map((user: any) => {
                    return {
                        ...user,
                        checked: isAllSelected,
                    };
                }),
            );
        }
    }, [isAllSelected]);

    React.useEffect(() => {
        if (ruleOperation) {
            if (byPassEnabled === false) {
                setRuleRestrictedBypassLimitDays(-1);
            } else {
                const selectedRule = rules.find(
                    (rule: any) => rule._id === ruleOperation._id,
                );
                if (selectedRule) {
                    console.log("selectedRule ", selectedRule);
                    setRuleRestrictedBypassLimitDays(
                        selectedRule.restrictedBypassLimitDays,
                    );
                }
            }
        }
    }, [byPassEnabled]);
    const [selectedUsersRule, setSelectedUsersRule] = React.useState<any>([]);

    const handleSelectAllAffectationUser = () => {
        if (!isAllSelected) {
            setSelectedUsersRule(users.map((user: any) => user._id));
        } else {
            setSelectedUsersRule([]);
        }
        setIsAllSelected(!isAllSelected);
    };
    const handleSelectAffectationUser = async (user: any) => {
        console.log("USER Affectation", users);
        console.log("USER", user);
        if (!user.checked) {
            await setSelectedUsersRule((prev: any) => [...prev, user._id]);
        } else {
            const rs = selectedUsersRule.filter((userID: string) => {
                console.log("userID ", userID);
                console.log("user._id ", user._id);
                console.log("userID != user._id >> ", userID != user._id);
                return userID != user._id;
            });
            await setSelectedUsersRule((prev: any) => rs);
        }
        await setUsers(
            users.map((u: any) => {
                if (u._id == user._id) {
                    u.checked = !u.checked;
                }
                return u;
            }),
        );
    };
    const [selectedBarioloRule, setSelectedBarioloRule] = React.useState<any>(
        {},
    );
    const handleSubmitAffectationComponent = async () => {
        console.log("selectedBarioloRule::", selectedBarioloRule);
        const result = await fetch(
            API.client.barioloVacation.rule(selectedBarioloRule?._id),
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                    employees: selectedUsersRule,
                }),
            },
        );
        if (result && result.status === 200) {
            let data = await result.json();
            console.log("data==> \n", data);
            fetchVacationParams && fetchVacationParams();
        }
        await setDisplayAffectation(false);
    };
    const handleCloseAffectationComponent = () => {
        setDisplayAffectation(false);
        setIsAllSelected(false);
    };
    const handleSearchUsers = (text: string) => {
        onUpdateFilterBy({
            ...filterBy,
            name: text,
        });
    };

    const fetchAffectationEmployees = async (ruleID: any, siteID: any) => {
        console.log("fetchAffectationEmployees");
        const response = await fetch(
            API.client.barioloVacation.affectation(ruleID, siteID),
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            },
        );
        if (response.ok === true) {
            let result = await response.json();
            setUsers(result);
            setSelectedUsersRule(
                result
                    .filter((emp: any) => emp.checked)
                    .map((emp: any) => emp._id),
            );
            console.log("result::: ", result);
        }
    };
    React.useEffect(() => {
        console.log("displayAffectation::", displayAffectation);
        console.log("selectedBarioloRule?._id::", selectedBarioloRule);
        console.log(
            "selectedBranchSettings?._id::",
            selectedBranchSettings?._id,
        );
        if (
            displayAffectation &&
            selectedBarioloRule?._id &&
            selectedBranchSettings?._id
        ) {
            fetchAffectationEmployees(
                selectedBarioloRule?._id,
                selectedBranchSettings?._id,
            );
        }
    }, [displayAffectation, selectedBarioloRule]);

    const AffectationComponent = () => {
        return (
            <Modal
                modalEnabled={displayAffectation}
                onClose={handleCloseAffectationComponent}
            >
                <PopUpCard
                    display={displayAffectation}
                    onClose={handleCloseAffectationComponent}
                    header="Selectionner les employees"
                    widthCloseIcon="24px"
                    containerWidth="480px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleCloseAffectationComponent}
                    button2Text="Valider"
                    onClickButton2={handleSubmitAffectationComponent}
                >
                    <div className="employees-section">
                        <div className="new-dept-search-bar">
                            <Input
                                captionText={null}
                                borderWidth={0}
                                iconImg={SearchSVG}
                                iconLocation="left"
                                placeholder="Rechercher"
                                inputWidth="70%"
                                style={{
                                    inputStyle: {
                                        backgroundColor: "transparent",
                                        outline: "none",
                                        width: "100%",
                                        padding: "7px 3px 7px 25px",
                                        color: "#8E96C1",
                                    },
                                }}
                                onChange={handleSearchUsers}
                            />
                            <TextData
                                style={{
                                    marginRight: 5,
                                }}
                                width="auto"
                                size="12"
                                variant="regular"
                                fontWeight="600"
                            >
                                Tous
                            </TextData>
                            <Checkbox
                                onClick={handleSelectAllAffectationUser}
                                id="all"
                                text={null}
                                rounded={true}
                                checked={isAllSelected}
                            />
                        </div>
                        <div className="new-dept-list-of-employees">
                            {users &&
                                users.map((user: any) => {
                                    return (
                                        <div
                                            key={user._id}
                                            className="employee"
                                        >
                                            <div className="information">
                                                <Picture
                                                    imgPath={ROUTER.STATIC_IMG(
                                                        user.picture,
                                                    )}
                                                    className={["rounded-full"]}
                                                    width="40px"
                                                    height="40px"
                                                />
                                                <TextData
                                                    variant="label"
                                                    width="auto"
                                                    size="14px"
                                                    fontWeight="bolder"
                                                    color="#101844"
                                                >
                                                    {`${user.fname} ${user.lname}`}
                                                </TextData>
                                            </div>
                                            <div className="employee-selection">
                                                <Checkbox
                                                    id={user._id}
                                                    text={null}
                                                    rounded={true}
                                                    checked={
                                                        user.checked || false
                                                    }
                                                    onClick={() =>
                                                        handleSelectAffectationUser(
                                                            user,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </PopUpCard>
            </Modal>
        );
    };

    const handleSubmitEdit = () => {
        updateRule();
        loadVacationRules();
        setDisplayEdit(false);
    };
    const handleSubmitBarioloEdit = async () => {
        const result = await fetch(
            API.client.barioloVacation.rule(selectedBarioloRule?._id),
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                    activateLimit: selectedBarioloRule?.activateLimit,
                    baseEntitlement: selectedBarioloRule?.baseEntitlement,
                    baseWorkDays: selectedBarioloRule?.baseWorkDays,
                    designation: selectedBarioloRule?.designation,
                    limitDays: selectedBarioloRule?.limitDays,
                }),
            },
        );
        if (result && result.status === 200) {
            let data = await result.json();
            console.log("data==> \n", data);
            fetchVacationParams && fetchVacationParams();
        }
        //add fetch for update specific rule
        setDisplayEdit(false);
    };

    const handleCancelEdit = () => {
        setDisplayEdit(false);
    };

    const handleActivationByPasslimit = () => {
        setSelectedBarioloRule((prev: any) => {
            return {
                ...prev,
                activateLimit: !prev.activateLimit,
            };
        });
    };
    const MonthlyLimitActivation = (props: any) => {
        const { activateLimit, limitDays } = props;
        if (activateLimit) {
            return (
                <>
                    <Checkbox
                        text="Activer la limite mensuelle"
                        checked={activateLimit}
                        onClick={handleActivationByPasslimit}
                        textSize="14px"
                        textColor="#2A346D"
                    />
                    <p>
                        Le nombre maximal de jours de travail par mois est{" "}
                        <input
                            type="number"
                            min={0}
                            style={{
                                display: "inline",
                                width: "40px",
                                border: "1px solid #ecf0f1",
                                borderRadius: "5px",
                                color: "#2A346D",
                            }}
                            value={limitDays || 0}
                            onChange={(e) => {
                                setSelectedBarioloRule((prev: any) => {
                                    return {
                                        ...prev,
                                        limitDays: parseFloat(e.target.value),
                                    };
                                });
                            }}
                        />{" "}
                        jour(s)
                    </p>
                </>
            );
        } else {
            return (
                <Checkbox
                    text="Activer la limite mensuelle"
                    checked={activateLimit}
                    onClick={handleActivationByPasslimit}
                    textSize="14px"
                    textColor="#2A346D"
                />
            );
        }
    };
    const EditComponent = () => {
        return (
            <Modal
                modalEnabled={displayEdit}
                onClose={() => setDisplayEdit(false)}
            >
                <PopUpCard
                    display={displayEdit}
                    onClose={() => setDisplayEdit(false)}
                    header="Modifier la régle"
                    widthCloseIcon="24px"
                    containerWidth="480px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleCancelEdit}
                    button2Text="Valider"
                    onClickButton2={handleSubmitBarioloEdit}
                >
                    {!selectedBarioloRule ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="new-conge-rule-container">
                                <Input
                                    captionText="Désignation"
                                    captionColor="#8E96C1"
                                    captionSize="16px"
                                    borderColor="#C7CBE2"
                                    borderWidth={1}
                                    textSize={14}
                                    textColor="#2A346D"
                                    inputRadius="4px"
                                    placeholder=""
                                    inputWidth="100%"
                                    onChange={(data: any) => {
                                        console.log(selectedBarioloRule);
                                        setSelectedBarioloRule((prev: any) => {
                                            return {
                                                ...prev,
                                                designation: data,
                                            };
                                        });
                                    }}
                                    value={
                                        selectedBarioloRule?.designation || ""
                                    }
                                />
                                <p>
                                    Le collaborateur bénéficie de{" "}
                                    <input
                                        type="number"
                                        style={{
                                            display: "inline",
                                            width: "50px",
                                            border: "1px solid #ecf0f1",
                                            borderRadius: "5px",
                                            color: "#2A346D",
                                        }}
                                        step={0.25}
                                        value={
                                            selectedBarioloRule?.baseEntitlement ||
                                            0
                                        }
                                        onChange={(e: any) => {
                                            setSelectedBarioloRule(
                                                (prev: any) => {
                                                    return {
                                                        ...prev,
                                                        baseEntitlement:
                                                            parseFloat(
                                                                e.target.value,
                                                            ),
                                                    };
                                                },
                                            );
                                        }}
                                    />{" "}
                                    jour(s) de congé pour chaque période de
                                    travail de{" "}
                                    <input
                                        type="number"
                                        style={{
                                            display: "inline",
                                            width: "50px",
                                            border: "1px solid #ecf0f1",
                                            borderRadius: "5px",
                                            color: "#2A346D",
                                        }}
                                        step={0.25}
                                        value={
                                            selectedBarioloRule?.baseWorkDays ||
                                            0
                                        }
                                        onChange={(e: any) => {
                                            setSelectedBarioloRule(
                                                (prev: any) => {
                                                    return {
                                                        ...prev,
                                                        baseWorkDays:
                                                            parseFloat(
                                                                e.target.value,
                                                            ),
                                                    };
                                                },
                                            );
                                        }}
                                    />{" "}
                                    jour(s)
                                </p>
                            </div>
                            <div className="new-conge-rule-container">
                                {MonthlyLimitActivation(selectedBarioloRule)}
                            </div>
                        </>
                    )}
                </PopUpCard>
            </Modal>
        );
    };
    // const handleDeleteRule = async (ruleID: any) => {
    //     deleteRule(ruleID);
    //     console.log("🚀 handleDeleteRule ~ rule:", ruleID);
    // };
    // const handleUpdateRule = (ruleID: any) => {
    //     loadRule(ruleID);
    //     setDisplayEdit(true);
    // };
    // const handleAffectRule = (ruleID: any) => {
    //     console.log("handleAffectRule");
    //     loadAffectationRule(ruleID);
    //     setDisplayAffectation(true);
    // };
    const handleDeleteBarioloRule = async (rule: any) => {
        console.log("🚀 handleDeleteRule ~ rule:", rule);
        const result = await Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Oui, supprimer !",
            cancelButtonText: "Annuler",
        });

        if (result.isConfirmed) {
            // User clicked "Yes"
            try {
                const response = await fetch(
                    API.client.barioloVacation.rule(rule._id),
                    {
                        method: "DELETE",
                    },
                );

                if (response.ok) {
                    fetchVacationParams && fetchVacationParams();
                    Swal.fire(
                        "Supprimé !",
                        "La politique de congés a été supprimé",
                        "success",
                    );
                } else {
                    Swal.fire(
                        "Erreur !",
                        "Une erreur s'est produite lors de la suppression.",
                        "error",
                    );
                }
            } catch (error) {
                Swal.fire("Erreur !", "Échec de la suppression", "error");
            }
        }
    };
    const handleUpdateBarioloRule = async (rule: any) => {
        await setSelectedBarioloRule(rule);
        setDisplayEdit(true);
        console.log("🚀 handleUpdateRule ~ rule:", rule);
    };
    const handleAffectBarioloRule = async (rule: any) => {
        console.log("handleAffectRule : ", rule);
        await setSelectedBarioloRule(rule);
        setDisplayAffectation(true);
    };
    if (rulesList && rulesList?.length > 0) {
        return (
            <div className="vacation-politic-container">
                {displayAffectation && AffectationComponent()}
                {displayEdit && EditComponent()}
                {rulesList?.map((rule: any, indx: any) => {
                    const {
                        _id,
                        activateLimit,
                        baseEntitlement,
                        baseWorkDays,
                        designation,
                        employees,
                        limitDays,
                    } = rule;
                    return (
                        <div className="politic-box-container">
                            <div className="left">
                                <ColoredCircle
                                    name="test"
                                    color="#2A8BAB"
                                    outlineColor="#2A8BAB99"
                                    size={10}
                                    outlineSize={4}
                                />
                                <p>
                                    Politique <b>{designation}</b> : L'employé a
                                    droit à <b>{baseEntitlement}</b> jour(s) de
                                    congé pour chaque période de travail de{" "}
                                    <b>{baseWorkDays}</b> jour(s).
                                    {activateLimit && limitDays && limitDays > 0
                                        ? `[Max. ${limitDays} jour(s)]`
                                        : ""}
                                </p>
                            </div>
                            <div className="right">
                                <Button
                                    style={{
                                        backgroundColor: "#2A8BAB",
                                        color: "white",
                                        fontSize: "14px",
                                    }}
                                    backgroundColor="#2A8BAB"
                                    textColor="white"
                                    width="100px"
                                    height="30px"
                                    onClick={() =>
                                        handleUpdateBarioloRule(rule)
                                    }
                                >
                                    Editer
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#EDEFF5",
                                        color: "#2A346D",
                                        fontSize: "14px",
                                    }}
                                    backgroundColor="#EDEFF5"
                                    textColor="#2A346D"
                                    width="100px"
                                    height="30px"
                                    onClick={() =>
                                        handleDeleteBarioloRule(rule)
                                    }
                                >
                                    Supprimer
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#2A8BAB",
                                        color: "white",
                                        fontSize: "14px",
                                    }}
                                    backgroundColor="#5BB3D0"
                                    textColor="white"
                                    width="100px"
                                    height="30px"
                                    onClick={() =>
                                        handleAffectBarioloRule(rule)
                                    }
                                >
                                    Affecter
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return (
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                    textAlign: "center",
                }}
            >
                <h2 style={{ color: "#2a346d", fontWeight: "500" }}>
                    Aucune règle spécifique a été définie pour l'affectation des
                    soldes de congés
                </h2>
            </div>
        );
    }
}
export const VacationBarioloProps = () => {
    // const {
    //     vacations,
    //     selectedVacation,
    //     vacationSetting,
    //     getVacationSettings,
    //     updateVacationSettings,
    // } = useVacation();

    const { selectedBranchSettings } = useBranch();
    // const {
    //     ruleOperation,
    //     createRule,
    //     setRuleDesignation,
    //     setRuleBaseEntitlement,
    //     setRuleBaseWorkDays,
    //     setRuleUsers,
    //     setRuleRestrictedBypassLimitDays,
    //     resetRuleOperation,
    //     loadVacationRules,
    // } = useVacationRule();
    const { getEmployees, employees, onUpdateFilterBy, filterBy } =
        useEmployees();
    const [users, setUsers] = React.useState<any>([]);
    const [isAllSelected, setIsAllSelected] = React.useState(false);
    React.useEffect(() => {
        getEmployees({ archived: false });
    }, []);
    // React.useEffect(() => {
    //     if (employees) {
    //         setUsers(
    //             employees
    //                 .map((emp) => {
    //                     return {
    //                         _id: emp._id,
    //                         fname: emp.fname,
    //                         lname: emp.lname,
    //                         picture: ROUTER.STATIC_IMG(emp.picture),
    //                         checked: false,
    //                     };
    //                 })
    //                 .sort((a, b) => {
    //                     const aName = `${a.fname} ${a.lname}`;
    //                     const bName = `${b.fname} ${b.lname}`;
    //                     return aName.localeCompare(bName);
    //                 }) ?? [],
    //         );
    //     }
    // }, [employees]);
    React.useEffect(() => {
        if (isAllSelected != undefined) {
            setUsers(
                users.map((user: any) => {
                    return {
                        ...user,
                        checked: isAllSelected,
                    };
                }),
            );
        }
    }, [isAllSelected]);
    const [displayAddNewRule, setDisplayAddNewRule] = React.useState(false);
    const [activateMonthlyBypass, setActivateMonthlyBypass] =
        React.useState(false);
    // React.useEffect(() => {
    //     if (displayAddNewRule && activateMonthlyBypass === false) {
    //         setRuleRestrictedBypassLimitDays(-1);
    //     }
    // }, [activateMonthlyBypass]);

    const handleAddNewRule = () => {
        console.log("🚀 handleAddNewRule:");
        setDisplayAddNewRule(true);
    };

    const [newRuleObject, setNewRuleObject] = React.useState<any>({
        designation: "",
        baseEntitlement: 0,
        baseWorkDays: 0,
        activateLimit: false,
        limitDays: "",
        employees: [],
    });
    const handleSubmitNewRule = async () => {
        console.log("newRuleObject", newRuleObject);
        const result = await fetch(API.client.barioloVacation.createRule, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(newRuleObject),
        });
        if (result && result.status === 200) {
            let data = await result.json();
            console.log("data==> \n", data);
            fetchVacationParams && fetchVacationParams();
        }
        setDisplayAddNewRule(false);
    };
    const handleCancelNewRule = () => {
        setDisplayAddNewRule(false);
    };
    const AddNewRuleComponent = () => {
        const updateNewRuleObject = (options: any) => {
            const {
                designation,
                baseEntitlement,
                baseWorkDays,
                activateLimit,
                limitDays,
                employees,
            } = options;
            if (designation !== undefined) {
                setNewRuleObject((prev: any) => ({
                    ...prev,
                    designation: designation,
                }));
            }
            if (baseEntitlement !== undefined) {
                setNewRuleObject((prev: any) => ({
                    ...prev,
                    baseEntitlement: baseEntitlement,
                }));
            }
            if (baseWorkDays !== undefined) {
                setNewRuleObject((prev: any) => ({
                    ...prev,
                    baseWorkDays: baseWorkDays,
                }));
            }
            if (activateLimit !== undefined) {
                setNewRuleObject((prev: any) => ({
                    ...prev,
                    activateLimit: activateLimit,
                }));
            }
            if (limitDays !== undefined) {
                setNewRuleObject((prev: any) => ({
                    ...prev,
                    limitDays: limitDays,
                }));
            }
            if (employees !== undefined) {
                setNewRuleObject((prev: any) => ({
                    ...prev,
                    employees: employees,
                }));
            }
            if (employees?.length === 0) {
                setNewRuleObject((prev: any) => ({
                    ...prev,
                    employees: [],
                }));
            }
        };
        // const Activation = () => {
        //     if (activateMonthlyBypass) {
        //         return (
        //             <>
        //                 <Checkbox
        //                     text="Activer la limite mensuelle"
        //                     checked={activateMonthlyBypass}
        //                     onClick={() => setActivateMonthlyBypass(false)}
        //                     textSize="14px"
        //                     textColor="#2A346D"
        //                 />
        //                 <p>
        //                     Le nombre maximal de jours de travail par mois est{" "}
        //                     <input
        //                         type="number"
        //                         min={0}
        //                         style={{
        //                             display: "inline",
        //                             width: "40px",
        //                             border: "1px solid #ecf0f1",
        //                             borderRadius: "5px",
        //                             color: "#2A346D",
        //                         }}
        //                         onChange={(e) => {
        //                             setRuleRestrictedBypassLimitDays(
        //                                 parseFloat(e.target.value),
        //                             );
        //                         }}
        //                     />{" "}
        //                     jour(s)
        //                 </p>
        //             </>
        //         );
        //     } else {
        //         return (
        //             <Checkbox
        //                 text="Activer la limite mensuelle"
        //                 checked={activateMonthlyBypass}
        //                 onClick={() => setActivateMonthlyBypass(true)}
        //                 textSize="14px"
        //                 textColor="#2A346D"
        //             />
        //         );
        //     }
        // };

        const handleSearchUsers = (text: string) => {
            onUpdateFilterBy({
                ...filterBy,
                name: text,
            });
        };
        const handleSelectAllAffectationUser = async () => {
            // const empIDs = employees?.map((emp) => emp?._id?.toString());
            // console.log("empIDs ", empIDs);
            // if (isAllSelected) {
            //     await updateNewRuleObject({
            //         ...newRuleObject,
            //         employees: empIDs,
            //     });
            // } else {
            //     await updateNewRuleObject({
            //         ...newRuleObject,
            //         employees: [],
            //     });
            // }
            setIsAllSelected(!isAllSelected);
        };
        const handleSelectAffectationUser = async (user: any) => {
            if (!newRuleObject.employees.includes(user?._id?.toString())) {
                await updateNewRuleObject({
                    ...newRuleObject,
                    employees: [
                        ...newRuleObject.employees,
                        user?._id?.toString(),
                    ],
                });
            } else {
                const rs = newRuleObject.employees.filter((userID: string) => {
                    return userID != user?._id?.toString();
                });
                await updateNewRuleObject({ employees: rs });
            }
        };
        return (
            <Modal
                modalEnabled={displayAddNewRule}
                onClose={() => setDisplayAddNewRule(false)}
            >
                <PopUpCard
                    display={displayAddNewRule}
                    onClose={() => setDisplayAddNewRule(false)}
                    header="Ajouter une régle"
                    widthCloseIcon="24px"
                    containerWidth="480px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleCancelNewRule}
                    button2Text="Valider"
                    onClickButton2={handleSubmitNewRule}
                >
                    <div style={{ height: "60vh", overflow: "auto" }}>
                        <div className="new-conge-rule-container">
                            <Input
                                captionText="Désignation"
                                captionColor="#8E96C1"
                                captionSize="16px"
                                borderColor="#C7CBE2"
                                borderWidth={1}
                                textSize={14}
                                textColor="#2A346D"
                                inputRadius="4px"
                                placeholder=""
                                inputWidth="100%"
                                onChange={(data: any) => {
                                    updateNewRuleObject({
                                        designation: data,
                                    });
                                }}
                            />
                            <p>
                                L'employé a droit à{" "}
                                <input
                                    type="number"
                                    style={{
                                        display: "inline",
                                        width: "40px",
                                        border: "1px solid #ecf0f1",
                                        borderRadius: "5px",
                                        color: "#2A346D",
                                    }}
                                    step={0.5}
                                    onChange={(e) =>
                                        updateNewRuleObject({
                                            baseEntitlement: parseFloat(
                                                e.target.value,
                                            ),
                                        })
                                    }
                                />{" "}
                                jour(s) de congé pour chaque période de travail
                                de{" "}
                                <input
                                    type="number"
                                    style={{
                                        display: "inline",
                                        width: "40px",
                                        border: "1px solid #ecf0f1",
                                        borderRadius: "5px",
                                        color: "#2A346D",
                                    }}
                                    step={0.5}
                                    onChange={(e) =>
                                        updateNewRuleObject({
                                            baseWorkDays: parseFloat(
                                                e.target.value,
                                            ),
                                        })
                                    }
                                />{" "}
                                jour(s)
                            </p>
                        </div>
                        <div className="new-conge-rule-container">
                            <Checkbox
                                text="Activer la limite mensuelle"
                                checked={false}
                                onClick={() =>
                                    updateNewRuleObject({
                                        activateLimit:
                                            !newRuleObject?.activateLimit,
                                    })
                                }
                                textSize="14px"
                                textColor="#2A346D"
                            />
                            {newRuleObject?.activateLimit && (
                                <p>
                                    Le nombre maximal de jours de travail par
                                    mois est{" "}
                                    <input
                                        type="number"
                                        min={0}
                                        style={{
                                            display: "inline",
                                            width: "40px",
                                            border: "1px solid #ecf0f1",
                                            borderRadius: "5px",
                                            color: "#2A346D",
                                        }}
                                        onChange={(e) => {
                                            updateNewRuleObject({
                                                limitDays: parseFloat(
                                                    e.target.value,
                                                ),
                                            });
                                        }}
                                    />{" "}
                                    jour(s)
                                </p>
                            )}
                        </div>
                        <div
                            className="employees-section"
                            style={{ height: "40vh" }}
                        >
                            <div className="new-dept-search-bar">
                                <Input
                                    captionText={null}
                                    borderWidth={0}
                                    iconImg={SearchSVG}
                                    iconLocation="left"
                                    placeholder="Rechercher"
                                    inputWidth="70%"
                                    style={{
                                        inputStyle: {
                                            backgroundColor: "transparent",
                                            outline: "none",
                                            width: "100%",
                                            padding: "7px 3px 7px 25px",
                                            color: "#8E96C1",
                                        },
                                    }}
                                    onChange={handleSearchUsers}
                                />
                                {/* <TextData
                                    style={{
                                        marginRight: 5,
                                    }}
                                    width="auto"
                                    size="12"
                                    variant="regular"
                                    fontWeight="600"
                                >
                                    Tous
                                </TextData>
                                <Checkbox
                                    onClick={handleSelectAllAffectationUser}
                                    id="all"
                                    text={null}
                                    rounded={true}
                                    checked={isAllSelected}
                                /> */}
                            </div>
                            <div className="new-dept-list-of-employees">
                                {employees &&
                                    employees.map((user: any) => {
                                        return (
                                            <div
                                                key={user._id}
                                                className="employee"
                                            >
                                                <div className="information">
                                                    <Picture
                                                        imgPath={ROUTER.STATIC_IMG(
                                                            user.picture,
                                                        )}
                                                        className={[
                                                            "rounded-full",
                                                        ]}
                                                        width="40px"
                                                        height="40px"
                                                    />
                                                    <TextData
                                                        variant="label"
                                                        width="auto"
                                                        size="14px"
                                                        fontWeight="bolder"
                                                        color="#101844"
                                                    >
                                                        {`${user.fname} ${user.lname}`}
                                                    </TextData>
                                                </div>
                                                <div className="employee-selection">
                                                    <Checkbox
                                                        id={user._id}
                                                        text={null}
                                                        rounded={true}
                                                        checked={false}
                                                        onClick={() =>
                                                            handleSelectAffectationUser(
                                                                user,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </PopUpCard>
            </Modal>
        );
    };

    const [vacationBariolo, setVacationBariolo] = React.useState<any>({});
    const fetchVacationParams = async () => {
        let result = await fetch(API.client.barioloVacation.setting, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        if (result && result.status === 200) {
            let data = await result.json();
            setVacationBariolo(data);
        }
    };
    React.useEffect(() => {
        fetchVacationParams();
    }, [selectedBranchSettings]);

    const updateVacationParams = async (options: any) => {
        const { status, calculDay } = options;
        let result = await fetch(API.client.barioloVacation.setting, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(options),
        });
        if (result && result.status === 200) {
            let data = await result.json();
            setVacationBariolo(data);
            Swal.fire({
                icon: "success",
                title: "Succès",
                text: "Le jour de clôture des congés a été enregistré avec succès !",
                confirmButtonText: "OK",
            });
        }
    };

    const VacationIsActivated = () => {
        if (!vacationBariolo) {
            return []; // or handle loading state
        }
        if (vacationBariolo && vacationBariolo?.status) {
            return [
                {
                    optionTitle: "Activer la gestion des congés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Centralisez et automatisez la gestion des absences en un clic.",

                    optionInput: (
                        <ToggleButton
                            isChecked={
                                vacationBariolo && vacationBariolo?.status
                            }
                            onClick={(e: any) => {
                                updateVacationParams({
                                    status: e.target.checked,
                                });
                            }}
                        />
                    ),
                },
                {
                    optionTitle: "Choix du jour de clôture des congés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Définissez la date de clôture mensuelle des congés pour une gestion alignée à vos besoins.",
                    optionInput: (
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                color: "#2a346d",
                            }}
                        >
                            Le
                            <Input
                                value={vacationBariolo?.calculDay || 0}
                                type="number"
                                captionText={null}
                                inputWidth="50px"
                                onBlur={(data: any) => {
                                    if (
                                        data != vacationBariolo?.calculDay &&
                                        data > 0 &&
                                        data <= 31
                                    ) {
                                        updateVacationParams({
                                            calculDay: data,
                                        });
                                    }
                                }}
                            />
                            de chaque mois
                        </p>
                    ),
                },
                {
                    optionTitle:
                        "Paramétrage des règles d'acquisition de congés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Automatisez l'attribution des congés en définissant vos propres règles basées sur les jours travaillés.",
                    optionInput: (
                        <>
                            {displayAddNewRule && AddNewRuleComponent()}
                            <Button
                                style={{
                                    backgroundColor: "#2A8BAB",
                                    color: "white",
                                    fontSize: "14px",
                                }}
                                backgroundColor="#2A8BAB"
                                textColor="white"
                                width="150px"
                                height="30px"
                                onClick={handleAddNewRule}
                            >
                                Ajouter une régle
                            </Button>
                        </>
                    ),
                    optionInput2: (
                        <VacationPolitics
                            rulesList={vacationBariolo?.rules}
                            fetchVacationParams={fetchVacationParams}
                        />
                    ),
                },
            ];
        } else {
            return [
                {
                    optionTitle: "Activer la gestion des congés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Centralisez et automatisez la gestion des absences en un clic.",

                    optionInput: (
                        <ToggleButton
                            isChecked={
                                vacationBariolo &&
                                vacationBariolo?.status === true
                            }
                            onClick={(e: any) => {
                                updateVacationParams({
                                    status: e.target.checked,
                                });
                            }}
                        />
                    ),
                },
            ];
        }
    };
    return [
        {
            headerTitle: "Gestion des congés",
            headerDescription:
                "Simplifiez la gestion des absences avec un suivi centralisé et des validations rapides pour une planification sans faille.",
            configOptions: VacationIsActivated(),
        },
    ];
};
