import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import {
    updateNotifications,
    updateAllNotifications,
    setAllNotificationsSeen,
    setLoadingNotifications,
} from "../redux/features/notifications/notificationsSlice";
import React from "react";
import { API } from "../constants/env";
import { isToday } from "../_helpers/Functions";

export const useNotifications = () => {
    const notificationsSettings = useAppSelector(
        (state: any) => state.notifications.settings,
    );
    const notifications = useAppSelector(
        (state: any) => state.notifications.some,
    );
    const allNotifications = useAppSelector(
        (state: any) => state.notifications.all,
    );
    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;
    const loading = useAppSelector((state: any) => state.notifications.loading);
    const dispatch = useAppDispatch();

    const getNotifications = async () => {
        const userData = sessionStorage.getItem("user");
        if (userData) {
            const userObj = JSON.parse(userData);
            try {
                const response = await fetch(
                    API.client.notification.get(userObj._id),
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok) {
                    const data = await response.json();
                    const formattedNotifications = data.notifications.map(
                        (notification: any) => ({
                            ...notification,
                            created_at: new Date(notification.created_at),
                        }),
                    );
                    const todayNotifications = formattedNotifications.filter(
                        (notification: any) => isToday(notification.created_at),
                    );
                    const oldNotifications = formattedNotifications.filter(
                        (notification: any) =>
                            !isToday(notification.created_at),
                    );
                    dispatch(
                        updateNotifications({
                            today: todayNotifications,
                            old: oldNotifications,
                            unread: data.undread,
                        }),
                    );
                } else {
                    console.error("Failed to fetch notifications");
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        }
    };
    const getAllNotifications = async () => {
        const userData = sessionStorage.getItem("user");
        if (userData) {
            const userObj = JSON.parse(userData);
            try {
                dispatch(setLoadingNotifications(true));
                const response = await fetch(
                    API.client.notification.getAll(userObj._id),
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok) {
                    const data = await response.json();
                    const formattedNotifications = data.notifications.map(
                        (notification: any) => ({
                            ...notification,
                            created_at: new Date(notification.created_at),
                        }),
                    );
                    const todayNotifications = formattedNotifications.filter(
                        (notification: any) => isToday(notification.created_at),
                    );
                    const oldNotifications = formattedNotifications.filter(
                        (notification: any) =>
                            !isToday(notification.created_at),
                    );
                    dispatch(
                        updateAllNotifications({
                            today: todayNotifications,
                            old: oldNotifications,
                            unread: data.undread,
                        }),
                    );
                } else {
                    console.error("Failed to fetch notifications");
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
            dispatch(setLoadingNotifications(false));
        }
    };
    const setNotificationsSeen = async () => {
        dispatch(setAllNotificationsSeen());
        const userData = sessionStorage.getItem("user");
        if (userData) {
            const userObj = JSON.parse(userData);
            try {
                const response = await fetch(
                    API.client.notification.set_seen(userObj._id),
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok) {
                    getNotifications();
                } else {
                    console.error("Failed to fetch notifications");
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        }
    };
    const clickNotification = async (id: string) => {
        try {
            const response = await fetch(
                API.client.notification.set_clicked(id),
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            if (response.ok === true) {
                let data = await response.json();
                dispatch(
                    updateNotifications({ updateOne: { ...data, seen: true } }),
                );
            }
        } catch (err) {
            console.log(err);
        }
    };
    const getNotificationsSettings = async () => {
        if (selectedBranchSettings?._id) {
            try {
                const response = await fetch(
                    API.client.settings.notifications.getNotifService(
                        selectedBranchSettings?._id,
                    ),
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok) {
                    const data = await response.json();
                    dispatch(
                        updateNotifications({
                            settings: data.notification,
                        }),
                    );
                } else {
                }
            } catch (error) { }
        }
    };
    const updateNotificationsSettings = async () => {
        if (selectedBranchSettings?._id) {
            try {
                const response = await fetch(
                    API.client.settings.notifications.updateNotifService(
                        selectedBranchSettings?._id,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify(notificationsSettings),
                    },
                );
                if (response.ok) {
                    const data = await response.json();
                    // dispatch(
                    //     updateNotifications({
                    //         settings: data.notification,
                    //     })
                    // );
                } else {
                }
            } catch (error) { }
        }
    };
    const setNotificationsSettings = (data: any) => {
        dispatch(
            updateNotifications({
                settings: data,
            }),
        );
    };

    return {
        notifications,
        notificationsSettings,
        allNotifications,
        loading,
        getNotifications,
        getAllNotifications,
        setNotificationsSeen,
        clickNotification,
        updateNotificationsSettings,
        getNotificationsSettings,
        setNotificationsSettings,
    };
};
