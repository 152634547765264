import * as React from "react";
import "moment/locale/fr";
import TextData from "../../../atoms/TextData";
import Button from "../../../atoms/Button";
import { ReactComponent as Arrow } from "./arrow.svg";
import { capitalize } from "../../../../_helpers/Functions";
import { useAnalytics } from "../../../../hooks/useAnalytics";
import Calendar from "../../Calendar";
import moment, { Moment } from "moment";

function WeekDates(props: any) {
    const {
        weekNumber,
        formatWeekRange,
        selectDay,
        selectedDay = "",
        handleNextWeekClick,
        handlePreviousWeekClick,
        isBudget,
    } = props;
    const [openCalendar, setOpenCalendar] = React.useState(false);
    const handleTextDataClick = (e: any) => {
        e.stopPropagation();
        setOpenCalendar((prev: boolean) => !prev);
    };
    const hideNextWeeks = React.useMemo(() => {
        return (
            selectedDay
                .clone()
                .startOf("isoWeek")
                .isSame(moment().startOf("isoWeek")) && !isBudget
        );
    }, [selectedDay, isBudget]);
    React.useEffect(() => {
        console.log(" selectedDay = ", selectedDay);
    }, [selectedDay]);
    return (
        <div className="week-dates-container ">
            <TextData variant="label" width="auto" size="12px" color="#4C5690">
                Semaine {weekNumber}
            </TextData>
            <div
                className="week-selection"
                style={{
                    position: "relative",
                }}
            >
                <TextData
                    variant="label"
                    width="120px"
                    size="13px"
                    color="#2A346D"
                    fontWeight="bold"
                    style={{ cursor: "pointer" }}
                    onClick={handleTextDataClick}
                >
                    {formatWeekRange()}
                </TextData>
                <Arrow
                    key={1}
                    width="20px"
                    style={{
                        marginLeft: "10px",
                        fontSize: "20px",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        handlePreviousWeekClick();
                    }}
                />
                <Arrow
                    key={2}
                    width="20px"
                    style={{
                        marginLeft: "5px",
                        fontSize: "20px",
                        transform: "rotate(180deg)",
                        opacity: hideNextWeeks ? "0.2" : "1",
                        cursor: hideNextWeeks ? "not-allowed" : "pointer",
                    }}
                    onClick={() => {
                        !hideNextWeeks && handleNextWeekClick();
                    }}
                />
                {/* {openCalendar && ( */}
                <div
                    style={{
                        position: "absolute",
                        top: "calc(100% + 10px)",
                        left: "-20px",
                        borderRadius: "4px",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    }}
                    className={`fixed   z-50`}
                >
                    <Calendar
                        calenderContainerClasses="border _shaddow"
                        open={openCalendar}
                        onChange={(value: Moment) => {
                            selectDay(value);
                            setOpenCalendar(false);
                        }}
                        // close={() => setOpenCalendar(false)} // Ensure this does not trigger multiple times
                        close={setOpenCalendar.bind(null, false)}
                        value={selectedDay}
                        showWeekCount={true}
                        disabledFrom={
                            !isBudget ? moment().endOf("isoWeek") : null
                        }
                    />
                </div>
                {/* )} */}
            </div>
        </div>
    );
}

function WeekDay(props: any) {
    const { date, dateNow } = props;

    const dayName = capitalize(dateNow.lang("fr").format("dddd"));
    const dayNumber = dateNow.lang("fr").format("D");

    if (date.dayName === dayName && date.dayNumber === dayNumber) {
        return (
            <>
                <TextData
                    variant="label"
                    color="#1A7290"
                    size="16px"
                    fontWeight="bold"
                    width="auto"
                >
                    {`${date.dayName}`}
                </TextData>
                <TextData
                    variant="label"
                    color="#ffffff"
                    size="16px"
                    fontWeight="bold"
                    width="auto"
                    style={{
                        marginLeft: "5px",
                        paddingRight: "3px",
                        paddingLeft: "3px",
                        backgroundColor: "#1A7290",
                        borderRadius: "2px",
                    }}
                >
                    {`${date.dayNumber}`}
                </TextData>
            </>
        );
    } else {
        return (
            <>
                <TextData
                    variant="label"
                    color="#2A346D"
                    size="16px"
                    fontWeight="bold"
                    width="auto"
                >
                    {`${date.dayName}`}
                </TextData>
                <TextData
                    variant="label"
                    color="#2A346D"
                    size="16px"
                    fontWeight="bold"
                    width="auto"
                    style={{
                        marginLeft: "5px",
                    }}
                >
                    {`${date.dayNumber}`}
                </TextData>
            </>
        );
    }
}

export default function HeaderTable(isBudget: boolean = true) {
    const { updateDateRange } = useAnalytics();
    const [currentDate, setCurrentDate] = React.useState(moment().locale("fr"));
    const [dateNow, setDateNow] = React.useState(currentDate);
    const [weekNumber, setPlanningProperties] = React.useState(
        currentDate.isoWeek(),
    );

    // Get the week number, start date, and end date for the current week
    const [startDate, setStartDate] = React.useState(
        currentDate.clone().startOf("week"),
    );

    const [endDate, setEndDate] = React.useState(
        currentDate.clone().endOf("week"),
    );

    const [days, setDays] = React.useState<moment.Moment[]>([]);
    // Create an array of moment objects for each day of the current week
    const [formattedDates, setformattedDates] = React.useState(
        days.map((day) => {
            // const formattedDay = day.lang("fr").format("dddd D");
            let dayName = day.lang("fr").format("dddd");
            dayName = dayName?.charAt(0)?.toUpperCase() + dayName?.slice(1);
            const dayNumber = day.lang("fr").format("D");
            return {
                dayName,
                dayNumber,
            };
        }),
    );

    // Handler for clicking the "Next Week" button
    const handleNextWeekClick = () => {
        setCurrentDate(currentDate.clone().add(1, "weeks"));
    };

    // Handler for clicking the "Previous Week" button
    const handlePreviousWeekClick = () => {
        setCurrentDate(currentDate.clone().subtract(1, "weeks"));
    };
    React.useEffect(() => {
        const weekNumber = currentDate.isoWeek();
        const startDate = currentDate.clone().startOf("week");
        const endDate = currentDate.clone().endOf("week");

        let days = [];
        for (let i = 0; i < 7; i++) {
            const day = startDate.clone().add(i, "days");
            days.push(day);
        }

        const formattedDates = days.map((day) => {
            // return formattedDay.charAt(0).toUpperCase() + formattedDay.slice(1);
            // const formattedDay = day.lang("fr").format("dddd D");
            let dayName = day.lang("fr").format("dddd");
            dayName = dayName?.charAt(0).toUpperCase() + dayName?.slice(1);
            const dayNumber = day.lang("fr").format("D");
            return {
                dayName,
                dayNumber,
            };
        });

        setPlanningProperties(weekNumber);
        setStartDate(startDate);
        setEndDate(endDate);
        updateDateRange({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
        }); //in the slice
        setDays(days);
        setformattedDates(formattedDates);
    }, [currentDate]);

    const formatWeekRange = () => {
        return `${startDate.clone().format("D")} - ${endDate
            .clone()
            .format("D")} ${endDate.clone().format("MMMM")} ${startDate
            .clone()
            .format("YYYY")}`;
    };
    const handleSelectDay = (day: Moment) => {
        setCurrentDate(day);
    };
    const weekdate = {
        title: (
            <WeekDates
                weekNumber={weekNumber}
                formatWeekRange={formatWeekRange}
                selectDay={handleSelectDay}
                selectedDay={currentDate}
                handleNextWeekClick={handleNextWeekClick}
                handlePreviousWeekClick={handlePreviousWeekClick}
                isBudget={isBudget}
            />
        ),
        name: "name",
        style: {
            padding: "15px 10px 12px 20px",
            width: "215px",
            paddingLeft: "40px",
            borderTop: "none",
        },
    };
    let weekdays: Field[] = [];
    formattedDates.forEach((date, index) => {
        weekdays.push({
            title: <WeekDay date={date} dateNow={dateNow} />,
            name: "name" + index,
            style: {
                padding: "10px 0",
                borderTop: "none",
                minWidth: "120px",
                textAlign: "center",
            },
        });
    });

    return [weekdate, ...weekdays];
}
interface Field {
    title?: React.ReactNode;
    name?: string;
    style?: React.CSSProperties;
}
