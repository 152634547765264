import React from "react";
import Picture from "../Picture";
import { Eye, EyeSlash } from "../../../Icons";
import Theme from "../../../tailwind-theme";

export default function PinCode({ value }: PinCodeInterface) {
  const [show, setShow] = React.useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <div className="flex justify-between items-center w-[80px]">
      {show ? (
        <span className="font-light text-md text-n-600">{value}</span>
      ) : (
        <div className="flex gap-1">
          {Array(value?.length)
            .fill(0)
            .map((element, index) => (
              <span
                key={index}
                className="inline-block w-[6px] h-[6px] rounded-full bg-n-600"
              ></span>
            ))}
        </div>
      )}
      {show ? (
        <div className="cursor-pointer" onClick={toggleShow}>
          <EyeSlash color={Theme.colors["n-500"]} />
        </div>
      ) : (
        <div className="cursor-pointer" onClick={toggleShow}>
          <Eye color={Theme.colors["n-500"]} />
        </div>
      )}
    </div>
  );
}

interface PinCodeInterface {
  value: any;
}
