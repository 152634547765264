//TODO: should be removed
import { Navigate, Route, Routes, useNavigate, Link } from "react-router-dom";
import DashboardTemplate from "../components/templates/Dashboard";
import EmployesPage from "../pages/Employees/EmployeesPage";
import PointeusePage from "../pages/Pointeuse";
import EmployeesEditPage from "../pages/Employees/EmployeesEditPage";
import RegisterPage from "../pages/Employees/Register";
import { ROUTER } from "../constants/env";
import {
    Branch,
    Schedule,
    TimeTracker,
    Vacation,
    Integration,
    Administrative,
} from "../pages/Settings";
import SettingsPage from "../pages/Settings";
import Mobile from "../pages/Settings/Mobile";
import NotificationsSettings from "../pages/Settings/Notifications";
import Report from "../pages/Report";
import Planning from "../pages/Planning";
import Support from "../pages/Support";
import Notifications from "../pages/Notifications";
import DashboardPage from "../pages/DashboardPage";
import RequireAuth from "./RequireAuth";
import EmployeeProfile from "../pages/Employees/EmployeeProfile";
import { useBranch } from "../hooks/useBranch";
import React from "react";
import CongesPage from "../pages/Conges/CongesPage";
import Productivity from "../pages/Productivity";
import { useLogin } from "../hooks/useLogin";
import Error404 from "../pages/Error404";
import Loading from "../components/atoms/Loading";
import FeuilleAmargement from "../pages/FeuilleAmargement";

export default function BasePage({}) {
    const {
        selectedBranchSettings,
        branchSettings,
        Settings,
        getSiteSettings,
    } = useBranch();
    const { auth } = useLogin();
    const checkPlanningOn = React.useMemo(() => {
        if (auth?.user?.realLicense?.length) {
            for (let license of auth?.user?.realLicense) {
                if (license?._id === selectedBranchSettings?.license)
                    return license?.planningOn;
            }
        }
        return false;
    }, [auth?.user, selectedBranchSettings]);
    const navigate = useNavigate();
    const [allowedAccessPages, setAllowedAccessPages] = React.useState<
        string[]
    >([]);
    const [isLoading, setIsLoading] = React.useState(true);
    // Planning', 'Pointeuse', 'Employés', 'Rapports'
    React.useEffect(() => {
        let allowedPages: string[] = [
            "Planning",
            "Pointeuse",
            "Employés",
            "Rapports",
        ];
        let currentSite = selectedBranchSettings?._id ?? Settings.site;
        if (currentSite) {
            const user = sessionStorage.getItem("user");
            if (user) {
                let manage = JSON.parse(user).manage;
                for (let element of manage) {
                    if (element.site === currentSite) {
                        allowedPages = [...element.roles];
                        break;
                    }
                }
            }
        }
        setAllowedAccessPages(allowedPages);
        getSiteSettings(); //to get the updated status for site
        setIsLoading(false);
    }, [selectedBranchSettings?._id, Settings.site]);
    const SettingsPages = [
        {
            path: ROUTER.SETTINGS.BRANCH,
            element: (
                <SettingsPage>
                    <Branch />
                </SettingsPage>
            ),
        },
        {
            path: ROUTER.SETTINGS.SCHEDULE,
            element: (
                <SettingsPage>
                    <Schedule />
                </SettingsPage>
            ),
            require:
                allowedAccessPages.includes("Planning") &&
                auth?.user?.license?.planningOn,
        },
        {
            path: ROUTER.SETTINGS.TIME_TRACKER,
            element: (
                <SettingsPage>
                    <TimeTracker />
                </SettingsPage>
            ),
        },
        {
            path: ROUTER.SETTINGS.MOBILE,
            element: (
                <SettingsPage>
                    <Mobile />
                </SettingsPage>
            ),
        },
        {
            path: ROUTER.SETTINGS.NOTIFICATIONS,
            element: (
                <SettingsPage>
                    <NotificationsSettings />
                </SettingsPage>
            ),
        },
        {
            path: ROUTER.SETTINGS.VACATION,
            element: (
                <SettingsPage>
                    <Vacation />
                </SettingsPage>
            ),
        },
        {
            path: ROUTER.SETTINGS.INTEGRATION,
            element: (
                <SettingsPage>
                    <Integration />
                </SettingsPage>
            ),
        },

        {
            path: ROUTER.SETTINGS.ADMINISTRATIVE,
            element: (
                <RequireAuth>
                    <SettingsPage>
                        <Administrative />
                    </SettingsPage>
                </RequireAuth>
            ),
        },
    ];

    const ReportPages = [
        {
            path: ROUTER.REPORT.WORKED_HOURS,
            element: <Report />,
        },
        {
            path: ROUTER.REPORT.PRODUCTIVITY,
            element: <Report />,
        },
        {
            path: ROUTER.REPORT.DELAY,
            element: <Report />,
        },
        {
            path: ROUTER.REPORT.VACATION,
            element: <Report />,
        },
        {
            path: ROUTER.REPORT.PAYROLL,
            element: <Report />,
        },
        {
            path: ROUTER.REPORT.ACTIVITY,
            element: <Report />,
        },
    ];
    if (isLoading) {
        return (
            <div
                style={{
                    backgroundColor: "white",
                    height: "100vh",
                    width: "100vw",
                }}
            ></div>
        ); // Show loading indicator until ready
    }

    // const handleUserRedirection = (userData: any) => {
    //page to redirect to:
    // let allRoles: String[] = ["", "", "", "", ""];
    // userData?.manage?.map((manageSite: any) => {
    //     const { roles } = manageSite;
    //     roles?.map((role: string) => {
    //         if (
    //             role === "Planning" &&
    //             !allRoles[0] &&
    //             userData.license.planningOn
    //         ) {
    //             allRoles[0] = role;
    //         }
    //         if (role === "Pointeuse" && !allRoles[1]) {
    //             allRoles[1] = role;
    //         }
    //         if (role === "Employés" && !allRoles[2]) {
    //             allRoles[2] = role;
    //         }
    //         if (role === "Rapports" && !allRoles[3]) {
    //             allRoles[3] = role;
    //         }
    //     });
    // });

    //get the current site manage
    //     allRoles = allRoles?.filter((role) => role);
    //     let routeToRedirect = "/";
    //     if (allRoles && allRoles?.length > 0) {
    //         switch (allRoles[0]) {
    //             case "Planning":
    //                 routeToRedirect = "/planning";
    //                 break;
    //             case "Pointeuse":
    //                 routeToRedirect = "/pointeuse";
    //                 break;

    //             case "Employés":
    //                 routeToRedirect = "/employees";
    //                 break;

    //             case "Rapports":
    //                 routeToRedirect = "/report/worked_hours";
    //                 break;

    //             default:
    //                 routeToRedirect = "/";
    //         }
    //     }
    //     navigate(routeToRedirect);
    // };
    return (
        <DashboardTemplate>
            <Routes>
                <Route
                    path={""}
                    element={<Navigate to={ROUTER.PLANNING} replace />}
                />
                {allowedAccessPages.includes("Employés") && (
                    <>
                        <Route path="/employees" element={<EmployesPage />} />
                        <Route
                            path="/employees/register"
                            element={<RegisterPage />}
                        />
                        <Route
                            path="/employees/:id/edit"
                            // element={<EmployeeProfile />}
                            element={<EmployeesEditPage />}
                        />
                        <Route path="/conges" element={<CongesPage />} />
                        <Route
                            path="/productivity"
                            element={<Productivity />}
                        />

                        {/* add logic to able or disable */}
                        {branchSettings?.activateEmergencePage != false && (
                            <Route
                                path="/feuille"
                                element={<FeuilleAmargement />}
                            />
                        )}

                        <Route
                            path="/employee/:id/edit"
                            element={<EmployeeProfile />}
                            // element={<EmployeesEditPage />}
                        />
                    </>
                )}
                {allowedAccessPages.includes("Pointeuse") && (
                    <Route path="/pointeuse" element={<PointeusePage />} />
                )}
                {SettingsPages.map((page) => {
                    if (page.require !== undefined) {
                        if (page.require) {
                            return (
                                <Route
                                    key={page.path}
                                    path={page.path}
                                    element={page.element}
                                />
                            );
                        }
                        return null;
                    }
                    return (
                        <Route
                            key={page.path}
                            path={page.path}
                            element={page.element}
                        />
                    );
                })}
                {allowedAccessPages.includes("Rapports") &&
                    ReportPages.map((page) => (
                        <Route
                            key={page.path}
                            path={page.path}
                            element={page.element}
                        />
                    ))}
                {allowedAccessPages.includes("Planning") && (
                    //  checkPlanningOn &&
                    <Route path={ROUTER.PLANNING} element={<Planning />} />
                )}
                <Route path={ROUTER.SUPPORT} element={<Support />} />
                <Route
                    path={ROUTER.NOTIFICATIONS}
                    element={<Notifications />}
                />
                <Route path={ROUTER.DASHBOARD} element={<DashboardPage />} />
                <Route path={"*"} element={<Navigate to="/404" replace />} />
            </Routes>
        </DashboardTemplate>
    );
}
