import React from "react";
import {
    ProductivityContent,
    ProductivityTableRightMenu,
    ProductivityTableHeader,
} from "../../components/organism/ReportManagement";
import { useBranch } from "../../hooks/useBranch";
import { useAnalytics } from "../../hooks/useAnalytics";
import { Link } from "react-router-dom";
export default function Productivity() {
    const { dateRange, hasConfig, configs, getAnalytics, getAnalyticsConfigs } =
        useAnalytics();
    const { selectedBranchSettings } = useBranch();

    React.useEffect(() => {
        getAnalytics();
    }, [dateRange, selectedBranchSettings?._id]);
    React.useEffect(() => {
        getAnalyticsConfigs();
    }, [selectedBranchSettings?._id]);
    if (!selectedBranchSettings?._id) {
        return (
            <div
                style={{
                    marginTop: "20px",
                    padding: "20px",
                    backgroundColor: "rgb(173 235 255)",
                    borderRadius: "8px",
                    color: "#4c5690",
                }}
            >
                Veuillez sélectionner un établissement
            </div>
        );
    }
    if (hasConfig === false && configs === null) {
        return (
            <div
                style={{
                    marginTop: "20px",
                    padding: "20px",
                    backgroundColor: "rgb(173 235 255)",
                    borderRadius: "8px",
                    color: "#4c5690",
                }}
            >
                Veuillez renseigner le champ "Productivité" dans{" "}
                <Link to="/settings/integration">
                    <span
                        style={{
                            textDecoration: "underline",
                            color: "blue",
                        }}
                    >
                        les paramètres
                    </span>
                </Link>
            </div>
        );
    }

    return (
        <div>
            <ProductivityTableHeader />
            {/* <ProductivityTableRightMenu /> */}
            <ProductivityContent />
        </div>
    );
}
